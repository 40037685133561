import { combineReducers } from 'redux';
import { penderReducer } from 'redux-pender';
import auth from '../screens/auth/Auth.action';

export const Reducers = (asyncReducers={}) => 
  combineReducers({
    auth,
    pender: penderReducer,
    ...asyncReducers
  })

