import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ContextType, GetSvg } from "./assets/images/images";
import queryStirng from "query-string";
// import { ReactComponent as Download } from "./assets/images/group12Copy2.svg";

export const AvocadoBar = (props: {
  time_percent: number;
  onPlay: () => void;
  onStop: () => void;
  isPlaying: boolean;
  onMore: () => void;
  onCredit: () => void;
  onClose: () => void;
  onDownload: () => void;
  onPurchase: () => void;
  onToast: (message: string) => void;
  title?: string;
  hideIos?: boolean;
}) => {
  const { width, height } = window.screen;
  const [toastMsg, setToastMsg] = useState("");
  const { query } = useParams<any>();
  const queryObj = queryStirng.parse(query);
  const { login, purchase } = queryObj;
  const isAnroid = navigator.userAgent.match(/Android/i) == null ? false : true;
  return (
    <>
      <div
        style={{
          ...style.topArea,
          minHeight: height / 20,
        }}
      ></div>
      <div
        style={{
          ...style.centeredText,
          fontSize: 15,
          fontFamily: isAnroid ? "NotoSansCJKKr" : "AppleSDGothic",
          // fontFamily: "AppleSDGothic",
          fontWeight: "bold",
        }}
      >
        {props.title}
        <div
          style={{
            // marginTop: height / 200,
            fontSize: 10,
            fontFamily: isAnroid ? "NotoSansCJKKr" : "AppleSDGothic",
            fontWeight: "normal",
          }}
        >
          C#m
        </div>
      </div>
      <div
        style={{
          ...style.topLeftContents,
          width: height / 22,
          height: height / 22,
        }}
        onClick={props.onClose}
      >
        <GetSvg context={ContextType.back} />
      </div>
      <div
        style={{
          ...style.topRightContents,
          width: height / 22,
          height: height / 22,
        }}
        onClick={props.onCredit}
      >
        <GetSvg context={ContextType.credit} />
      </div>
      {!props.hideIos && (
        <div
          style={{
            ...style.topRightContents2,
            width: height / 22,
            height: height / 22,
          }}
          onClick={props.onMore}
        >
          <GetSvg context={ContextType.more} />
        </div>
      )}
      <div
        style={{
          ...style.progressPlaybar,
          height: "0.5%",
          width: props.time_percent === 0 ? 0 : `${props.time_percent}%`,
        }}
      ></div>
      <div
        style={{
          ...style.bottomToolbar,
          // height: height / 6,
        }}
      >
        {/* 하단 속성
        <div style={{ marginTop: "10%" }}>
          <button onClick={props.onPlay}>
            {props.isPlaying ? "일시정지" : "재생"}
          </button>
          <button onClick={props.onStop}>중지</button>
          <input
            onChange={(e) => setToastMsg(e.target.value)}
            placeholder="Enter toast msg"
            value={toastMsg}
          ></input>
          <button
            onClick={() => {
              props.onToast(toastMsg);
            }}
          >
            toast 전송
          </button>
          <button onClick={props.onMore}>more</button>
          <button onClick={props.onDownload}>download</button>
          <button onClick={props.onClose}>close</button>
        </div> */}
      </div>
      <div
        style={{
          ...style.bottomLeftContext,
          width: height / 22,
          height: height / 22,
        }}
        onClick={props.onStop}
      >
        <GetSvg context={ContextType.restart} />
      </div>
      <div
        style={{
          ...style.bottomCenterContext,
          width: height / 18,
          height: height / 18,
        }}
        onClick={props.onPlay}
      >
        <GetSvg
          context={props.isPlaying ? ContextType.pause : ContextType.play}
        />
      </div>
      {!props.hideIos && (
        <div
          style={{
            ...style.bottomRightContext,
            width: height / 22,
            height: height / 22,
            // opacity: login && login === "Y" ? 1.0 : 0.5,
          }}
          onClick={
            purchase && purchase === "Y" ? props.onDownload : props.onPurchase
          }
        >
          <GetSvg
            context={
              purchase && purchase === "Y"
                ? ContextType.download
                : ContextType.payment
            }
          />
        </div>
      )}
    </>
  );
};

const style: any = {
  almostCentered: {
    // backgroundColor: "red",
    position: "fixed",
    width: "20%",
    textAlign: "center",
    top: "5%",
    left: "50%",
    padding: "20px",
    marginLeft: "-20%",
  },
  topArea: {
    backgroundColor: "#transparent",
    // backdropFilter: "opacity(100%) blur(200px)",
    backdropFilter: "blur(5px) opacity(98%)",
    WebkitBackdropFilter: "blur(5px) opacity(98%)",
    minHeight: "10%",
    position: "fixed",
    width: "100%",
    textAlign: "center",
    // top: "5%",
    left: "50%",
    padding: "20px",
    transform: "translate(-50%,0)",
    fontSize: 50,
    top: -10,
    borderRadius: "20px",
    zIndex: 2,
  },
  centeredText: {
    // backgroundColor: "#FFFFFF",
    // height: "15%",
    height: "5%",
    position: "fixed",
    width: "100%",
    textAlign: "center",
    // top: "5%",
    left: "50%",
    // padding: "20px",
    transform: "translate(-50%,0)",
    top: "2%",
    zIndex: 2,
  },
  topLeftContents: {
    position: "fixed",
    width: "8%",
    // height: "8%",
    top: "2%",
    // backgroundColor: "#FFFFFF",
    padding: "1%",
    marginLeft: "2%",
    zIndex: 2,
  },
  topRightContents: {
    position: "fixed",
    width: "8%",
    // height: "8%",
    top: "2%",
    // backgroundColor: "#FFFFFF",
    padding: "1%",
    right: "10%",
    zIndex: 2,
  },
  topRightContents2: {
    position: "fixed",
    width: "8%",
    // height: "8%",
    top: "2%",
    right: "-1%",
    // backgroundColor: "#FFFFFF",
    padding: "1%",
    // marginLeft: "83%",
    zIndex: 2,
  },
  bottomToolbar: {
    position: "fixed",
    width: "100%",
    // padding: "1%",
    backgroundColor: "#FFFFFF",
    // top: "80%",
    bottom: 0,
    alignItems: "center",
    justifyContents: "center",
    textAlign: "center",
    zIndex: 1,
    height: "12.5%",
    minHeight: "12.5%",
    maxHeight: "12.5%",
  },
  bottomCenterContext: {
    backgroundColor: "#FFFFFF",
    // height: "15%",
    height: "5%",
    width: "8%",
    position: "fixed",
    // top: "5%",
    left: "50%",
    // padding: "5px",
    transform: "translate(-50%,0)",
    bottom: "5%",
    alignItems: "center",
    justifyContents: "center",
    textAlign: "center",
    zIndex: 2,
  },
  bottomLeftContext: {
    position: "fixed",
    alignItems: "center",
    justifyContents: "center",
    textAlign: "center",
    zIndex: 1,
    backgroundColor: "#FFFFFF",
    width: "8%",
    left: "2%",
    // padding: "1%",
    bottom: "5%",
    height: "8%",
  },
  bottomRightContext: {
    position: "fixed",
    alignItems: "center",
    justifyContents: "center",
    textAlign: "center",
    zIndex: 1,
    backgroundColor: "#FFFFFF",
    width: "8%",
    // left: "83%",
    right: "2%",
    // padding: "1%",
    bottom: "5%",
    height: "5%",
  },
  progressPlaybar: {
    position: "fixed",
    // width: "100%",
    // padding: 5,
    backgroundColor: "rgb(83,155,237,1)",
    // bottom: "12.5%",
    // borderRadius: "20px",
    // alignItems: "center",
    // justifyContents: "center",
    // textAlign: "center",
    bottom: "12.5%",
    pointerEvents: "none",
    zIndex: 3,
  },
};
