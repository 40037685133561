import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { connectWithoutDone } from '../../core/connection';
import { contents } from './ContentList';
interface ParamTypes {
    id: string
  }

function Content(props) {
    const { id } = useParams<ParamTypes>();
    const content = contents.find(c => c.id === Number(id));
    useEffect(() => {
        if (content) {
            window.location.href = content.url;
        }
      }, [content]);
    return <div></div>
}

export default connectWithoutDone(
    state=>({}),
    dispatch=>({}),
    Content
)

