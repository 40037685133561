import React, { forwardRef, useEffect, useRef, useState } from "react";
import { BehaviorSubject } from "rxjs";
import {
  ArchType,
  AvocadoScoreComponent,
  ScoreType,
  PlayOption,
  EditType,
  LayoutAlignType,
  OptionType,
  AvocadoModule,
} from "juice-avocado-web";
import { FileLoader } from "./avocadoJsonLoader";
import {
  AvocadoBridge,
  ScoreActionType,
  SendActionToGenie,
} from "./avocado-bridge";
import { AvocadoBar } from "./avocadoBar";
import { useParams } from "react-router-dom";
import queryStirng from "query-string";
import { AvocadoPDFConverter } from "./avocadoPDFConverter";
// import "svg2pdf.js";

const AVModule = AvocadoModule.create();
AVModule.setPlatform(ArchType.WEB, require("tone"));
export function AVCDREPETITIONTEST() {
  const { query } = useParams<any>();
  const queryObj = queryStirng.parse(query);
  const {
    sheetId,
    full_score,
    authToken,
    //test용
    login,
    purchase,
  } = queryObj;
  const {
    // width,
    height,
  } = window.screen;
  const width = window.innerWidth;

  const [score, setScore] = useState(undefined);
  const [fileReady, setFileReady] = useState(false);
  //const [score, setScore] = useState(라라라);
  const state = useRef({
    playOption: {
      // playbar: "note",
      // playbarSubject: new BehaviorSubject(false),
    } as PlayOption,

    drawOption: {
      // score_type: ScoreType.PLAYER,
      // score_width: 1200,
      // scale: 0.75,
      // scale: 0.2,
    } as OptionType,
  }).current;

  const marginTop = 900;
  const marginLeft = 5;
  const [draw, redraw] = useState(0);
  const avocadoRef = React.createRef<HTMLDivElement>();
  const [avocado, _] = useState<AvocadoScoreComponent>(
    AvocadoScoreComponent.createAvocado()
  );
  const [ctx, setCtx] = useState();
  const [title, setTitle] = useState("");
  const [creator, setCreator] = useState([""]);
  const [yPos, setYpos] = useState<number>(0);
  const [xPos, setXpos] = useState<number>(0);
  const playbarRef = useRef<HTMLDivElement>(null);
  const score_width = 1100; //width * 3; //1200;
  const adjust = 1;
  const scale = (width / score_width) * adjust;
  const topScale = height / marginTop;
  const avocadoSetting = async (avcd: AvocadoScoreComponent, context: any) => {
    state.playOption = {
      playbar: "note",
      playbarSubject: new BehaviorSubject(false),
    } as PlayOption;
    state.drawOption = {
      score_type: ScoreType.PLAYER,
      score_width: score_width,
      FIRST_BLOCK_LEFT_MARGIN: 0,
      FIRST_PAGE_PADDING_TOP: marginTop,
      SCORE_MARGIN_BOTTOM: 500,
      SCORE_MARGIN_LEFT: 50,
      SCORE_MARGIN_RIGHT: 50,
      FIRST_PAGE_BLOCK: 4,
      BLOCK_PER_PAGE: 4,
      MAX_MEASURE_PER_BLOCK: 4,
      auto_stem: true,
      paging: true,
      // scale: 0.75,
      scale: scale,
    } as OptionType;
    state.playOption.prebeat = false;
    state.playOption.metronome = false;
    state.playOption.repeat = true;
    avocado.onInit(context, ArchType.WEB);
    if (full_score !== "Y") {
      state.playOption.preview_mode = true;
      state.drawOption.preview_mode = true;
    }
    avocado.drawV3(score, state.drawOption, state.playOption);
    avocado.score_type = ScoreType.PLAYER;
    avocado.measure_listening = true;
    let activating = false;
    avocado.scorePartwiseV2.setOnEdit((type, value) => {
      if (type === EditType.PRESS_MEASURE) {
        state.playOption.start_measure = value ?? 0;
        if (avocado.is_playing$().value) {
          avocado.stop();
          avocado.playV2(false, state.playOption);
        } else {
          if (avocado.is_paused$().value) {
            //일시 정지 상태에서 마디 누를시 stop 후 해당마디 재생
            avocado.stop();
          }
          avocado.playbar.show_playbar_current(value ?? 0);
        }
      } else if (type === EditType.PLAYBAR) {
        //playbar 따라서 View 변환
        if (value.layout && value.layout === LayoutAlignType.AUTOMATIC) {
          setYpos(value.y);
          if (playbarRef.current) {
            // playbarRef.current.style.top =
            //   value.y * (state.drawOption.scale ?? 1) + marginTop + "px";
            playbarRef.current.style.top =
              value.y * (state.drawOption.scale ?? 1) + topScale + "px";
            playbarRef.current.style.left =
              value.x * (state.drawOption.scale ?? 1) + marginLeft + "px";
            playbarRef.current.style.width = "5px";
            // playbarRef.current.style.height = value.height + "px";
            playbarRef.current.style.height = String(height / 5) + "px";
          }
        } else if (value.layout && value.layout === LayoutAlignType.STRAIGHT) {
          setXpos(value.x);
        }
      }
    });
    setTitle(avocado.scorePartwiseV2.credit.title);
    setCreator(avocado.scorePartwiseV2.credit.creator);
  };

  useEffect(() => {
    if (score) {
      const ctxF = avocado?.getContextFromElement(
        avocadoRef.current,
        1200,
        500
      );
      setCtx(ctxF);
      avocadoSetting(avocado, ctxF);
    }
    if (avocado && score && ctx) {
      avocado.stop();
      avocadoSetting(avocado, ctx);
    }
    const playsub = avocado?.is_playing$().subscribe((val) => {
      setPlaying(val);
    });
    const pausesub = avocado?.is_paused$().subscribe((val) => {
      setPaused(val);
    });
    const totalTime = avocado.total_Time$().subscribe((val) => {
      //   setTotalTime(val);
      setTotalTime(Math.ceil(val));
    });
    const playTime = avocado.play_Time$().subscribe((val) => {
      //   setCurrentTime(val);
      setCurrentTime(Math.ceil(val));
    });
    return () => {
      playsub?.unsubscribe();
      pausesub?.unsubscribe();
      totalTime?.unsubscribe();
      playTime?.unsubscribe();

      if (avocado) {
        onStop();
        avocado.destroy();
      }
    };
  }, [score]);

  useEffect(() => {
    setPurchase(purchase && purchase === "Y");
    setLogin(login && login === "Y");
  }, [queryObj]);

  const [playing, setPlaying] = useState(false);
  const [paused, setPaused] = useState(false);
  const [totalTime, setTotalTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const playingStatus = useRef(null);
  const [isInitialPlay, setIsInitialPlay] = useState(true);
  const [isLogin, setLogin] = useState(false);
  const [isPurchased, setPurchase] = useState(false);
  const isAndroid =
    navigator.userAgent.match(/Android/i) == null ? false : true;
  useEffect(() => {
    if (!playing) playingStatus.current = "stop";
    if (playing) playingStatus.current = "play";
  }, [playing]);
  const onStop = () => {
    if (avocado) {
      state.playOption.start_measure = undefined;
      avocado.stop();
      playingStatus.current = "stop";
      if (avocado.playbar) avocado.playbar.stopBar();
    }
  };

  const onPlay = async () => {
    if (avocado) {
      avocado.playbar.show_playbar_current(state.playOption.start_measure ?? 0);
      await avocado.playV2(false, state.playOption);
      playingStatus.current = "play";
      if (isInitialPlay) {
        const msg = `toast^악보 미리듣기 중입니다.^${
          !isLogin ? "로그인" : isPurchased ? "악보 다운로드" : "악보 구매하기"
        }`;
        SendActionToGenie(msg, (res) => setWebToApp(res));
        setIsInitialPlay(false);
      }
    }
  };

  const onPause = () => {
    if (avocado) {
      avocado.pause();
      playingStatus.current = "pause";
    }
  };

  useEffect(() => {
    playbarRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [yPos]);
  const [webToApp, setWebToApp] = useState("");
  const [hideIos, setHideIos] = useState(false);
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
      }}
    >
      {/* <AvocadoPDFConverter element={document.getElementById("score")} /> */}
      <div
        ref={playbarRef}
        style={{
          position: "absolute",
          pointerEvents: "none",
          height: height / 10,
          // backgroundColor: "red",
          zIndex: 1,
        }}
      ></div>
      {/* <div
        style={{
          position: "absolute",
          pointerEvents: "none",
          top: 200,
          zIndex: 5,
        }}
      >
        {webToApp}
      </div> */}
      <AvocadoBar
        title={title}
        isPlaying={playingStatus.current === "play"}
        onPlay={() => {
          playing ? onPause() : onPlay();
          if (playing) {
            onPause();
            SendActionToGenie(ScoreActionType.pause, (res) => setWebToApp(res));
          } else {
            onPlay();
            SendActionToGenie(ScoreActionType.play, (res) => setWebToApp(res));
          }
        }}
        onStop={() => {
          onStop();
          SendActionToGenie(ScoreActionType.stop, (res) => setWebToApp(res));
        }}
        onMore={() => {
          SendActionToGenie(ScoreActionType.more, (res) => setWebToApp(res));
        }}
        onClose={() => {
          onStop();
          SendActionToGenie(ScoreActionType.close, (res) => setWebToApp(res));
        }}
        onDownload={() => {
          SendActionToGenie(ScoreActionType.download, (res) =>
            setWebToApp(res)
          );
        }}
        onPurchase={() => {
          SendActionToGenie(ScoreActionType.purchase, (res) =>
            setWebToApp(res)
          );
        }}
        onCredit={() => {
          SendActionToGenie(ScoreActionType.credit, (res) => setWebToApp(res));
        }}
        onToast={(message) => {
          SendActionToGenie(`toast^${message}`, (res) => setWebToApp(res));
        }}
        time_percent={(currentTime / totalTime) * 100}
        hideIos={hideIos}
      />

      <div
        id="container"
        style={
          {
            // marginTop: marginTop,
            // position: "fixed",
            // height: height / 7,
          }
        }
      >
        <FileLoader
          genieToken={authToken}
          sheetId={sheetId}
          onUpload={(file) => {
            const j = file;
            j.version = "v2";
            setScore(j);
          }}
          onReady={async (json) => {
            if (json === false) setFileReady(false);
            else if (json) {
              setFileReady(true);
              setScore(json);
            }
          }}
        />
        <AvocadoBridge
          onStop={() => {
            if (playingStatus.current === "play") onStop();
          }}
          onPlay={async () => {
            if (playingStatus.current !== "play") await onPlay();
          }}
          onPause={() => {
            if (playingStatus.current === "play") onPause();
          }}
          ios_hide={() => {
            setHideIos(true);
          }}
          ios_show={() => {
            setHideIos(false);
          }}
        />
      </div>
      <div
        style={{
          ...styles.titleArea,
          // top: marginTop,
          // top: 700,

          fontSize: width < 420 ? 25 : 40,
          // fontFamily: isAndroid ? "NotoSansCJKKr" : "AppleSDGothic",
          fontWeight: "normal",
          // backgroundColor: "#FFFFFF",
          // // backgroundColor: "red",

          // minHeight: "15%",
          backgroundColor: "#FFFFFF",
          top: height / 7,
          position: "absolute",
          flex: 1,
          zIndex: 1,
        }}
      >
        {title === "" ? "" : title}
      </div>
      <div
        style={{
          ...styles.songInfoArea,
          top: height / 5,
          fontSize: width < 420 ? 8 : 10,
          whiteSpace: "pre-line",
          lineHeight: 1,
          flex: 1,
          left: "2%",
          textAlign: "left",
          backgroundColor: "#FFFFFF",
        }}
      >
        {title === ""
          ? ""
          : `${title} 수록곡\n
        [쉬워요 2단계]\n*원곡은 AKey 이나 연주의 편의상 CKey 로 조 옮김.\n*원곡의 (1절과 후렴)부분을 간단한 멜로디와 반주로 편곡.`}
      </div>

      <div
        style={{
          ...styles.songInfoArea,
          top: height / 5,
          fontSize: width < 420 ? 8 : 10,
          whiteSpace: "pre-line",
          lineHeight: 1,
          flex: 1,
          right: "2%",
          textAlign: "right",
          backgroundColor: "#FFFFFF",
        }}
      >
        {title === ""
          ? ""
          : `Ylva Dimberg, 조휴일, 유효, 민지(MINJI) 작사\n250, Ylva dimberg 작곡\n뉴진스 노래\n악보바다 편곡`}
      </div>

      <div
        style={{
          marginLeft: marginLeft,
          // position: "absolute",
          // marginTop: marginTop,
          backgroundColor: "#FFFFFF",
          // backgroundColor: "red",
          width: width,
        }}
        id="score"
        ref={avocadoRef}
      ></div>
    </div>
  );
}

const styles: any = {
  titleArea: {
    fontFamily: "Pretendard",
    fontWeight: 0,
    width: "45%",
    textAlign: "center",
    left: "50%",
    transform: "translate(-50%,0)",
  },
  songInfoArea: {
    position: "absolute",
    fontFamily: "Pretendard",
    // fontSize: height / 22,
    fontWeight: 0,
    // backgroundColor: "#FFFFFF",
    // textAlign: "center",
  },
};