import React, { CSSProperties } from 'react'
import { connectWithoutDone } from '../../core/connection';

export const contents = [
    {id:1, title: 'Rachmaninoff - piano concerto no3 1st mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/1.+S.Rachmaninoff+Piano+Concerto+No.3.mp3'},
    {id:2, title: 'Ravel - Bolero', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/2.+M.Ravel+Bolero.mp3'},
    {id:3, title: 'Satie - Je te veux', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/3.+Satie+-+Je+te+veux.wav'},
    {id:4, title: 'Sarasate - Introduction and Tarantella', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/4.+P.Sarasate+Introduction+and+Tarantella.mp3'},
    {id:5, title: 'Saint-Saens - The carnival of the animals no.13 the swan', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/5.+C.Saint-Saens+The+Carnival+of+the+Animals+No.+13+%22The+Swan%22.mp3'},
    {id:6, title: 'Schubert - Arpeggione sonata 1st mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/60.+Gubaidulina+Offertorium.mp3'},
    {id:7, title: 'David Concertino op.4 2nd mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/7.+David+-+Concertino+op.4+2nd+mov.wav'},
    {id:8, title: 'Mozart - piano concerto no.21 2nd mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/8.+W.A.Mozart+Piano+Concerto+No.21+K.467+2nd+mov.mp3'},
    {id:9, title: 'Weber - der Freischütz overture', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/9.+C.M.Weber+Der+Freischutz+Op.77.mp3'},
    {id:10, title: 'Mendelssohn - symphony no.4 2nd mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/10.+F.Mendelssohn+Symyphony+No.4%2C+Op.90+2nd+mov.mp3'},
    {id:11, title: 'Handel - Rinaldo, aria \'Lascia ch\'io pianga', url: `https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/11.+G.F.Handel+-+Lascia+ch'io+pianga.mp3`},
    {id:12, title: 'Kreisler - Praeluduim and Allegro', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/12.+F.Kreisler+Praludium+and+Allegro.mp3'},
    {id:13, title: 'Dvorak - Romantic piece op75 part 1', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/13.+Dvorak+-+Romantic+piece+op75+part+1.wav'},
    {id:14, title: 'Schubert - violin sonata no.2 1st mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/14.+F.Schubert+Violin+Sonata+No.2.mp3'},
    {id:15, title: 'Chausson - op.2 nanny', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/15.+Chausson+-+op.2+nanny.wav'},
    {id:16, title: 'Schumann - string quartet op.41 no.1 2nd mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/16.+R.Schumann+String+Quartet+Op.14+No.1+2nd+mov.mp3'},
    {id:17, title: 'Bartok - mikrokosmos no.48', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/17.+Bartok+-+mikorkosmos+no.48.wav'},
    {id:18, title: 'Bartok - mikrokosmos no.55', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/18.+Bartok+-+mikorkosmos+no.55.wav'},
    {id:19, title: 'Bartok - mikrokosmos no.32', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/19.+Bartok+-+mikorkosmos+no.32.wav'},
    {id:20, title: 'Bartok - mikrokosmos no.34', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/20.+Bartok+-+mikorkosmos+no.34.wav'},
    {id:21, title: 'Debussy - reverie', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/21.+Debussy+Reverie.mp3'},
    {id:22, title: 'Debussy - Suite bergamasque no.1', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/22.+Debussy+Suite+Bergamasque+No.1.mp3'},
    {id:23, title: 'Debussy - Suite bergamasque no.2', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/23.+Debussy+Suite+Bergamasque+No.2.mp3'},
    {id:24, title: 'Debussy - Suite bergamasque no.4', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/24.+Debussy+Suite+Bergamasque+No.4.mp3'},
    {id:25, title: 'Debussy - prelude book 1 no.11', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/25.+Debussy+Prelude%2C+book+1%2C+No.11.mp3'},
    {id:26, title: 'Debussy - Mazurka', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/26.+Debussy+Mazurka.mp3'},
    {id:27, title: 'Paisiello - Nel cor piu non mi sento', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/27.+Paisiello+-+Nel+cor+piu+non+mi+sento.wav'},
    {id:28, title: 'Falconieri - occhietti amati', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/28.+Falconieri+-+occhietti+amati.wav'},
    {id:29, title: 'Schubert - Winterrieise no.9 Irrlicht', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/29.+Schubert+-+Winterrieise+no.9+Irrlicht.wav'},
    {id:30, title: 'Suppe - Dichter und Bauer overture', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/30.+F.V.Suppe+Dichter+und+Bauer+Overture.mp3'},
    {id:31, title: 'Schubert - piano trio no.2 2nd mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/31.+Schubert+-+piano+trio+no.2+2nd+mov.wav'},
    {id:32, title: 'Schubert - Schwanengesang no.4 Staendchen', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/32.+F.Schubert+Schwanengesang.mp3'},
    {id:33, title: 'Mozart - Die Verschweigung', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/33.+W.A.Mozart+Die+Verschweigung.mp3'},
    {id:34, title: 'Bach - bist du bei mir', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/34.+Bach+-+bist+du+bei+mir.wav'},
    {id:35, title: 'Schubert - An die Musik', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/35.+F.Schubert+An+die+Musik+D.547.mp3'},
    {id:36, title: 'Mendelssohn - piano trio no.1 1st mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/36.+F.Mendelssohn+Piano+Trio+No.1+Op.49.mp3'},
    {id:37, title: 'Beethoven - trio for 2 oboes and English horn', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/37.+L.V.Beethoven+Trio+for+2+Oboes+and+Englisch+Horn+in+C+Major%2C+Op.87.mp3'},
    {id:38, title: 'Clara Schumann - piano trio op.17', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/38.+Clara+Schumann+piano+trio+op.17.wav'},
    {id:39, title: 'Beethoven - Andante Favori in F 1-8', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/39.+L.V.Beethoven+Andante+Favori.mp3'},
    {id:40, title: 'Beethoven - Andante Favori in F 52-59', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/40.+L.V.Beethoven+Andante+Favori.mp3'},
    {id:41, title: 'Schubert - winterreise no.15 die Krahe', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/41.+Schubert+-+winterreise+no.15+die+Krahe.wav'},
    {id:42, title: 'Mahler - symphony no.9 4th mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/42.+G.Mahler+Symphony+No.9+4th+mov.mp3'},
    {id:43, title: 'Beethoven symphony no.7 2nd mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/43.+L.V.Beethoven+Symphony+No.7+2nd+mov.mp3'},
    {id:44, title: 'Tchaikovsky - album for the young no.21 sweet dreams', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/44.+Tchaikovsky+-+album+for+the+young+no.21+sweet+dreams.wav'},
    {id:45, title: 'Beethoven - violin sonata no.2 3rd mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/45.L.V.Beethoven+Violin+Sonata+No.2+3rd+mov.mp3'},
    {id:46, title: 'Bach - Violin sonata no.1 4th mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/46.+J.S.Bach+Violin+Sonata+No.1+Presto.mp3'},
    {id:47, title: 'Mozart - string quartet no.19 1st mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/47.+W.A.Mozart+String+Quartet+No.19%2C+K.465+1st+mov.mp3'},
    {id:48, title: 'Beethoven - allegro for 2 flutes', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/48.+Beethoven+-+allegro+for+2+flutes.wav'},
    {id:49, title: 'Weber - trio op.67', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/49.+Weber+piano+trio+op.63.mp3'},
    {id:50, title: 'Schumann - dichterliebe no.7 Ich grolle nicht', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/50.+R.Schumann+dichterliebe+no.7+Ich+grolle+nicht.mp3'},
    {id:51, title: 'Schumann - Valse noble op.9', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/51.+R.Schumann+Valse+Noble.mp3'},
    {id:52, title: 'Schumann - Kinderszenen op.15 no.13', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/52.+R+Schumann+Kinderszenen+op.15+No.13.mp3'},
    {id:53, title: 'Tchaikovsky - album for the young no.1 morning prayer', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/53.+Tchaikovsky+-+album+for+the+young+no.1+morning+prayer.wav'},
    {id:54, title: 'Beethoven - piano sonata no.21 1st mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/54.+L.V.Beethoven+Piano+sonata+No.21+Op.53+1st+mov.mp3'},
    {id:55, title: 'Mozart - Ave verum corpus', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/55.+W.A.Mozart+Ave+Verum+Corpus+K.V.618.mp3'},
    {id:56, title: 'Franck - piano quintet in f minor 1st mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/56.+C.Frank+Piano+Quintet+FWV+7+1st+mov+pt.1.mp3'},
    {id:57, title: 'Franck - piano quintet in f minor 1st mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/57.+C.Frank+Piano+Quintet+FWV+7+1st+mov+pt.2.mp3'},
    {id:58, title: 'Strauss - 4 lieder op.27 no.4 morgen', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/58.+Strauss+-+4+lieder+op.27+no.4+morgen.wav'},
    {id:59, title: 'Bach - musical offering the kings theme', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/59.+J.S.Bach+The+Musical+Offering_1.mp3'},
    {id:60, title: 'Gubaidulina - Offertorium the kings theme', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/60.+Gubaidulina+Offertorium.mp3'},
    {id:61, title: 'Beethoven - piano sonata no.3 2nd mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/61.+L.V.Beethoven+Piano+sonata+No.3+2nd+mov.mp3'},
    {id:62, title: 'Beethoven - piano sonata no.4 2nd mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/61.+L.V.Beethoven+Piano+sonata+No.3+2nd+mov.mp3'},
    {id:63, title: 'Beethoven - piano sonata no.8 2nd mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/61.+L.V.Beethoven+Piano+sonata+No.3+2nd+mov.mp3'},
    {id:64, title: 'Puccini - Crisantemi', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/64.+Puccini+-+Crisantemi.mp3'},
    {id:65, title: 'Mozart - Ave verum corpus', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/65.+L.V.Beethoven+12+Variations+in+G+Major+on+See+the+Conquring+Hero+Comes+WoO+45.mp3'},
    {id:66, title: 'Beethoven - violin sonata no.5 1st mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/66.+L.V.Beethoven+Violin+Sonata+No.5+1st+mov_1.mp3'},
    {id:67, title: 'Beethoven - string quartet no.14 1st mov', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/67.+L.V.Beethoven+String+Quartet+No.14+1st+mov.mp3'},
    {id:68, title: 'Chopin - prelude no.4', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/68.+F.Chopin+Prelude+Op.28+No.4.mp3'},
    {id:69, title: 'Chopin - prelude no.20', url: 'https://juice-admin.s3.ap-northeast-2.amazonaws.com/earing/69.+Chopin+Prelude+in+C+minor+Op+28+No+20.mp3'},
]

function ContentList(props) {

    return <div style={style.divTable}>
        <div style={style.divTableBody}>
        {contents.map(c => <div key={c.id} style={style.divTableRow}>
        <div style={style.divTableCell}>{c.id}</div>
        <div style={style.divTableCell}>{c.title}</div>
        <div style={style.divTableCell}><a href={c.url}>{c.url}</a></div>
    </div>)}</div>
    </div>
}

export default connectWithoutDone(
    state=>({}),
    dispatch=>({}),
    ContentList
)

const style:{[s:string]:CSSProperties} = {
    divTable: {
        display: 'table',
        width: '100%',
    },
    divTableRow: {
        display: 'table-row',
    },
    divTableHeading: {
        backgroundColor: '#EEE',
        display: 'table-header-group',
        fontWeight: 'bold',
    },
    divTableCell: {
        border: '1px solid #999999',
        display: 'table-cell',
        padding: '3px 10px',
    },
    divTableFoot: {
        backgroundColor: '#EEE',
        display: 'table-footer-group',
        fontWeight: 'bold',
    },
    divTableBody: {
        display: 'table-row-group',
    }
}

