/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
// import { Button, ButtonGroup } from 'reactstrap';
import { Base } from '../../core/Base';
import { binding, connectWithoutDone } from '../../core/connection';
import { History } from 'history'
import { AuthAction } from './Auth.action';
import { AlertAction } from '../alert/Alert.action';
import { Button, ButtonGroup } from 'reactstrap';
interface Props {
    history: History
    AuthAct:typeof AuthAction
    AlertAct:typeof AlertAction
}

interface States {
    insider:boolean
    username:string
    password1:string
    password2:string
    email:string
    company:string
    tel:string
}

class AuthSignUp extends Base<Props, States> {

    state = {
        insider:true,
        username:'',
        password1:'',
        password2:'',
        email:'',
        company:'',
        tel:''
    }

    async signUp() {
        const { AuthAct, AlertAct } = this.props
        if (!this.state.insider && this.state.company === '(주)주스') {
            AlertAct.show('(주)주스를 소속으로 만들 수 없습니다.', 'danger')
            return
        }
        if (this.state.password1 !== this.state.password2) {
            AlertAct.show('두 비밀번호가 일치하지 않습니다.', 'danger')
            return
        }
        const res = await AuthAct.signUp(this.state.username, this.state.password1, 
            this.state.insider, {
                email:this.state.email,
                company:this.state.company,
                tel:this.state.tel
            }
        )
        if (res.message === 'success') {
            AlertAct.show('가입이 완료되었습니다. 관리자 승인 후 로그인 할 수 있습니다.', 'success')
            this.signIn()
        } else {
            AlertAct.show(res.message, 'danger')
        }
    }

    signIn() {
        const { history} = this.props
        history.push('/auth')
    }

    renderForm(label:string, key:string, type:string='text') {
        return <div className="form-group">
            <label>{label}</label>
            <input type={type} className="form-control" placeholder={label}
                value={this.state[key]} 
                // @ts-ignore
                onChange={e=> this.setState({[key]:e.target.value})}
                onKeyPress={(e:any)=> {
                    if(e.code === 'Enter' && (key === 'tel' || key === 'password2')){
                        this.signUp()
                    }
                }}/>
        </div>
    }

    renderOutsiderForm() {
        return <div>
            {this.renderForm('회사(소속)', 'company')}
            {this.renderForm('이메일', 'email', 'email')}
            {this.renderForm('연락처', 'tel')}
        </div>
    }

    render() {
        return <div css={css`
            padding: 0px 10px;
            @media screen and (min-width: 768px){
                margin-left: 40%;
            }
        `}>
            <div className="col-md-6 col-sm-12">
                <div css={css`
                    @media screen and (max-width: 450px) {
                        margin-top: 10%;
                    }
                    @media screen and (min-width: 768px){
                        margin-top: 40%;
                    }
                `}>
                    <ButtonGroup>
                        <Button color={this.state.insider ? "dark" : "light"}
                            onClick={()=> this.setState({insider:true})}>임직원</Button>
                        <Button color={this.state.insider ? "light" : "dark"}
                            onClick={()=> this.setState({insider:false})}>외부손님</Button>
                    </ButtonGroup>
                    {this.renderForm('아이디', 'username')}
                    {this.renderForm('비밀번호', 'password1', 'password')}
                    {this.renderForm('비밀번호 확인', 'password2', 'password')}
                    {!this.state.insider && this.renderOutsiderForm()}
                    <button type="submit" className="btn" css={css`
                        background-color: #000 !important;
                        color: #fff;
                        margin-right:3px;
                    `} onClick={()=> this.signUp()}>Register</button>
                    <button type="submit" className="btn btn-secondary"
                        onClick={()=> this.signIn()}>Back to login</button>
                </div>
            </div>
        </div>
    }
}

export default connectWithoutDone(
    state=>({}),
    dispatch=>({
        AuthAct:binding(AuthAction, dispatch),
        AlertAct:binding(AlertAction, dispatch)
    }),
    AuthSignUp
)