/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Base } from '../../core/Base';
import { binding, connectWithoutDone } from '../../core/connection';
import { AuthAction, AuthState } from '../auth/Auth.action';
import { History } from 'history'
// import Admin from './Admin';
import Admin from './Admin';

// import routes from "./routes.js";
interface Props {
    auth:AuthState
    history:History
    AuthAct: typeof AuthAction
}

interface States {
    path:string
}

class AdminLayout extends Base<Props, States> {

    profileMenu = [{
        name:'Profile',
        onClick:()=> {
            const {history} = this.props
            history.push('/admin/profile')
        }
    },{
        name:'Logout',
        onClick:()=>{
            const {AuthAct} = this.props
            AuthAct.signOut()
        }
    }]

    notificationMenu = [{
        name:'Mike John responded to your email',
        onClick:()=>{
            console.log('1')
        }
    },{
        name:'You have 5 new tasks',
        onClick:()=>{
            console.log('2')
        }
    }]

    bind(props:Props, state:States) {
        const {auth, history} = props
        if (!auth.profile) {
            history.push('/auth')
        }
        state.path = window.location.href
    }

    render() {
        const {auth} = this.props
        return <Admin 
            profile={auth.profile}
            profileMenu={this.profileMenu} 
            notificationMenu={this.notificationMenu}
            dashboardMenu={()=> console.log('dashboardMenu')}
            onSearch={(keyword:string)=> console.log(keyword)}
        />
    }
}

export default connectWithoutDone(
    state=>({
        auth:state.auth
    }),
    dispatch=>({
        AuthAct:binding(AuthAction, dispatch)
    }),
    AdminLayout
)