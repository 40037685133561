import React, { useState } from 'react'
import Button from 'components/CustomButtons/Button'
import { LinearProgress } from '@material-ui/core'

export function DemoMusicTransformer() {
    const [link, setLink] = useState('')
    const [progress, setProgress] = useState(0)
    const [progress2, setProgress2] = useState(0)
    const [progress3, setProgress3] = useState(0)
    const runProgress = (ms, update) => {
        for(let i=0;i<10;i++) {
            setTimeout(()=>{
                update(10*(i+1))
            },ms/10*(i+1))
        }
    }
    const download = (name, link) => {
        const a = document.createElement('a')
        a.download = name
        a.href = link
        a.click()
    }
    return <>
        <h3>1. 유튜브 음원 선택</h3>
        <div>
            <input value={link} onChange={e=> setLink(e.target.value)}/>
            <Button color="primary" onClick={()=> {
                runProgress(5000, setProgress)
                setTimeout(()=>{
                    runProgress(5000, setProgress2)
                }, 5500)
            }}>완료</Button>
            <div>Transcription</div>
            <LinearProgress style={{marginBottom:10}} value={progress} variant={'determinate'}/>
            <div>Extraction</div>
            <LinearProgress value={progress2} variant={'determinate'}/>
        </div>
        <h3>2. 분석 결과물 확인</h3>
        <div>
            <Button color={progress===100 ? 'primary' : undefined} disabled={progress!==100}
                onClick={()=> download('transcription.mid', '/assets/demo/musictransformer/transcription.mid')}>Transcription</Button>
            <Button color={progress2===100 ? 'primary' : undefined} disabled={progress2!==100}
                onClick={()=> download('extraction.mid', '/assets/demo/musictransformer/pitch_BTS.mp3.mid')}>Extraction</Button>
        </div>
        <h3>3.음악 합성</h3>
        <div>
            <Button disabled={progress2 !== 100} 
                color={progress2===100 ? 'primary' : undefined} 
                onClick={()=> runProgress(5000, setProgress3)}>
                    합성 시작
            </Button>
            <LinearProgress value={progress3} variant={'determinate'}/>
            {progress3 === 100 && <>
                <Audio label={'Style A'} src={"/assets/demo/musictransformer/style_A.mp3"} />
                <Audio label={'Style B'} src={"/assets/demo/musictransformer/style_B.mp3"} />
                <Audio label={'Style C'} src={"/assets/demo/musictransformer/style_C.mp3"} />
            </>}
        </div>
    </>    
}

function Audio({label,src}:{label:string, src:string}) {
    return <div style={{alignItems:'center', display:'flex', flexDirection:'row'}}>
        {label} : <audio src={src} controls></audio>
    </div>
}