export const U = {
    union:(arr:Array<Array<any>>) => {
        const out:any = []
        arr.forEach(arr_2=>arr_2.forEach(item=> {
            if (out.indexOf(item) === -1) {
                out.push(item)
            }
        }))
        return out
    },
    img:(url)=>{
        if(url) return url
        else return '/assets/profile.png'
    },
    getId:(item):number=> {
        if (typeof item === 'number') return item
        else return item.id
    },
    downloadBase64:(base64, filename) => {
        const blob = U.b64toBlob(base64)
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement("a"); //Create <a>
        a.href = blobUrl; //Image Base64 Goes here
        a.download = filename; //File name Here
        a.click(); //Downloaded file
    },
    b64toBlob:(b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    },
}