/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Api, domain } from 'core/Api';
import { Base } from 'core/Base';
import { connectWithoutDone } from 'core/connection';
import { History } from 'history';
import { BoardItem } from 'types/api.types';
import Button from 'components/CustomButtons/Button';
import { AuthState } from 'screens/auth/Auth.action';
import CustomInput from 'components/CustomInput/CustomInput';
import CardFooter from 'components/Card/CardFooter';
import * as custom from 'types/custom.types'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface Props {
    history: History
    location: Location
    auth: AuthState
}

interface States {
    boardItem?:BoardItem
    title:string
    body:string
}

class BoardDetail extends Base<Props, States> {

    state:States = {
        title:'',
        body:''
    }

    async componentDidMount() {
        const { history, location, auth } = this.props
        if (auth.profile) {
            if(!(auth.profile.user as custom.auth.User).is_staff){
                history.push('/admin/dashboard')
                return
            } 
            const b = location.pathname.split('/')
            const id = b[b.length-1]
            if(id === '0') {
                const boardItem = await Api.create<BoardItem>('/api-board/item/',{
                    title:'제목',
                    author:auth.profile.id,
                    body:'내용'
                })
                this.setState({boardItem, body:boardItem.body})
            } else {
                const boardItem = await Api.retrieve<BoardItem>('/api-board/item/', id, {})
                this.setState({boardItem, body:boardItem.body, title:boardItem.title})
            }
        }
    }

    async update() {
        await Api.update<BoardItem>('/api-board/item/', this.state.boardItem.id, {
            title:this.state.title,
            body:this.state.body,
            valid:true
        })
        const {history} = this.props
        history.push('/admin/board/'+this.state.boardItem.id)
    }

    render() {
        if(!this.state.boardItem) return <div/>
        return <div>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                <CardHeader color="primary">
                    <h4 style={styles.cardTitleWhite}>글쓰기</h4>
                    
                </CardHeader>
                <CardBody>
                    <CustomInput formControlProps={{}}
                        inputProps={{
                            placeholder: "제목",
                            inputProps: {
                            "aria-label": "제목"
                            },
                            value:this.state.title,
                            onChange:(e)=>this.setState({title:e.target.value})
                        }}
                        />
                    <CKEditor
                        editor={ ClassicEditor }
                        config={{
                            ckfinder:{uploadUrl:domain+"/upload/"+this.state.boardItem.id}
                        }}
                        data={this.state.body}
                        onReady={ (editor:any) => {
                            editor.setData(this.state.body)
                            // You can store the "editor" and use when it is needed.
                            // console.log( 'Editor is ready to use!', editor );
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            this.setState({body:data})
                            // console.log( { event, editor, data } );
                        } }
                        onBlur={ ( event, editor ) => {
                            // console.log( 'Blur.', editor );
                        } }
                        onFocus={ ( event, editor ) => {
                            // console.log( 'Focus.', editor );
                        } }
                    />
                </CardBody>
                <CardFooter>
                    <div css={css`
                        flex:1;
                        text-align:center;
                    `}>
                        <Button color="success" onClick={()=>{
                            const {history} = this.props
                            history.push('/admin/dashboard')
                        }}>목록</Button>
                        <Button color="success" onClick={()=>{
                            this.update()
                        }}>저장</Button>
                    </div>
                </CardFooter>
                </Card>
            </GridItem>
            </GridContainer>
        </div>
    }
}

export default connectWithoutDone(
    state=>({
        auth:state.auth
    }),
    dispatch=>({
    }),
    BoardDetail
)


const styles:any = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };