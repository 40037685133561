/** @jsxRuntime classic */
/** @jsx jsx */
import { css,jsx } from '@emotion/react'
import Icon from '@material-ui/core/Icon';
import Card from 'components/Card/Card';
import CardFooter from 'components/Card/CardFooter';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Base } from 'core/Base';
import { connectWithoutDone } from 'core/connection';
import { History } from 'history';
import moment from 'moment';
import CardBody from 'components/Card/CardBody';
import { Api } from 'core/Api';
import { Profile, Schedule } from 'types/api.types';
import { AuthState } from 'screens/auth/Auth.action';
import Table from "components/Table/Table.js";
import Button from 'components/CustomButtons/Button';
import { U } from 'core/U';
import { Divider, Modal } from '@material-ui/core';
import { AttendanceUtils } from './AttendanceUtils';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomInput from 'components/CustomInput/CustomInput';
import { useState } from 'react';
import { s2ab, sheet_from_array_of_arrays } from '../coloring/settlement/ColoringSettlement';
declare var XLSX:any;
declare var saveAs:any;
interface Props {
    auth:AuthState
    history: History
}

interface States {
    schedules:Schedule<Profile>[]
    year:number
    month:number
    date:number
    calendar:Array<CalendarRow>
    modalSchedule:Schedule[]
    personalCalendarTarget?:{
        year:number,
        month:number,
        profile:Profile,
        calendar:Array<CalendarCell[]>,
        sum_work_minutes:number
    }
    annual_std:Date
    leave_query:Schedule[]
    reject_schedule_id:number
    reject_content:string
    rejecting:boolean
}

type CalendarRow = {
    profile:Profile
    items:Array<Schedule[]>
}

type CalendarCell = {
    currentMonth:boolean
    date:number
    items:string[],
}
class AttendanceManage extends Base<Props, States> {

    state:States = {
        schedules:[],
        year:0,
        month:0,
        date:0,
        calendar:[],
        modalSchedule:[],
        personalCalendarTarget:undefined,
        annual_std:new Date(),
        leave_query:[],
        reject_schedule_id:-1,
        reject_content:'',
        rejecting:false
    }

    async componentDidMount() {
        const {auth, history} = this.props
        if (auth.profile && auth.profile.user.is_staff) {
            const schedules = await Api.list<Schedule<Profile>[]>('/api-schedule/', {
                request:true
            })
            await Api.expand(schedules, 'profile', '/api-profile/')
            const today = moment()
            const calendar = await this.makeCalendar(today.year(), today.month(), today.date())
            this.setState({schedules, calendar, year:today.year(), month:today.month(), date:today.date(), 
                modalSchedule:[], personalCalendarTarget:undefined})
        } else {
            history.replace('/admin/dashboard')
        }
    }

    async changeCalender(delta:number) {
        const next = moment().year(this.state.year).month(this.state.month).date(this.state.date)
        next.add(delta, 'days')
        const calendar = await this.makeCalendar(next.year(), next.month(), next.date())
        this.setState({
            year:next.year(), 
            month:next.month(), 
            date:next.date(),
            calendar
        })
    }
    
    async makeCalendar(year, month, date):Promise<Array<CalendarRow>> {
        const start = moment().year(year).month(month).date(date).startOf('week').add(1, 'day')
        const end = moment().year(year).month(month).date(date).endOf('week').add(1,'day')
        const profiles = await Api.list<Profile[]>('/api-profile/', {
            company:'(주)주스'
        })
        const schedules = await Api.list<Schedule[]>('/api-schedule/', {
            start__gte:start.clone().utc().format(),
            start__lte:end.clone().utc().format()
        })
        const calendar:Array<CalendarRow> = []
        const profile_index = {}
        profiles.forEach((p,i)=> {
            const item:CalendarRow = {
                profile:p,
                items:[[],[],[],[],[],[],[]]
            }
            profile_index[p.id] = i
            calendar.push(item)
        })
        schedules.forEach(s=> {
            const idx = profile_index[U.getId(s.profile)]
            if(idx !== undefined) {
                const day = (moment(s.start).day() + 7 - 1)%7
                calendar[idx].items[day].push(s)
            }
        })
        const holiday = await Api.list<{name:string, date:string}[]>('/api/holiday', {year:start.year(), month:start.month()+1})
        holiday.filter(h => moment(h.date) >= start && moment(h.date) <= end)
        .forEach(h=> {
            const day = (moment(h.date).day() + 7 -1)%7
            calendar.forEach(row=> row.items[day].push({
                id:-1,
                reason:h.name,
                leave:true,
                request:false,
                rest:0,
                annual_leave:false,
                profile:row.profile.id,
                created: ' '
            }))
        })
        return Promise.resolve(calendar)
    }

    async selectPerson(profile:Profile) {
        const today = moment()
        const {calendar, sum_work_minutes} = await this.makePersonalCalendar(today.year(), today.month(), profile)
        this.setState({personalCalendarTarget:{
            year:today.year(),
            month:today.month(),
            profile:profile,
            calendar,
            sum_work_minutes
        }})
    }

    async makePersonalCalendar(year, month, profile):Promise<{calendar:Array<CalendarCell[]>,sum_work_minutes:number}> {
        const startMonth = moment().year(year).month(month).startOf('month')
        const start = startMonth.clone().startOf('week')
        const endMonth = moment().year(year).month(month).endOf('month').endOf('day')
        const end = endMonth.clone().endOf('week').endOf('day')
        const holiday = await Api.list<{name:string, date:string}[]>('/api/holiday', {year:startMonth.year(), month:startMonth.month()+1})
        
        let schedules:Schedule[] = []
        schedules = await Api.list<Schedule[]>('/api-schedule/', {
            profile:profile.id,
            start__gte:startMonth.clone().utc().format(),
            start__lte:endMonth.clone().utc().format()
        })
        const iter = start.clone()
        const calendar:Array<CalendarCell[]> = []
        let stop = false
        let currentMonth = false
        let sum_work_minutes = 0
        while(!stop) {
            const row:CalendarCell[] = []
            for(let i=0;i<7;i++) {
                if (iter.format() === startMonth.format()) {
                    currentMonth = true
                }
                const items = []
                schedules.filter(s=> moment(s.start).date() === iter.date())
                    .forEach(s=> {
                    if(s.request && s.leave) {
                        items.push(AttendanceUtils.getLeaveType(s)+' 요청중')
                    } else if (s.request) {
                        items.push('수정 요청중')
                    } else if (s.leave) {
                        items.push(AttendanceUtils.getLeaveType(s))
                        if(moment(s.start) < moment()) {
                            const work_minutes = moment(s.end).diff(s.start, 'minutes') - s.rest
                            sum_work_minutes += work_minutes
                        }
                    } else if (currentMonth) {
                        items.push('출근 '+moment(s.start).format('HH:mm'))
                        if (s.end) {
                            items.push('퇴근 '+moment(s.end).format('HH:mm'))
                            const work_minutes = moment(s.end).diff(s.start, 'minutes') - s.rest
                            sum_work_minutes += work_minutes
                            items.push('근무시간 '+ Math.floor(work_minutes/60)+'시간 ' + (work_minutes%60)+'분')
                        }
                    }
                })
                holiday.filter(h=>moment(h.date).date() === iter.date())
                .forEach(h=> {
                    items.push(h.name)
                })
                row.push({
                    currentMonth,
                    date:iter.date(),
                    items
                })
                if (iter.format() === endMonth.clone().startOf('day').format()) {
                    currentMonth = false
                }
                if (iter.format() === end.clone().startOf('day').format()) {
                    stop=true
                    break
                }
                iter.add(1,'days')
            }
            calendar.push(row)
        }
        return Promise.resolve({calendar, sum_work_minutes})
    }

    async changePersonalCalender(delta) {
        const {year, month, profile} = this.state.personalCalendarTarget
        const next = moment().year(year).month(month)
        next.add(delta, 'month')
        const {calendar, sum_work_minutes} = await this.makePersonalCalendar(next.year(), next.month(), profile)
        this.setState({
            personalCalendarTarget: {
                year:next.year(), 
                month:next.month(), 
                profile,
                calendar,
                sum_work_minutes
            }
        })
    }

    renderCalendar() {
        const target = moment().year(this.state.year).month(this.state.month).date(this.state.date)
        const start = target.clone().startOf('week').add(1,'days')
        const end = target.clone().endOf('week').add(1,'days')
        return <GridItem xs={12} sm={12} md={12}>
        <Card>
            <CardHeader color="primary">
                <h4 style={styles.cardTitleWhite as any}>근태달력</h4>

            </CardHeader>
            <CardBody>
                <div css={css`
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    margin:10px;
                `}>
                    <div onClick={()=>this.changeCalender(-7)}><Icon>keyboard_arrow_left</Icon></div>
                    <h4 css={css`margin:0px;margin-bottom:5px;`}>{start.year()}.{start.month()+1}.{start.date()} ~ {end.year()}.{end.month()+1}.{end.date()}</h4>
                    <div onClick={()=>this.changeCalender(7)}><Icon>keyboard_arrow_right</Icon></div>
                </div>
                <div css={css`
                    flex-direction:row;
                    justify-content:space-between;
                    display:flex;
                    border:solid 1px #ccc;
                `}>
                    {['임직원','월요일', '화요일', '수요일', '목요일', '금요일', '토요일','일요일', '합계'].map((cell, j)=> <div key={j} css={css`
                        flex:1;
                        margin-top:5px;
                        margin-bottom:5px;
                        align-items:center;
                        text-align:center;
                        font-weight:bold;
                    `}>
                        <div css={css`color:${j===7?'red':j===6?'blue':'black'};`}>{cell}</div>
                    </div>)}
                </div>
                {this.state.calendar.map((row,i)=> {
                let sum = 0
                return <div key={i} css={css`
                    flex-direction:row;
                    justify-content:space-between;
                    display:flex;
                    padding:0px;
                    border-right:solid 1px #ccc;
                `}>
                    <div css={css`
                        flex:1;
                        align-items:center;
                        border-left:solid 1px #ccc;
                        border-bottom:solid 1px #ccc;
                        padding:5px;
                        height:80px;
                        &:hover {
                            background-color:#CCC;
                            cursor:pointer;
                        }
                    `} onClick={()=> this.selectPerson(row.profile)}>
                        {row.profile.name_en}
                    </div>
                    {row.items.map((schedules, j)=> {
                    return <div key={j} css={css`
                        flex:1;
                        align-items:center;
                        border-left:solid 1px #ccc;
                        border-bottom:solid 1px #ccc;
                        padding:5px;
                        height:80px;
                        &:hover {
                            background-color:#CCC;
                            cursor:pointer;
                        }
                    `} onClick={()=> this.setState({
                        modalSchedule:schedules.filter(s=>s.id !== -1)
                    })}>
                        {schedules.map((s,k)=> {
                        let minutes = 0
                        if (s.end) {
                            minutes = moment(s.end).diff(moment(s.start), 'minutes')-s.rest
                            if (!s.request) {
                                sum += minutes
                            }
                        }
                        return <div key={k}>
                            {s.request ? '요청중' : 
                            s.id === -1 ? s.reason :
                            s.leave ? '연차' : <div>
                                <span style={{color:(s.reason && s.reason.indexOf('외근출근') !== -1) ? 'red':'black'}}>{moment(s.start).format('HH:mm')}</span> - 
                                <span style={{color:(s.reason && s.reason.indexOf('외근퇴근') !== -1) ? 'red':'black'}}>{s.end && moment(s.end).format('HH:mm')}</span><br/> 
                                {s.end && (' ('+(Math.floor(minutes/60))+'시간 '+(minutes%60)+'분)')}
                            </div>}
                        </div>})}
                    </div>})}
                    <div css={css`
                        flex:1;
                        align-items:center;
                        border-left:solid 1px #ccc;
                        border-bottom:solid 1px #ccc;
                        padding:5px;
                        height:80px;
                    `}>{Math.floor(sum/60)+'시간 '+sum%60+'분'}</div>
                </div>})}
                <div style={{textAlign:'right'}}>외근은 빨간색으로 표시 됩니다.</div>
            </CardBody>
            <CardFooter stats>
            </CardFooter>
        </Card>
        </GridItem>
    }

    date_to_str(date:Date) {
        return date.getFullYear() + '-'+String(date.getMonth()+1).padStart(2,"0")+'-'+String(date.getDate()).padStart(2,"0")
    }

    renderPersonalCalendar() {
        return <Modal open={this.state.personalCalendarTarget !== undefined} onClose={()=> this.setState({personalCalendarTarget:undefined, leave_query:[]})}>
            <div style={{margin:10}}>
                {this.state.personalCalendarTarget && <Card>
                    <CardHeader color="primary">
                        <h4 style={styles.cardTitleWhite as any}>근태달력</h4>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <div>연차 조회</div>
                            조회 시작일 : <input type="date" value={this.date_to_str(this.state.annual_std)} onChange={(ev)=> {
                                this.setState({annual_std:new Date(ev.target.value)})
                            }}/>
                            <button onClick={()=>{
                                Api.list<Schedule[]>('/api-schedule/', {
                                    profile:this.state.personalCalendarTarget.profile.id,
                                    start__gte: moment(this.state.annual_std).utc().format(),
                                    leave:true
                                }).then(res=> {
                                    this.setState({leave_query: res})
                                    console.log(res)
                                })
                            }}>조회</button>
                            합계 {this.state.leave_query.filter(item=> item.annual_leave).map(item=> {
                                const worktime = moment(item.end).diff(moment(item.start), 'minutes') - item.rest
                                if (worktime > 7*60) return 1
                                else return 0.5
                            }).reduce((a,b)=> a+b, 0)} 일
                            {this.state.leave_query.map(item=> <div style={{fontWeight: item.annual_leave ? 'bold' : 'lighter'}}>
                                {moment(item.start).format('yyyy-MM-DD')} {JSON.parse(item.reason)['요청']}
                            </div>)}
                        </div>
                        <div style={{textAlign:'center', fontSize:20, marginBottom:10}}>
                            {this.state.personalCalendarTarget.profile.name_ko} - 
                            월간 근무시간 : {Math.floor(this.state.personalCalendarTarget.sum_work_minutes/60)}시간
                            {Math.floor(this.state.personalCalendarTarget.sum_work_minutes%60)}분
                        </div>
                        <div css={css`
                            display:flex;
                            justify-content:center;
                            align-items:center;
                            margin:10px;
                        `}>
                            <div onClick={()=>this.changePersonalCalender(-1)}><Icon>keyboard_arrow_left</Icon></div>
                            <h4 css={css`margin:0px;margin-bottom:5px;`}>{this.state.personalCalendarTarget.year}년 {this.state.personalCalendarTarget.month+1}월</h4>
                            <div onClick={()=>this.changePersonalCalender(1)}><Icon>keyboard_arrow_right</Icon></div>
                        </div>
                        <div css={css`
                            flex-direction:row;
                            justify-content:space-between;
                            display:flex;
                            border:solid 1px #ccc;
                        `}>
                            {['일', '월', '화', '수', '목', '금', '토'].map((cell, j)=> <div key={j} css={css`
                                flex:1;
                                margin-top:5px;
                                margin-bottom:5px;
                                align-items:center;
                                text-align:center;
                                font-weight:bold;
                            `}>
                                <div css={css`color:${j===0?'red':j===6?'blue':'black'};`}>{cell}요일</div>
                            </div>)}
                        </div>
                        {this.state.personalCalendarTarget.calendar.map((row,i)=> <div key={i} css={css`
                            flex-direction:row;
                            justify-content:space-between;
                            display:flex;
                            padding:0px;
                            border-right:solid 1px #ccc;
                        `}>
                            {row.map((cell, j)=> <div key={j} css={css`
                                flex:1;
                                align-items:center;
                                border-left:solid 1px #ccc;
                                border-bottom:solid 1px #ccc;
                                padding:5px;
                                height:100px;
                                &:hover {
                                    background-color:#CCC;
                                    cursor:pointer;
                                }
                            `}>
                                <div css={css`
                                    font-weight:bold;
                                    text-align:right;
                                    color:${!cell.currentMonth? '#ccc':j===0?'red':j===6?'blue':'black'};`}>{cell.date}</div>
                                {cell.currentMonth && cell.items.map((item, i)=> <div key={i}>{item}</div>)}
                            </div>)}
                        </div>)}
                    </CardBody>
                    <CardFooter stats>
                    </CardFooter>
                </Card>}
            </div>
        </Modal>
    }

    render() {
        return <div>
            <Modal open={this.state.modalSchedule.length !== 0} onClose={()=> this.setState({modalSchedule:[]})}>
                <div css={css`
                    position:absolute;
                    top:50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                `}>
                    <Card>
                        <CardBody>
                            <h4>근태 상세 정보</h4>
                            {this.state.modalSchedule.map((item, i)=> {
                            let minutes = 0
                            if(item.end) {
                                minutes = moment(item.end).diff(moment(item.start), 'minutes') - item.rest
                            } else {
                                minutes = moment().diff(moment(item.start), 'minutes')
                                if (minutes > item.rest) {
                                    minutes -= item.rest
                                }
                            }
                            return <div key={i}>
                                <div>{moment(item.start).format('YYYY-MM-DD HH:mm')} - {item.end && moment(item.end).format('HH:mm')}</div>
                                <div>휴게시간 : {item.rest}분</div>
                                <div>근무시간 : {Math.floor(minutes/60)}시간 {minutes%60}분</div>
                                <div>
                                    {item.reason}
                                </div>
                                {item.request && <div style={{fontWeight:'bold'}}>근태 수정 요청중</div>}
                                {item.leave && <div style={{fontWeight:'bold'}}>휴가</div>}
                                <Button color="danger" onClick={async()=>{
                                    await Api.delete('/api-schedule/', item.id)
                                    this.componentDidMount()
                                }}>삭제</Button>
                                <Divider/>
                            </div>})}
                        </CardBody>
                    </Card>
                </div>
            </Modal>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                <CardHeader color="primary">
                    <h4 style={styles.cardTitleWhite}>근태요청</h4>
                    <p style={styles.cardCategoryWhite}></p>
                </CardHeader>
                <CardBody>
                    <Table
                    tableHeaderColor="primary"
                    tableHead={["요청", "요청 시간", "이름", "날짜", "휴게시간", "내용", "확인"]}
                    tableData={this.state.schedules.map(item=>[
                        item.leave ? AttendanceUtils.getLeaveType(item):'근태', 
                        moment(item.created).format('YYYY.MM.DD HH:mm'),
                        item.profile.name_en, 
                        moment(item.start).format('YYYY.MM.DD HH:mm') + ' - ' + moment(item.end).format('HH:mm'),
                        String(item.rest),
                        item.reason ? JSON.parse(item.reason)['요청'] : '',
                        <div>
                        <Button variant="contained" color="primary" onClick={async()=>{
                            const schedules = await Api.list<Schedule[]>('/api-schedule/', {
                                profile:U.getId(item.profile),
                                start__gte:moment(item.start).startOf('date').utc().format(),
                                start__lte:moment(item.start).endOf('date').utc().format()
                            })
                            schedules.forEach(async(s)=> {
                                if (s.id === item.id) {
                                    await Api.update('/api-schedule/', s.id, {
                                        request:false
                                    })
                                    
                                } else if(item.leave === s.leave) {
                                    await Api.delete('/api-schedule/', s.id)
                                }
                            })
                            this.setState({
                                schedules:this.state.schedules.filter(s=> s.id !== item.id)
                            })
                        }}>승인</Button>
                        <Button variant="contained" color="danger" onClick={async()=>{
                            this.setState({reject_schedule_id:item.id, reject_content:''})
                            // await Api.delete('/api-schedule/', item.id)
                            // this.setState({
                            //     schedules:this.state.schedules.filter(s=> s.id !== item.id)
                            // })
                        }}>반려</Button>
                        </div>
                    ])}
                    onClick={(r,c)=> {}}
                    />
                </CardBody>
                <CardFooter>
                    <div />
                    {/* {auth.profile && (auth.profile.user as custom.auth.User).is_staff && 
                    <Button color="primary" onClick={()=>{
                        const {history} = this.props
                        history.push('/admin/board/write/0')
                    }}>글쓰기</Button>} */}
                </CardFooter>
                </Card>
            </GridItem>
            {this.renderCalendar()}
            {this.renderPersonalCalendar()}
            </GridContainer>
            <Modal open={this.state.reject_schedule_id !== -1}>
                <div style={{background:'white', maxWidth:600, margin:'auto', padding:15, borderRadius:20, marginTop:20}}>
                    <ModalHeader>
                        근태 반려
                    </ModalHeader>
                    <ModalBody>
                        <CustomInput
                                labelText="반려 사유를 작성해주세요."
                                id="outside_off"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    multiline: true,
                                    rows: 5,
                                    value:this.state.reject_content,
                                    onChange:(e)=>this.setState({reject_content:e.target.value})
                                }}
                            />
                        <div style={{fontSize:8}}>* 반려 내용은 이메일로 전송됩니다.</div>
                    </ModalBody>
                    <ModalFooter style={{textAlign:'right'}}>
                        <Button onClick={()=> this.setState({reject_schedule_id:-1})}  disabled={this.state.rejecting}>취소</Button>
                        <Button color="danger" disabled={this.state.rejecting} onClick={async()=> {
                            this.setState({rejecting:true})
                            const schedule = this.state.schedules.find(s=> s.id === this.state.reject_schedule_id)
                            await Api.create('/mail/', {
                                title: (schedule.leave ? AttendanceUtils.getLeaveType(schedule):'근태') + '요청이 반려되었습니다.',
                                message: '날짜 : '+moment(schedule.start).format('YYYY.MM.DD HH:mm') + ' - ' + moment(schedule.end).format('HH:mm') +'\n반려 사유\n\n'+this.state.reject_content,
                                to: schedule.profile.email
                            })
                            await Api.delete('/api-schedule/', this.state.reject_schedule_id)
                            this.setState({
                                schedules:this.state.schedules.filter(s=> s.id !== this.state.reject_schedule_id),
                                reject_schedule_id:-1,
                                rejecting:false
                            })
                        }}>반려</Button>
                    </ModalFooter>
                </div>
            </Modal>
            <DownloadSummary />
        </div>
    }
}

const DAY = [
    '일요일',
    '월요일',
    '화요일',
    '수요일',
    '목요일',
    '금요일',
    '토요일'
]

function DownloadSummary() {
    const [month, setMonth] = useState(moment())
    const [running, setRunning] = useState(false)
    return <div>
        <h4>월별 종합</h4>
        <input type="month" value={month.format('YYYY-MM')} onChange={e=> {
            const [year, month] = e.target.value.split('-')
            setMonth(moment().year(Number(year)).month(Number(month)-1))
        }} />
        <Button color="primary" onClick={async()=>{
            setRunning(true)
            const holiday = await Api.list<{name:string, date:string}[]>('/api/holiday', {year:month.year(), month:month.month()+1})
            const wb = { Sheets: {}, SheetNames: [] };
            const schedules = await Api.list<Schedule<Profile>[]>('/api-schedule/', {
                start__gte:month.clone().startOf('month').utc().format(),
                start__lte:month.clone().endOf('month').utc().format(),
                order_by:'start'
            })
            await Api.expand(schedules, 'profile', Profile.API)
            const data_by_profile:{p:Profile,d:Schedule[]}[] = []
            schedules.forEach(item=> {
                let profile = data_by_profile.find(p=> p.p.id === item.profile.id)
                if (!profile) {
                    profile = {
                        p:item.profile,
                        d:[]
                    }
                    data_by_profile.push(profile)
                }
                profile.d.push({...item, profile:item.profile.id})
            })
            const summary = []
            data_by_profile.forEach(item=> {
                const name = `${item.p.name_en}_${item.p.name_ko}`
                const summary_item = {
                    name,
                    time:[]
                }
                let sum_of_time = 0
                const start = moment(item.d[0].start).startOf('month')
                const end = moment(item.d[0].start).endOf('month')
                const diff = end.diff(start, 'day') + 1
                const days = []
                for(let i=0;i<diff;i++) {
                    days.push(i+1)
                }

                const data = [
                    ['날짜', '출근', '퇴근', '근무시간', '휴게시간(분)', '비고'],
                ]
                let is_last
                days.forEach(day=> {
                    is_last = false
                    const date = start.clone().date(day)
                    const schedules = item.d.filter(d=>{
                        const start = moment(d.start)
                        return start.date() === day
                    })
                    let special = ''
                    holiday.forEach(h=> {
                        if(moment(h.date).date() === day) {
                            special = h.name
                        }
                    })
                    const output = [moment(date).format('YYYY-MM-DD') + ' ' + DAY[moment(date).day()]]
                    output.push(schedules.map(d=> moment(d.start).format('HH:mm')).join('\n'))
                    output.push(schedules.map(d=> moment(d.end).format('HH:mm')).join('\n'))
                    output.push(schedules.map(d=> {
                        const diff = moment(d.end).diff(moment(d.start), 'minutes') - d.rest
                        if (!isNaN(diff)) sum_of_time += diff
                        return toStr(diff)
                    }).join('\n'))
                    output.push(schedules.map(d=> d.rest).join('\n'))
                    output.push([special,...schedules.map(d=> d.reason)].join('\n'))
                    data.push(output)
                    if (moment(date).day() === 0) {
                        const summary = ['주간 합계','','', toStr(sum_of_time)]
                        data.push(summary)
                        summary_item.time.push(sum_of_time)
                        sum_of_time = 0
                        is_last = true
                    }
                })
                if (!is_last) {
                    const summary = ['주간 합계','','', toStr(sum_of_time)]
                    data.push(summary)
                    summary_item.time.push(sum_of_time)
                }
                const sheet = sheet_from_array_of_arrays(data)
                sheet['!cols'] = [{wch:15},{wch:15},{wch:15},{wch:15},{wch:15},{wch:30}]
                wb.Sheets[name] = sheet
                wb.SheetNames.push(name)
                summary.push(summary_item)
            })

            const data = [
                ['이름', '1주차', '2주차', '3주차', '4주차', '5주차', '합계'],
            ]
            console.log(summary)
            summary.forEach(item=> {
                data.push([
                    item.name, 
                    toStr(item.time[0]),
                    toStr(item.time[1]),
                    toStr(item.time[2]),
                    toStr(item.time[3]),
                    toStr(item.time[4]),
                    toStr(item.time.reduce((a,b)=> a+b,0))
                ])
            })
            const sheet = sheet_from_array_of_arrays(data)
            sheet['!cols'] = [{wch:15}, {wch:15}, {wch:15}, {wch:15}, {wch:15}, {wch:15}, {wch:15}]
            wb.Sheets['요약'] = sheet
            wb.SheetNames.unshift('요약')
            const wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'})
            saveAs(new Blob([s2ab(wbout)], {type:"application/octet-stream"}), `${month.format('YYYY-MM')} 근태 종합.xlsx`)
            setRunning(false)
        }} disabled={running}>{running ? '진행중...':'다운로드'}</Button>
    </div>
}

function toStr(minutes) {
    if(!minutes) return '--'
    return `${Math.floor(minutes/60)}시간 ${minutes%60}분`
}

export default connectWithoutDone(
    state=>({
        auth:state.auth
    }),
    dispatch=>({
    }),
    AttendanceManage
)

const styles:any = {
    cardCategoryWhite: {
    //   "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    //   },
    //   "& a,& a:hover,& a:focus": {
    //     color: "#FFFFFF"
    //   }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };