import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { auth } from 'types/custom.types';
import * as ApiType from '../types/api.types';
import { U } from './U';
// import TagManager from 'react-gtm-module';
// import FileDownload from 'js-file-download';
// declare var csrf_token:string;

export const domain = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://api.intranet.juice.co.kr';
const jwtRefreshExp = 3 * 60 * 60 * 1000; // 만료 3시간 전
const KEY_JWT_TOKEN = 'jwt_token';
export const KEY_PROFILE = 'profile';
export const tokenExpiredSubject = new BehaviorSubject<boolean>(false);

const setHeader = async() => {
    return new Promise((resolve:any)=> {
        if (typeof localStorage !== 'undefined') {
            // axios.defaults.headers.common['X-CSRFToken'] = csrf_token;
            const token = localStorage.getItem(KEY_JWT_TOKEN);
            if (token === null || token === undefined || token === 'undefined') {
                delete axios.defaults.headers.common['Authorization']
                resolve()
            } else {
                const state = checkTokenExpired(token)
                if(state === 'valid') {
                    axios.defaults.headers.common['Authorization'] = 'JWT '+token;
                    resolve()
                } else if(state === 'refresh') {
                    delete axios.defaults.headers.common['Authorization']
                    axios.post(domain+'/api/token/refresh', {
                        token: token
                    }).then((res:any)=> {
                        localStorage.setItem(KEY_JWT_TOKEN, res.data.token);
                        axios.defaults.headers.common['Authorization'] = 'JWT '+res.data.token;
                        resolve();
                    }).catch(()=> {
                        tokenExpiredSubject.next(true)
                        delete axios.defaults.headers.common['Authorization'];
                    })
                } else if (state === 'invalid') {
                    tokenExpiredSubject.next(true);
                    delete axios.defaults.headers.common['Authorization'];
                    resolve();
                }
            }
        } else {
            resolve()
        }
    })
}

const queryUrl = (url:string, query:any, id:number|undefined|string=undefined)=> {
    if (id) {
        if (url[url.length-1] !== '/') {
            url += '/';
        }
        url += id+'/';
    }
    if (Object.keys(query).length > 0) {
        url += '?';
        Object.keys(query).forEach((key, i)=> {
            if (i !== 0) {
                url += '&';
            }

            if (Array.isArray(query[key])) {
                query[key].forEach((value: any, i: number) => {
                    // console.log(i, query[key].length, value)
                    if ( i < (query[key].length - 1) ) {
                        url += key + '=' + value + '&'
                    } else {
                        url += key + '=' + value
                    }
                })
            } else {
                url += key + '=' + query[key]
            }
            
        })
    }
    return url;
}

const checkTokenExpired = (token:string) => {
    const payload = parseJwt(token);
    const expDate = new Date(payload.exp*1000);
    const refreshDate = new Date(payload.exp*1000 - jwtRefreshExp);
    const current = new Date();
    if (current < refreshDate) {
        return 'valid';
    } 
    if (current < expDate) {
        return 'refresh';
    }
    return 'invalid'
}

const parseJwt = (token:string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};


const minify_array_query = (query: any) => {
    if (query) {
      Object.keys(query).forEach((key) => {
        if (Array.isArray(query[key])) {
          query[key] = JSON.stringify(query[key]);
        }
      });
    }
    return query;
}
export class Api {
    static async list<T>(url:string, query:object, config?:any):Promise<T> {
        await setHeader();
        return axios.get(domain+queryUrl(url, minify_array_query(query)), config).then(res=>res.data)
    }
    static async create<T>(url:string, body:object):Promise<T> {
        await setHeader();
        return axios.post(domain+url, body).then(res=>res.data);
    }
    static async retrieve<T>(url:string, id:number|string, query:object):Promise<T> {
        await setHeader();
        return axios.get(domain+queryUrl(url, minify_array_query(query), id)).then(res=>res.data)
    }
    static async update<T>(url:string, id:number|string, body:object):Promise<T> {
        await setHeader();
        return axios.put(domain+queryUrl(url, {}, id), body).then(res=>res.data)
    }
    static async patch<T>(url:string, id:number|string, body:object):Promise<T> {
        await setHeader();
        return axios.patch(domain+queryUrl(url, {}, id), body).then(res=>res.data);
    }
    static async delete<T>(url:string, id:number|string):Promise<T> {
        await setHeader();
        return axios.delete(domain+queryUrl(url, {}, id)).then(res=>res.data);
    }
    static async signIn(jwt_token:string|undefined, profile:ApiType.Profile<auth.User>|undefined) {
        if (typeof localStorage !== 'undefined') {
            if (jwt_token !== undefined) {
                localStorage.setItem(KEY_JWT_TOKEN, jwt_token);
            }
            if (profile !== undefined) {
                // TagManager.dataLayer({
                //     dataLayer: {
                //         userId:String(userItem.id)
                //     }
                // })
                localStorage.setItem(KEY_PROFILE, JSON.stringify(profile));
            }
        }
    }
    static async signOut() {
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem(KEY_JWT_TOKEN);
            localStorage.removeItem(KEY_PROFILE);
        }
    }
    // static async expand(arr:Array<any>, key:string, url:string) {
    //     const ids = U.union([arr.filter(item=>item[key]).map((item:any)=>item[key])])
    //     if (ids.length > 0) {
    //         const res:Array<any> = await Api.list(url,{
    //             'pk__in[]':ids
    //         })
    //         arr.forEach((item:any)=> item[key]= res.filter(r=> r.id === item[key])[0])
    //     }
    // }
    static async expand(arr:Array<any>, key:string, url:string, mtm:boolean=false) {
        let ids 
        if (mtm) ids = U.union(arr.filter(item=>item[key]).map((item:any)=>item[key]))
        else ids = U.union([arr.filter(item=>item[key]).map((item:any)=>item[key])])
        if (ids.length > 0) {
            const res:Array<any> = await Api.list(url,{
                'pk__in[]':ids
            })
            if (mtm) arr.forEach((item:any)=> item[key] = item[key].map((id:number)=> res.filter(r=>r.id === id)[0]))
            else arr.forEach((item:any)=> item[key]= res.filter(r=> r.id === item[key])[0])
        }
    }
    static async upload(file:File, id:number, extra:string):Promise<{uploaded:boolean,url:string}> {
        await setHeader()
        const formData = new FormData()
        formData.append('upload', file)
        formData.append('extra', extra)
        return axios.post(domain+'/upload/'+String(id),formData,{
            headers:{
                'Content-Type': 'multipart/form-data'
            }
        }).then(res=>res.data)
    }
    // static async download(url:string, body:any, filename:string) {
    //     await setHeader()
    //     return axios.post(domain+url, body, {responseType:'arraybuffer'}).then(res=> {
    //         FileDownload(res.data, filename+'.xlsx')
    //     })
    // }
}