import { store } from "../../core/Store"

export type AlertState = {
    message:string
    color:string
    visible:boolean
}

const initState = {
    visible:false,
    message:'',
    color:''
}

export const AlertAction = {
    name:'Alert',
    show:async(message:string, color:string, visible:boolean=true)=> {
        return Promise.resolve({
            message, color, visible
        })
    }
}

store.injectReducer(AlertAction, initState)