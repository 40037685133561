import { G } from "core/G"
import { Api, KEY_PROFILE } from "../../core/Api"
import { getHandleActions } from "../../core/connection"
import { Profile } from "../../types/api.types"
import { auth } from "../../types/custom.types"

export type AuthState = {
    profile?: Profile<auth.User>
}

const initState:AuthState = {

}

export const AuthAction = {
    init: async()=> {
        const strdata = localStorage.getItem(KEY_PROFILE)
        if (strdata) {
            try {
                const user = await Api.list<auth.User>('/api-user/',{self:'true'})
                const profiles = await Api.list<Profile<auth.User>[]>('/api-profile/',{user:user.id})
                if (profiles.length === 0) {
                    return Promise.resolve({message:'비정상적인 접근 입니다. 관리자에게 문의하세요.'})
                }
                profiles[0].user = user
                Api.signIn(undefined, profiles[0])
                G.profile.next(profiles[0])
                return Promise.resolve({profile:profiles[0]})
            } catch(e) {
                console.log(e)
                Api.signOut()
            }
        }
        return Promise.resolve({})
    },
    signIn: async(username:string, password:string)=> {
        const res = await Api.list<{message:string}>('/api-user/', {
            active:'true',
            username
        })
        if (res.message === 'no user') {
            return Promise.resolve({message:'존재하지 않는 아이디 입니다.'})
        } else if (res.message === 'invalid') {
            return Promise.resolve({message:'아직 가입승인이 되지 않았습니다.'})
        }
        const jwt = await Api.create<{token:string}>('/api/token/auth/', {
            username, password
        }).catch(err=> ({token:null}))
        if (jwt.token === null) {
            return Promise.resolve({message:'올바르지 않은 비밀번호 입니다.'})
        }
        Api.signIn(jwt.token, undefined)
        const user = await Api.list<auth.User>('/api-user/',{self:'true'})
        const profiles = await Api.list<Profile<auth.User>[]>('/api-profile/',{user:user.id})
        if (profiles.length === 0) {
            return Promise.resolve({message:'비정상적인 접근 입니다. 관리자에게 문의하세요.'})
        }
        profiles[0].user = user
        Api.signIn(undefined, profiles[0])
        G.profile.next(profiles[0])
        return Promise.resolve({message:'success', profile:profiles[0]})
    },
    signUp: async(username:string, password:string, insider:boolean, param:any)=> {
        const res = await Api.create<{message:string}>('/api-user/', {
            username, password, 
            insider, ...param
        })
        if (res.message === 'exist user') {
            return Promise.resolve({message:'이미 사용중인 아이디입니다.'})
        } else if(res.message === 'success') {
            return Promise.resolve({message:'success'})
        }
    },
    signOut: async() => {
        Api.signOut()
        G.profile.next(undefined)
        return Promise.resolve({profile:undefined})
    },
    update: async(profile:Profile<auth.User>, body:any)=>{
        const newProfile = await Api.update<Profile<auth.User>>('/api-profile/',profile.id, body)
        newProfile.user = profile.user
        Api.signIn(undefined, newProfile)
        return Promise.resolve({profile:newProfile})
    }
}

export default getHandleActions(AuthAction, initState)