/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Api } from 'core/Api';
import { Base } from 'core/Base';
import { connectWithoutDone } from 'core/connection';
import { History } from 'history';
import { CardFooter } from 'reactstrap';
import { BoardItem } from 'types/api.types';
import Button from 'components/CustomButtons/Button';
import { AuthState } from 'screens/auth/Auth.action';
import * as custom from 'types/custom.types'
interface Props {
    history: History
    location: Location
    auth:AuthState
}

interface States {
    boardItem?:BoardItem
}

class BoardDetail extends Base<Props, States> {

    state:States = {
    }

    async componentDidMount() {
        const { location } = this.props
        const b = location.pathname.split('/')
        const id = b[b.length-1]
        const boardItem = await Api.retrieve<BoardItem>('/api-board/item/', id, {})
        this.setState({boardItem})
    }

    render() {
        const { auth } = this.props
        if(!this.state.boardItem) return <div/>
        return <div>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                <CardHeader color="primary">
                    <h4 style={styles.cardTitleWhite}>{this.state.boardItem.title}</h4>
                </CardHeader>
                <CardBody>
                    <div className="html-wrapper" dangerouslySetInnerHTML={{__html:this.state.boardItem.body}}></div>
                </CardBody>
                <CardFooter>
                    <div css={css`
                        text-align:center;
                    `}>
                        <Button color="success" onClick={()=>{
                            const {history} = this.props
                            history.push('/admin/dashboard')
                        }}>목록</Button>
                        {auth.profile && (auth.profile.user as custom.auth.User).is_staff && 
                        <Button color="success" onClick={()=>{
                            const {history} = this.props
                            history.push('/admin/board/write/'+this.state.boardItem.id)
                        }}>수정</Button>}
                    </div>
                </CardFooter>
                </Card>
            </GridItem>
            </GridContainer>
        </div>
    }
}

export default connectWithoutDone(
    state=>({
        auth:state.auth
    }),
    dispatch=>({
    }),
    BoardDetail
)


const styles:any = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };