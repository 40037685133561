import { Api } from 'core/Api'
import { NotoFontPack, ReactNativeSVGContext, ScorePartwiseV2 } from 'juice-avocado-web'
import React, { forwardRef, useEffect, useRef, useState } from 'react'

type ScoreInfo = {
    id:number
    artist: string
    code: string
    composition:string
    gender:string
    key:string
    lyrics:string
    organization:string
    tempo:string
    title_en:string
    title_ko:string
}

const SvgClass = {
    svg: forwardRef((props, ref: any) => <svg {...props} ref={ref} />),
    path: forwardRef((props, ref: any) => <path {...props} ref={ref} />),
    rect: forwardRef((props, ref: any) => <rect {...props} ref={ref} />),
    g: forwardRef((props, ref: any) => <g {...props} ref={ref} />),
}

export function DemoCoverMusic() {
    const [items, setItems] = useState<ScoreInfo[]>([])
    const [totalPage, setTotalPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [_, draw] = useState(0)

    const loadPage = (page)=> {
        Api.list<{total_pages:number, items:ScoreInfo[]}>('/api/score_info/score_info/', {
            page:page,
            item_count_per_page:50
        }).then(res=>{
            console.log(res)
            setItems(res.items)
            setTotalPage(res.total_pages)
            setCurrentPage(page)
        })
    }
    const ref = useRef({
        ctx: new ReactNativeSVGContext(NotoFontPack, SvgClass)
    }).current
    useEffect(()=>{
        loadPage(1)
    },[])
    return <>
        {ref.ctx.render()}
        {items.map(d=><div key={d.id} onClick={()=>{
            Api.retrieve('/api/score_info/score_info/', d.code, {}).then((json)=>{
                const sp = new ScorePartwiseV2(json)
                if (!('cler' in sp.part[0].measure[0].attributes)) {
                    sp.part[0].measure[0].attributes['clef'] = {
                        '@number':'1',
                        line:'2',
                        sign:'G'
                    }
                }
                console.log(sp)
                sp.draw(ref.ctx)
                draw(new Date().getTime())
            })
        }}>
            {d.code} : {d.artist} - {d.title_ko}
        </div>)}
        <div>
            <div>Page : <input value={currentPage} onChange={(e)=> setCurrentPage(Number(e.target.value))} /><button onClick={()=> {
                if (0 < currentPage && currentPage <= totalPage) {
                    loadPage(currentPage)
                }
            }}>이동</button></div>
            <div>Total Page : {totalPage}</div>
        </div>
    </>
}