import { tokenExpiredSubject } from 'core/Api';
import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Route, Switch, Redirect } from 'react-router-dom'
import { AdminLayout, Auth, Home } from 'Routes';
import Content from 'screens/earing/Content';
import ContentList from 'screens/earing/ContentList';
import { ScoreDemo } from 'screens/genie/ScoreDemo';
import { AVCDREPETITIONTEST } from "screens/genie/avocado-scorecomponent-repetition";
import { binding, connectWithoutDone } from "./core/connection";
import Alert from "./screens/alert/Alert";
import { AuthAction } from "./screens/auth/Auth.action";
interface Props {
  AuthAct: typeof AuthAction;
}

interface States {
  ready: boolean;
}

class App extends React.Component<Props, States> {
  state = {
    ready: false,
  };

  sub: any;

  componentDidMount() {
    const { AuthAct } = this.props;
    this.sub = tokenExpiredSubject.subscribe((val) => {
      if (val) {
        AuthAct.signOut();
      }
    });
    AuthAct.init().then(() => this.setState({ ready: true }));
  }

  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe();
  }

  render() {
    return (
      <HelmetProvider>
        <Helmet>
          <title>Juice</title>
        </Helmet>
        {this.state.ready && (
          <div style={{ flex: 1, height: "100%" }}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/auth" component={Auth} />
              <Route path="/admin" component={AdminLayout} />
              <Route path="/earing/=id" component={Content} />
              <Route path="/earing" component={ContentList} />
              <Route
                path="/genie/score_demo/:query?"
                component={AVCDREPETITIONTEST}
              />
              <Route path="*" component={() => <Redirect to="/" />} />
            </Switch>
          </div>
        )}
        <Alert />
      </HelmetProvider>
    );
  }
}

export default connectWithoutDone(
  state=>({}),
  dispatch=>({AuthAct:binding(AuthAction, dispatch)}),
  App
);
