/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import AttachMoney from "@material-ui/icons/AttachMoney";
import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";
import MusicNote from "@material-ui/icons/MusicNote";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
// import Icons from "views/Icons/Icons.js";
// import Maps from "views/Maps/Maps.js";
// import NotificationsPage from "views/Notifications/Notifications.js";
// import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
import Profile from "screens/views/profile/Profile";
import Humans from "screens/views/humans/Humans";
import Attendance from "screens/views/attendace/Attendance";
import AttendanceManage from "screens/views/attendace/AttendanceManage";
import Contents from "screens/views/contents/Contents";
// import Board from "screens/views/board/Board";
import BoardDetail from "screens/views/board/BoardDetail";
import DashboardPage from 'screens/views/dashboard/Dashboard'
import Payment from "screens/views/payment/Payment";
import BoardWrite from "screens/views/board/BoardWrite";
import ColoringScreen from "screens/views/coloring/Coloring";
import DemoList from "screens/views/demo/DemoList";
// core components/views for RTL layout

const dashboardRoutes = [
  {
    path:'/admin/profile',
    component: Profile
  },
  {
    path:'/admin/board/:id',
    component: BoardDetail
  },
  {
    path:'/admin/board/write/:id',
    component: BoardWrite
  },
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage
  },
  // {
  //   path: "/admin/board",
  //   name: "공지사항",
  //   icon: LibraryBooks,
  //   component: Board,
  // },
  {
    path: "/admin/user",
    name: "사원정보",
    icon: Person,
    component: Humans,
  },
  {
    path: "/admin/attendance",
    name: "근태관리",
    icon: "content_paste",
    component: Attendance,
  },
  {
    path: "/admin/m_attendance",
    name: "근태관리[관리자]",
    icon: "content_paste",
    component: AttendanceManage,
    is_staff:true
  },
  {
    path: "/admin/contents",
    name: "컨텐츠",
    icon: MusicNote,
    component: Contents,
    is_outsider:true
  },
  {
    path: "/admin/coloring",
    name: "컬러링변환",
    icon: MusicNote,
    component: ColoringScreen
  },
  {
    path: "/admin/payment",
    name: "비용처리",
    icon: AttachMoney,
    component: Payment,
  },
  {
    path: "/admin/demo",
    name: "데모",
    icon: BubbleChart,
    component: DemoList,
  },
  // {
  //   path: "/admin/maps",
  //   name: "Maps",
  //   icon: LocationOn,
  //   component: Maps,
  // },
  // {
  //   path: "/admin/notifications",
  //   name: "Notifications",
  //   icon: Notifications,
  //   component: NotificationsPage,
  // },
  // {
  //   path: "/admin/upgrade-to-pro",
  //   name: "Upgrade To PRO",
  //   icon: Unarchive,
  //   component: UpgradeToPro,
  // }
];

export default dashboardRoutes;
