import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './core/reportWebVitals';
import Root from './core/ClientRoot'
import './index.css';
import { Config, ConfigApi, ConfigPlatform } from 'juice-frontend-core';
import axios from 'axios';
import * as RNWeb from 'react-native-web';

new Config(ConfigPlatform.Web, RNWeb, process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://api.intranet.juice.co.kr', false)
  .setWebDependency(axios, null)
  .setSpecialApi(ConfigApi.AUTH_TOKEN_REFRESH, '/api/token/refresh')

ReactDOM.render(<Root />,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
