/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Icon } from '@material-ui/core';
import { Accessibility, AccessTime, ArrowUpward, DateRange, LocalOffer, Store, Update, Warning } from '@material-ui/icons';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Table from "components/Table/Table.js";
import Danger from 'components/Typography/Danger';
import { Api } from 'core/Api';
import { Base } from 'core/Base';
import { connectWithoutDone } from 'core/connection';
import { History } from 'history';
import ChartistGraph from 'react-chartist';
import { Profile } from 'types/api.types';
import { completedTasksChart, dailySalesChart, emailsSubscriptionChart } from 'variables/charts';

// import { bugs, website, server } from "variables/general.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Board from '../board/Board';

interface Props {
    history: History
}

interface States {
    profiles:Profile[]
    selecedProfile?:Profile
}

class Humans extends Base<Props, States> {

    state:States = {
        profiles:[]
    }

    sitemap = [
      ['주스 이메일', "http://mail.juice.co.kr"],
      ['오픈프로젝트', "http://openproject.juice.co.kr"],
      ['테스트보고서', "http://juice.co.kr:9998"],
      ['잔디', "https://juice.jandi.com/app"],
      ['구글캘린더', "https://calendar.google.com"],
      ['복지플랫폼', "https://welfare.korcham.net/main/index"]
    ]

    async componentDidMount() {
        const profiles = await Api.list<Profile[]>('/api-profile/', {})
        this.setState({profiles})
    }

    render() {
        return <div>
        {/* <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Icon>content_copy</Icon>
                </CardIcon>
                <p style={styles.cardCategory}>Used Space</p>
                <h3 style={styles.cardTitle as any}>
                  49/50 <small>GB</small>
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div style={styles.stats}>
                  <Danger>
                    <Warning />
                  </Danger>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    Get more space
                  </a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Store />
                </CardIcon>
                <p style={styles.cardCategory}>Revenue</p>
                <h3 style={styles.cardTitle as any}>$34,245</h3>
              </CardHeader>
              <CardFooter stats>
                <div style={styles.stats}>
                  <DateRange />
                  Last 24 Hours
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <Icon>info_outline</Icon>
                </CardIcon>
                <p style={styles.cardCategory}>Fixed Issues</p>
                <h3 style={styles.cardTitle as any}>75</h3>
              </CardHeader>
              <CardFooter stats>
                <div style={styles.stats}>
                  <LocalOffer />
                  Tracked from Github
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Accessibility />
                </CardIcon>
                <p style={styles.cardCategory}>Followers</p>
                <h3 style={styles.cardTitle as any}>+245</h3>
              </CardHeader>
              <CardFooter stats>
                <div style={styles.stats}>
                  <Update />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader color="success">
                <ChartistGraph
                  className="ct-chart"
                  data={dailySalesChart.data}
                  type="Line"
                  options={dailySalesChart.options}
                  listener={dailySalesChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 style={styles.cardTitle as any}>Daily Sales</h4>
                <p style={styles.cardCategory}>
                  <span style={styles.successText}>
                    <ArrowUpward style={styles.upArrowCardCategory} /> 55%
                  </span>{" "}
                  increase in today sales.
                </p>
              </CardBody>
              <CardFooter chart>
                <div style={styles.stats}>
                  <AccessTime /> updated 4 minutes ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader color="warning">
                <ChartistGraph
                  className="ct-chart"
                  data={emailsSubscriptionChart.data}
                  type="Bar"
                  options={emailsSubscriptionChart.options}
                  responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                  listener={emailsSubscriptionChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 style={styles.cardTitle as any}>Email Subscriptions</h4>
                <p style={styles.cardCategory}>Last Campaign Performance</p>
              </CardBody>
              <CardFooter chart>
                <div style={styles.stats}>
                  <AccessTime /> campaign sent 2 days ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart>
              <CardHeader color="danger">
                <ChartistGraph
                  className="ct-chart"
                  data={completedTasksChart.data}
                  type="Line"
                  options={completedTasksChart.options}
                  listener={completedTasksChart.animation}
                />
              </CardHeader>
              <CardBody>
                <h4 style={styles.cardTitle as any}>Completed Tasks</h4>
                <p style={styles.cardCategory}>Last Campaign Performance</p>
              </CardBody>
              <CardFooter chart>
                <div style={styles.stats}>
                  <AccessTime /> campaign sent 2 days ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer> */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            {/* <CustomTabs
              title="Tasks:"
              headerColor="primary"
              tabs={[
                {
                  tabName: "Bugs",
                  tabIcon: BugReport,
                  tabContent: (
                    <Tasks
                      checkedIndexes={[0, 3]}
                      tasksIndexes={[0, 1, 2, 3]}
                      tasks={bugs}
                    />
                  )
                },
                {
                  tabName: "Website",
                  tabIcon: Code,
                  tabContent: (
                    <Tasks
                      checkedIndexes={[0]}
                      tasksIndexes={[0, 1]}
                      tasks={website}
                    />
                  )
                },
                {
                  tabName: "Server",
                  tabIcon: Cloud,
                  tabContent: (
                    <Tasks
                      checkedIndexes={[1]}
                      tasksIndexes={[0, 1, 2]}
                      tasks={server}
                    />
                  )
                }
              ]}
            /> */}
            <Board />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="warning">
                <h4 style={styles.cardTitleWhite as any}>관련사이트 링크</h4>
                <p style={styles.cardCategoryWhite}>
                  사내 업무 및 복지 관련 사이트로 이동합니다.
                </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="warning"
                  tableData={this.sitemap.map(item=> [item[0]])}
                  onClick={(r,c)=> window.open(this.sitemap[r][1], '_blank')}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    }
}

export default connectWithoutDone(
    state=>({
    }),
    dispatch=>({
    }),
    Humans
)
