import { LinearProgress } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import { Api } from 'core/Api'
import React, { useEffect, useState } from 'react'
import { Subscription } from 'rxjs'
import { auth } from 'types/custom.types'
import { ColoringCtrl } from './Coloring.ctrl'
import { AlertMsg, DragArea, FileListItem, MetaExcel } from './Coloring.view'
import { ColoringSettlementScreen } from './settlement/ColoringSettlement'


function ColoringScreen() {
    const [meta, setMeta] = useState<File|undefined>(undefined)
    const [fileList, setFileList] = useState<File[]>([])
    const [warning, setWarning] = useState('')
    const [progress, setProgress] = useState(0)
    const [settlement, setSettlement] = useState(false)
    const ctrl = ColoringCtrl.get<ColoringCtrl>()
    useEffect(()=>{
        const sub:Subscription[] = []
        sub.push(ctrl.progress.subscribe(p=> setProgress(p)))
        sub.push(ctrl.error.subscribe(e=> setWarning(e)))
        Api.list<auth.User>('/api-user/', {self:true}).then(async(res)=> {
            if(res.groups.length > 0) {
                const group = await Api.list<auth.Group[]>('/api-group/',{'pk__in[]':res.groups})
                if(group.find(g=> g.name === '컬러링정산')) {
                    setSettlement(true)
                }
            }
        })
        

        return ()=>{
            sub.forEach(s=> s.unsubscribe())
            ctrl.destroy()
        }
    },[])
    return <div>
        <MetaExcel onUpload={(file)=> setMeta(file)} />
        <DragArea onDrop={(files)=>{
            const re = /\d+.wav/;
            for(let i=0;i<files.length;i++) {
                if (!files[i].name.match(re)) {
                    ctrl.error.next('올바르지 않은 파일명입니다. : ' + files[i].name)
                    return
                }
            }
            fileList.push(...files)
            setFileList([...fileList])
        }} />
        <AlertMsg message={warning} />
        <div style={{display:'flex', alignItems:'center'}}>
            <LinearProgress style={{display:'flex', flex:1, marginRight:15}} variant="determinate" value={progress} />
            <Button color="success" disabled={(progress > 0 && progress < 100) || meta === undefined || fileList.length === 0} onClick={()=>{
                ctrl.start(meta, fileList)
            }}>시작하기</Button>
        </div>
        {fileList.sort((a,b)=> a.name > b.name ? 1: -1).map((f,i)=> <FileListItem key={i} file={f} onDelete={()=>{
            fileList.splice(i, 1)
            setFileList([...fileList])
        }}/>)}
        {settlement && <ColoringSettlementScreen />}
    </div>
}

export default ColoringScreen