import { css, cx } from '@emotion/css'
import { Alert } from '@material-ui/lab'
import Button from 'components/CustomButtons/Button'
import React, { useEffect, useState } from 'react'

export function FileListItem({file, onDelete}:{file:File, onDelete:()=>void}) {
    const kb = file.size/1024
    const mb = kb/1024
    return <div className={css(`
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-between;
    `)}>
        <div>{file.name}</div>
        <div className={css(`
        display:flex;
        align-items:center;
        `)}>
            <div>{mb.toFixed(2)}MB</div>
            <Button onClick={onDelete} color={'danger'}>삭제</Button>
        </div>
    </div>
}

export function DragArea({onDrop}:{onDrop:(files:File[])=> void}) {
    const [dropable, setDropable] = useState(false)
    return <div>
        <div>컬러링 Wav파일(60초)</div>
        <div className={cx([css(`
            width:100%;
            height:50px;
            border: dashed 1px black;
        `), dropable ? css(`
            background: #CCC;
        `) : {}])} onDrop={(e)=>{
            setDropable(false)
            e.preventDefault()
            const files = []
            if(e.dataTransfer.items) {
                for(let i=0;i<e.dataTransfer.items.length;i++) {
                    if(e.dataTransfer.items[i].kind === 'file') {
                        const file = e.dataTransfer.items[i].getAsFile()
                        files.push(file)
                    }
                }
            } else {
                for(let i=0;i<e.dataTransfer.files.length;i++) {
                    files.push(e.dataTransfer.files[i])
                }
            }
            onDrop(files)
        }}
        onDragOver={e=>{
            setDropable(true)
            e.preventDefault()
        }}
        onDragLeave={e=>{
            setDropable(false)
        }}>
            <div style={{margin:15}}>
                변환할 파일을 여기에 드래그해주세요.
            </div>
        </div>
        <div>* 파일명은 "숫자.wav" 만 지원합니다.</div>
    </div>
}

export function AlertMsg({message}:{message:string}) {
    const [body, setBody] = useState('')
    useEffect(()=>{
        setBody(message)
    }, [message]) 
    return body === '' ? <div /> : <Alert color={'error'} onClick={()=>setBody('')}>{body}</Alert>
}

export function MetaExcel({onUpload}:{onUpload:(file:File)=> void}) {
    return <div className={css(`
        margin-bottom:15px;
    `)}>
        <div>메타이터 엑셀 파일</div>
        <input type={'file'} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e)=>onUpload(e.target.files[0])}/>
        <Button onClick={()=> {
            downloadURI('/assets/meta.xlsx', 'meta.xlsx')
        }}>포멧 다운로드</Button>
    </div>
}

export function downloadURI(uri, name) {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}