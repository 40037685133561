/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Base } from '../../core/Base';
import { binding, connectWithoutDone } from '../../core/connection';
import { AlertAction, AlertState } from './Alert.action';
import { Alert } from 'reactstrap';
import { AnimateOnChange } from 'react-animation'
interface Props {
    AlertAct:typeof AlertAction
    alert: AlertState
}

interface States {
    color:string
    message:string
    visible:boolean
}

class AlertComp extends Base<Props, States> {

    state = {
        color:'',
        message:'',
        visible:false
    }

    bind(props:Props, state:States) {
        const { alert } = props
        state.color = alert.color
        state.message = alert.message
        state.visible = alert.visible
        if (state.visible === true) {
            setTimeout(()=> {
                const {AlertAct} = this.props
                AlertAct.show('', '', false)
            }, 3000)
        }
    }

    render() {
        return <div css={css`
            position:absolute;
            right:10px;
            bottom:10px;
        `}>
            <AnimateOnChange>
                {this.state.visible && <Alert color={this.state.color}>
                    {this.state.message}
                </Alert>}
            </AnimateOnChange>
        </div>
    }
}

export default connectWithoutDone(
    state=>({
        alert:state.Alert
    }),
    dispatch=>({
        AlertAct:binding(AlertAction, dispatch)
    }),
    AlertComp
)