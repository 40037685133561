import Button from 'components/CustomButtons/Button'
import { binding, connectWithoutDone } from 'core/connection'
import { ArchType, AvocadoModule } from 'juice-avocado-web'
import React, { useEffect, useState } from 'react'
import AuthAction from 'screens/auth/Auth.action'
import { DemoCoverMusic } from './DemoCoverMusic'
import { DemoHarmony } from './DemoHarmony'
import { DemoMusicCut } from './DemoMusicCut'
import { DemoMusicTransformer } from './DemoMusicTransformer'

function DemoList() {
    const [target, setTarget] = useState('')
    useEffect(()=>{
        const AVModule = AvocadoModule.create();
        AVModule.setPlatform(ArchType.WEB, require('tone'));
    },[])
    return <>
        <Button color="success" onClick={()=>setTarget('화성학')}>화성학</Button>
        <Button color="success" onClick={()=>setTarget('음악변환')}>음악변환</Button>
        <Button color="success" onClick={()=>setTarget('커버뮤직')}>커버뮤직</Button>
        <Button color="success" onClick={()=>setTarget('뮤직컷')}>뮤직컷</Button>
        {target === '화성학' && <DemoHarmony />}
        {target === '음악변환' && <DemoMusicTransformer />}
        {target === '커버뮤직' && <DemoCoverMusic />}
        {target === '뮤직컷' && <DemoMusicCut />}
    </>
}

export default connectWithoutDone(
    state=>({
        auth:state.auth
    }),
    dispatch=>({
      AuthAct:binding(AuthAction, dispatch),
    }),
    DemoList
)