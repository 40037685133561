import { css } from "@emotion/css";
import React, { useEffect, useState } from "react";
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowRight from '@material-ui/icons/ArrowRight'
import { ColoringContent, ColoringSettlement, MusicAgency, MusicComposer, MusicItem } from "types/api.types";
import { Api } from "core/Api";
import moment from "moment";
import Button from 'components/CustomButtons/Button'

declare var XLSX:any;
declare var saveAs:any;

type Record = {
    'JuiceID':string,
    '전달번호':string,
    '크리에이터 명':string
    '곡명':string
    '곡코드':number,
    '구매 건수':number,
    '실 정산 금액':number,
    '콘텐츠 분류':string
    '판매 금액':number,
    content:ColoringContent<MusicItem<MusicComposer<MusicAgency>>>
}

async function updateRecord(raw_records):Promise<Record[]> {
    //@ts-ignore
    const codes = [...new Set(raw_records.map(d=> d.곡코드))]
    const contents = await Api.list<ColoringContent<MusicItem>[]>(ColoringContent.API, {
        'code__in[]':codes
    })
    await Api.expand(contents, 'source', MusicItem.API, true)
    const composer_ids = []
    contents.forEach(c=> {
        c.source.forEach(s=> {
            if (s.composer) composer_ids.push(s.composer)
        })
    })
    const composers = await Api.list<MusicComposer<MusicAgency>[]>(MusicComposer.API, {
        'pk__in[]':composer_ids
    })
    await Api.expand(composers, 'agency', MusicAgency.API)
    const extendsContents = contents.map(c=> ({
        ...c,
        source: c.source.map(s=> ({
            ...s,
            composer: composers.find(c=> c.id === s.composer)
        }))
    }))
    return raw_records.map(d=>({
        'JuiceID':d['JuiceID'],
        '전달번호':d['전달번호'],
        '크리에이터 명':d['크리에이터 명'],
        '곡명':d['곡명'],
        '곡코드':d['곡코드'],
        '구매 건수':d['구매 건수'],
        '실 정산 금액':d['실 정산 금액'],
        '콘텐츠 분류':d['콘텐츠 분류'],
        '판매 금액':d['판매 금액'],
        '정산 완료':d['정산 완료'],
        content: extendsContents.find(content=> d.곡코드 === content.code)
    }))
}

export function ColoringSettlementScreen() {
    const [row, setRow] = useState<Record[]>([])
    const [update, setUpdate] = useState(0)
    const [date, setDate] = useState<moment.Moment>(undefined)
    const [settlements, setSettlements] = useState<ColoringSettlement<ColoringContent>[]>([])
    return <>
        <div>월별 정산 보기</div>
        <SelectMonth update={update} onSelect={async(date)=>{
            const settlements = await Api.list<ColoringSettlement<ColoringContent>[]>(ColoringSettlement.API, {
                date__gte: date.clone().utc().format('YYYY-MM-DD[T]HH:mm:ss'),
                date__lte: date.clone().endOf('month').utc().format('YYYY-MM-DD[T]HH:mm:ss')
            })
            await Api.expand(settlements, 'content', ColoringContent.API)
            setRow(await updateRecord(settlements.map(d=> ({
                'JuiceID':d.content.source.join(','),
                '전달번호':d.content.no,
                '크리에이터 명':d.content.nickname,
                '곡명':d.content.title,
                '곡코드':d.content.code,
                '구매 건수':d.count,
                '실 정산 금액':d.real,
                '콘텐츠 분류':d.type,
                '판매 금액':d.amount,
                '정산 완료':d.done
            }))))
            setSettlements(settlements)
            setDate(date)
        }} />
        <ImportExcel row={row} onUpload={async(file)=> {
            setRow(await updateRecord(await readExcel(file)))
            setUpdate(new Date().getTime())
            setSettlements([])
        }}  />
        <GroupedList row={row} settlements={settlements} onUpdate={async(settlements)=>{
            setRow(await updateRecord(settlements.map(d=> ({
                '크리에이터 명':d.content.nickname,
                '곡명':d.content.title,
                '곡코드':d.content.code,
                '구매 건수':d.count,
                '실 정산 금액':d.real,
                '콘텐츠 분류':d.type,
                '판매 금액':d.amount,
                '정산 완료':d.done
            }))))
            setSettlements(settlements)
        }}/>
        <ExportExcel row={row} date={date}/>
    </>
}

export function s2ab(s) { 
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;    
}

function datenum(v, date1904?:any) {
	if(date1904) v+=1462;
	var epoch = Date.parse(v);
    // @ts-ignore
	return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}
export function sheet_from_array_of_arrays(data, opts?:any):any {
	var ws = {};
	var range = {s: {c:10000000, r:10000000}, e: {c:0, r:0 }};
	for(var R = 0; R != data.length; ++R) {
		for(var C = 0; C != data[R].length; ++C) {
			if(range.s.r > R) range.s.r = R;
			if(range.s.c > C) range.s.c = C;
			if(range.e.r < R) range.e.r = R;
			if(range.e.c < C) range.e.c = C;
			var cell:any = {v: data[R][C] };
			if(cell.v == null) continue;
			var cell_ref = XLSX.utils.encode_cell({c:C,r:R});
			if(typeof cell.v === 'number') cell.t = 'n';
			else if(typeof cell.v === 'boolean') cell.t = 'b';
			else if(cell.v instanceof Date) {
				cell.t = 'n'; cell.z = XLSX.SSF._table[14];
				cell.v = datenum(cell.v);
			}
			else cell.t = 's';
			ws[cell_ref] = cell;
		}
	}
	if(range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
	return ws;
}

function getTitleStyle(bg?:boolean) {
    return {
        fill:bg ?{
            fgColor:{ rgb: "ff6f48" }
        } : undefined,

        font: {
            bold:true,
        }, alignment: {
            horizontal:'center'
        }
    }
}

const CURRENCY = '#,###.#'

function ExportExcel({row, date}:{row:Record[], date?:moment.Moment}) {
    function getGroup() {
        const group = []
        row.forEach(row=> {
            if (group.indexOf(row["크리에이터 명"]) === -1) {
                group.push(row["크리에이터 명"])
            }
        })
        row.forEach(row=> {
            if (row.content) {
                row.content.source.forEach(music=>{
                    if(group.indexOf(music.composer.agency.name) === -1 && music.composer.agency.name !== 'Juice') {
                        group.push(music.composer.agency.name)
                    }
                })
            } else {
                alert(row.content.title + '곡의 곡코드와 컨텐츠 연결이 되어 있지 않습니다. 개발자에게 문의 바랍니다.')
                throw 'not matched code'
            }
        })
        return group
    }
    function getSummaryData() {
        const group = getGroup()
        return [
            [date?date.format('YYYY년 MM월 정산'):''],
            ['구분', '컬러링', '벨소리', '주스 수수료', '지급액'],
            ...group.map(g=> {
                // const g_row = row.filter(r=> r["크리에이터 명"] === g)
                return [g, 
                    // g_row.filter(r=> r["콘텐츠 분류"] === '컬러링').map(r=> r["구매 건수"]).reduce((a,b)=>a+b,0),
                    // g_row.filter(r=> r["콘텐츠 분류"] === '벨소리').map(r=> r["구매 건수"]).reduce((a,b)=>a+b,0), 
                    // g_row.map(r=> r['판매 금액']).reduce((a,b)=>a+b,0),
                    // g_row.map(r=> r['실 정산 금액']).reduce((a,b)=>a+b,0),
                    // 0,
                    0,0,0,0
                ]
            }),
            ['합계금액','','',0,0,0] 
                // row.map(r=> r['판매 금액']).reduce((a,b)=>a+b,0),
                // row.map(r=> r['실 정산 금액']).reduce((a,b)=>a+b,0)]
        ]
    }

    return <>
        <Button color={'success'} disabled={row.length === 0} onClick={()=>{
            const wb = { Sheets: {}, SheetNames: [] };
            const groups = getGroup()
            const data = getSummaryData()
            const sheet = sheet_from_array_of_arrays(data)
            sheet["!merges"] = [
                {s: {r:0, c:0}, e:{r:0, c:4}},
                {s: {r:data.length-1, c:0}, e:{r:data.length-1,c:2}}
            ]
            sheet.A1.s = getTitleStyle(true)
            sheet.A2.s = getTitleStyle()
            sheet.B2.s = getTitleStyle()
            sheet.C2.s = getTitleStyle()
            sheet.D2.s = getTitleStyle()
            sheet.E2.s = getTitleStyle()
            sheet[`A${groups.length+3}`].s = getTitleStyle()
            sheet['!cols'] = [{wch:15},{wch:10},{wch:10}, {wch:10}, {wch:10}]
            wb.Sheets['요약'] = sheet
            wb.SheetNames.push('요약')
            const refs = groups.map(groupName=> {
                const g_row = row.filter(r=>r["크리에이터 명"] === groupName && r.content.ment === '--') // 멘트 없는 케이스
                let m_row:Record[]
                if (groupName === 'JUICE') {
                    m_row = row.filter(r=> r['크리에이터 명'] === "JUICE" && r.content.ment !== '--') // 멘트 있는 케이스
                    const not_matched_case = row.filter(r=> r['크리에이터 명'] === "JUICE" && r.content.ment === '미분류') // 곡코드 연결 필요
                    if (not_matched_case.length > 0) {
                        alert(not_matched_case[0].content.title + '곡의 곡코드와 컨텐츠 연결이 되어 있지 않습니다. 개발자에게 문의 바랍니다.')
                        throw 'not matched code'
                    }
                } else {
                    m_row = row.filter(r=> r['크리에이터 명'] === "JUICE" && r.content.ment !== '--' && r.content.source.find(src=> src.composer.agency.name === groupName)) // 멘트 있는 케이스
                }
                
                const sheet = sheet_from_array_of_arrays([
                    [date?date.format('YYYY년 MM월 정산'):''],
                    ['','','구분', 'R/S'],
                    ['','','주스', 0.3],
                    ['','','Ment', 0.35],
                    ['','','BGM(Ment O)', 0.35],
                    ['','','BGM(Ment X)', 0.7],
                    [],
                    ['구분', 'Juice ID', '전달 번호', '곡코드', '곡명', '콘텐츠 분류', '구매 건수', '판매 금액', '정산 금액', 'Juice 수수료', '성우 지급액', 'BGM 지급액', '처리 결과'],
                    ...g_row.map(r=> [groupName, r.JuiceID, r.전달번호, r.곡코드, r.곡명, r["콘텐츠 분류"], r["구매 건수"], r["판매 금액"], r["실 정산 금액"], 0, 0, 0, r['정산 완료'] ? '정산완료' : '미지급']),
                    ['합계', '','','','','','','','', 0, 0,0],
                    [],
                    ['구분', 'Juice ID', '전달 번호', '곡코드', '곡명', '콘텐츠 분류', '구매 건수', '판매 금액', '정산 금액', 'Juice 수수료', '성우 지급액', 'BGM 지급액', '처리 결과', '원곡명', '작곡자', '성우'],
                    ...m_row.map(r=> ['JUICE', r.JuiceID, r.전달번호, r.곡코드, r.곡명, r["콘텐츠 분류"], r["구매 건수"], r["판매 금액"], r["실 정산 금액"], 0, 0, 0, r['정산 완료'] ? '정산완료' : '미지급', 
                        r.content.source.map(src=>src.title).join(','), r.content.source.map(src=>src.composer.name).join(','), r.content.ment]),
                    ['합계', '','','','','','','','', 0, 0, 0],
                ])
                sheet.A1.s = getTitleStyle(true)
                sheet.C2.s = getTitleStyle()
                sheet.D2.s = getTitleStyle()
                sheet.A8.s = getTitleStyle()
                sheet.B8.s = getTitleStyle()
                sheet.C8.s = getTitleStyle()
                sheet.D8.s = getTitleStyle()
                sheet.E8.s = getTitleStyle()
                sheet.F8.s = getTitleStyle()
                sheet.G8.s = getTitleStyle()
                sheet.H8.s = getTitleStyle()
                sheet.I8.s = getTitleStyle()
                sheet.J8.s = getTitleStyle()
                sheet.K8.s = getTitleStyle()
                sheet.L8.s = getTitleStyle()
                sheet.M8.s = getTitleStyle()
                sheet[`A${g_row.length+9}`].s = getTitleStyle()
                sheet[`B${g_row.length+11}`].s = getTitleStyle()
                sheet[`C${g_row.length+11}`].s = getTitleStyle()
                sheet[`D${g_row.length+11}`].s = getTitleStyle()
                sheet[`E${g_row.length+11}`].s = getTitleStyle()
                sheet[`F${g_row.length+11}`].s = getTitleStyle()
                sheet[`G${g_row.length+11}`].s = getTitleStyle()
                sheet[`H${g_row.length+11}`].s = getTitleStyle()
                sheet[`I${g_row.length+11}`].s = getTitleStyle()
                sheet[`J${g_row.length+11}`].s = getTitleStyle()
                sheet[`K${g_row.length+11}`].s = getTitleStyle()
                sheet[`L${g_row.length+11}`].s = getTitleStyle()
                sheet[`M${g_row.length+11}`].s = getTitleStyle()
                sheet[`N${g_row.length+11}`].s = getTitleStyle()
                sheet[`O${g_row.length+11}`].s = getTitleStyle()
                sheet[`P${g_row.length+11}`].s = getTitleStyle()
                sheet[`A${g_row.length+11+m_row.length+1}`].s = getTitleStyle()
                g_row.forEach((r, i)=> {
                    sheet[`H${i+9}`].z = CURRENCY
                    sheet[`I${i+9}`].z = CURRENCY
                    sheet[`J${i+9}`].f = `I${i+9}*D3`
                    sheet[`J${i+9}`].z = CURRENCY
                    sheet[`L${i+9}`].f = `I${i+9}*D6`
                    sheet[`L${i+9}`].z = CURRENCY
                })
                sheet[`J${g_row.length+9}`].f = g_row.length === 0 ? 0 : `SUM(J9:J${g_row.length+8})`
                sheet[`J${g_row.length+9}`].z = CURRENCY
                sheet[`L${g_row.length+9}`].f = g_row.length === 0 ? 0 : `SUM(L9:L${g_row.length+8})`
                sheet[`L${g_row.length+9}`].z = CURRENCY
                m_row.forEach((r,i)=>{
                    sheet[`H${i+g_row.length+12}`].z = CURRENCY
                    sheet[`I${i+g_row.length+12}`].z = CURRENCY
                    sheet[`J${i+g_row.length+12}`].z = CURRENCY
                    sheet[`K${i+g_row.length+12}`].z = CURRENCY
                    sheet[`L${i+g_row.length+12}`].z = CURRENCY
                    if(r.content.source.length === 0 ) {
                        sheet[`J${i+g_row.length+12}`].f = `I${i+g_row.length+12}*D3`
                        sheet[`K${i+g_row.length+12}`].f = `I${i+g_row.length+12}*(D4+D5)`
                        sheet[`L${i+g_row.length+12}`].f = `I${i+g_row.length+12}*0`
                    } else {
                        if(groupName === 'JUICE')
                            sheet[`J${i+g_row.length+12}`].f = `I${i+g_row.length+12}*0`
                        else
                            sheet[`J${i+g_row.length+12}`].f = `I${i+g_row.length+12}*D3`
                        sheet[`K${i+g_row.length+12}`].f = `I${i+g_row.length+12}*D4`
                        sheet[`L${i+g_row.length+12}`].f = `I${i+g_row.length+12}*D5`
                    }
                })
                sheet[`J${g_row.length+m_row.length+12}`].f = m_row.length === 0 ? 0 : `SUM(J${g_row.length+12}:J${g_row.length+m_row.length+11})`
                sheet[`J${g_row.length+m_row.length+12}`].z = CURRENCY
                sheet[`K${g_row.length+m_row.length+12}`].f = m_row.length === 0 ? 0 : `SUM(K${g_row.length+12}:K${g_row.length+m_row.length+11})`
                sheet[`K${g_row.length+m_row.length+12}`].z = CURRENCY
                sheet[`L${g_row.length+m_row.length+12}`].f = m_row.length === 0 ? 0 : `SUM(L${g_row.length+12}:L${g_row.length+m_row.length+11})`
                sheet[`L${g_row.length+m_row.length+12}`].z = CURRENCY
                sheet['!cols'] = [{wch:15},{wch:10},{wch:10},{wch:10},{wch:50}, {wch:10}, {wch:8}, {wch:10}, {wch:10},{wch:10},{wch:10},{wch:10},{wch:10},{wch:50},{wch:20}]
                sheet['!merges'] = [
                    {s:{r:0, c:0}, e:{r:0, c:12}},
                    {s:{r:g_row.length+8, c:0}, e:{r:g_row.length+8, c:8}},
                    {s:{r:g_row.length+m_row.length+11, c:0}, e:{r:g_row.length+m_row.length+11, c:8}},
                ]
                wb.Sheets[groupName] = sheet
                wb.SheetNames.push(groupName)
                if(groupName === 'JUICE') {
                    return {
                        group: groupName,
                        coloring:[...g_row,...m_row].filter(r=> r["콘텐츠 분류"] === '컬러링').map(r=> r["구매 건수"]).reduce((a,b)=>a+b,0),
                        bell:[...g_row,...m_row].filter(r=> r["콘텐츠 분류"] === '벨소리').map(r=> r["구매 건수"]).reduce((a,b)=>a+b,0),
                        fee: `JUICE!J${g_row.length+9}+JUICE!J${g_row.length+m_row.length+12}`,
                        price:`JUICE!L${g_row.length+9}+JUICE!K${g_row.length+m_row.length+12}`
                    }
                } else {
                    return {
                        group: groupName,
                        coloring:[...g_row,...m_row].filter(r=> r["콘텐츠 분류"] === '컬러링').map(r=> r["구매 건수"]).reduce((a,b)=>a+b,0),
                        bell:[...g_row,...m_row].filter(r=> r["콘텐츠 분류"] === '벨소리').map(r=> r["구매 건수"]).reduce((a,b)=>a+b,0),
                        fee: `'${groupName}'!J${g_row.length+9}+'${groupName}'!J${g_row.length+m_row.length+12}`,
                        price:`'${groupName}'!L${g_row.length+9}+'${groupName}'!L${g_row.length+m_row.length+12}`
                    }
                }
            })
            groups.forEach((name,i)=>{
                const ref = refs.find(ref=> ref.group === name)
                wb.Sheets['요약'][`B${i+3}`].v = ref.coloring
                wb.Sheets['요약'][`C${i+3}`].v = ref.bell
                wb.Sheets['요약'][`D${i+3}`].f = ref.fee
                wb.Sheets['요약'][`D${i+3}`].z = CURRENCY
                wb.Sheets['요약'][`E${i+3}`].f = ref.price
                wb.Sheets['요약'][`E${i+3}`].z = CURRENCY
            })
            wb.Sheets['요약'][`D${groups.length+3}`].f = `SUM(D3:D${groups.length+2})`
            wb.Sheets['요약'][`D${groups.length+3}`].z = CURRENCY
            wb.Sheets['요약'][`E${groups.length+3}`].f = `SUM(E3:E${groups.length+2})`
            wb.Sheets['요약'][`E${groups.length+3}`].z = CURRENCY
            wb.Sheets['요약'][`F${groups.length+3}`].f = `D${groups.length+3} + E${groups.length+3}`

            const wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'})
            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), 'JUICE_컬러링_정산.xlsx');
        }}>excel 다운로드</Button>
    </>
}

function SelectMonth({update, onSelect}:{update:number, onSelect:(date?:moment.Moment)=>void}) {
    const months = []
    const date = moment()
    while(date > moment().year(2021).month(2)) {
        months.push({'year': date.year(), 'month': date.month()})
        date.subtract(1, 'month')
    }
    const [month, setMonth] = useState('')
    useEffect(()=>{
        setMonth('')
    },[update])
    return <>
        <select value={month} onChange={(e)=> {
            setMonth(e.target.value)
            if (e.target.value) {
                const b = e.target.value.split('.')
                const year = Number(b[0])
                const month = Number(b[1])
                const date = moment().startOf('month').year(year).month(month)
                onSelect(date)
            } else {
                onSelect(undefined)
            }
        }}>
            <option value='' label="월 선택" />
            {months.map((m, i)=> <option key={i} value={m.year+'.'+m.month} label={m.year+'년 '+String(m.month+1).padStart(2, '0')+ '월'} />)}
        </select>
    </>
}

function GroupedList({row, settlements, onUpdate}:{row: Record[], settlements:ColoringSettlement<ColoringContent>[], onUpdate:(settlements:ColoringSettlement<ColoringContent>[])=>void}) {
    const groups = []
    row.forEach(r=> {
        if (groups.indexOf(r['크리에이터 명']) === -1){
            groups.push(r['크리에이터 명'])
        }
    })
    const [drops, setDrops] = useState([])
    const [updating, setUpdating] = useState(false)
    return <>
        {groups.sort((a,b)=> {
            if(a === 'JUICE') return -1
            else if (b === 'JUICE') return 1
            else return a-b
        }).map(g=> {
            const filteredSettles = settlements.filter(d=> d.content.nickname === g)
            return <div key={g}>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <div style={{cursor:'pointer'}}>
                    {drops.indexOf(g) === -1 ? 
                        <ArrowRight onClick={()=> setDrops(drops.concat(g))} /> : 
                        <ArrowDropDown onClick={()=> setDrops(drops.filter(d=> d!==g))} />}
                    </div>
                    <div style={{flex:1}}>
                        {g+` (${row.filter(r=> r['크리에이터 명'] === g).length})`} 
                        <Button size={'sm'} disabled={updating || filteredSettles.length === 0} onClick={async()=>{
                            setUpdating(true)
                            const nextDone = !filteredSettles[0].done
                            for(let i=0;i<filteredSettles.length;i++) {
                                const updatedOne = await Api.update<ColoringSettlement>(ColoringSettlement.API, filteredSettles[i].id, {
                                    done:nextDone
                                })
                                settlements.find(d=> d.id === updatedOne.id).done = updatedOne.done
                            }
                            onUpdate([...settlements])
                            setUpdating(false)
                        }}>{updating ? '정산 처리중...' : (filteredSettles.length === 0 || !filteredSettles[0].done ? '미정산' :'정산 완료')}</Button>
                    </div>
                    <div>
                        <span style={{fontWeight:'bold'}}>{'판매금 합계 : '}</span>
                    </div>
                    <div style={{width:100, textAlign:'right'}}>
                        {numberWithCommas(row.filter(r=> r['크리에이터 명'] === g).map(d=> d["판매 금액"]).reduce((a,b)=> a+b,0))}</div>
                    <div style={{marginLeft:15}}>
                        <span style={{fontWeight:'bold'}}>{'정산금 합계 : '}</span>
                    </div>
                    <div style={{width:100, textAlign:'right'}}>
                        {numberWithCommas(row.filter(r=> r['크리에이터 명'] === g).map(d=> d["실 정산 금액"]).reduce((a,b)=> a+b,0))}
                    </div>
                </div>
                {drops.indexOf(g) !== -1 && 
                (g === 'JUICE' ? <JuiceSettlement row={row.filter(r=> r['크리에이터 명'] === g)} /> :
                <DetailTable row={row.filter(r=> r['크리에이터 명'] === g).map(r=> ({record:r, source:1}))}/> )}
            </div>
        })}
        
        <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
            <div>
                <span style={{fontWeight:'bold'}}>{'총 판매금 합계 : '}</span>
            </div>
            <div style={{width:100, textAlign:'right'}}>
                {numberWithCommas(row.map(d=> d["판매 금액"]).reduce((a,b)=> a+b,0))}</div>
            <div style={{marginLeft:15}}>
                <span style={{fontWeight:'bold'}}>{'총 정산금 합계 : '}</span>
            </div>
            <div style={{width:100, textAlign:'right'}}>
                {numberWithCommas(row.map(d=> d["실 정산 금액"]).reduce((a,b)=> a+b,0))}
            </div>
        </div>
    </>
}

function DetailTable({row}:{row:{record:Record, source:number}[]}) {
    return <div style={{paddingLeft:10, fontSize:14, lineHeight:1}}>
        <div style={{display:'flex', flexDirection:'row', fontWeight:'bold'}}>
            <div style={{flex:1, textAlign:'center'}}>{`곡명`}</div>
            <div style={{width:40, textAlign:'center'}}>{'분류'}</div>
            <div style={{width:30, textAlign:'center'}}>{'건수'}</div>
            <div style={{width:60, textAlign:'center'}}>{'판매액'}</div>
            <div style={{width:60, textAlign:'center'}}>{'정산액'}</div>
        </div>
        {row.map((d, i)=> <div key={i} style={{display:'flex', flexDirection:'row'}}>
            <div style={{flex:1}}>{d.record['곡명']}</div>
            <div style={{width:40, textAlign:'right'}}>{d.record['콘텐츠 분류']}</div>
            <div style={{width:30, textAlign:'right'}}>{d.record['구매 건수']}</div>
            <div style={{width:60, textAlign:'right'}}>{numberWithCommas(d.record['판매 금액'] / d.source)}</div>
            <div style={{width:60, textAlign:'right'}}>{numberWithCommas(d.record['실 정산 금액'] / d.source)}</div>
        </div>)}
    </div>
}

function JuiceSettlement({row}:{row:Record[]}) {
    const [mentUser, setMentUser] = useState<string[]>([])
    const [agency, setAgency] = useState<MusicAgency[]>([])
    const [data, setData] = useState<{record:Record,content:ColoringContent<MusicItem<MusicComposer<MusicAgency>>>, source:number}[]>([])
    const [drops, setDrops] = useState([])
    useEffect(()=>{
        const codes = []
        row.forEach(d=> {
            if (codes.indexOf(d['곡코드']) === -1) {
                codes.push(d['곡코드'])
            }
        })
        Api.list<ColoringContent<MusicItem>[]>(ColoringContent.API, {
            'code__in[]':codes
        }).then(async(contents)=> {
            await Api.expand(contents, 'source', MusicItem.API, true)
            const composer_ids = []
            contents.forEach(c=> {
                c.source.forEach(s=> {
                    if (s.composer) composer_ids.push(s.composer)
                })
            })
            const composers = await Api.list<MusicComposer<MusicAgency>[]>(MusicComposer.API, {
                'pk__in[]':composer_ids
            })
            await Api.expand(composers, 'agency', MusicAgency.API)
            const extendsContents = contents.map(c=> ({
                ...c,
                source: c.source.map(s=> ({
                    ...s,
                    composer: composers.find(c=> c.id === s.composer)
                }))
            }))
            setData(row.map(d=> ({
                'record':d,
                'content': extendsContents.find(c=> c.code === d['곡코드']),
                'source':0 
            })))
            const ments = []
            extendsContents.forEach(c=> {
                if (ments.indexOf(c.ment) === -1) {
                    ments.push(c.ment)
                }
            })
            setMentUser(ments)
            const agency = []
            const agency_ids = []
            extendsContents.forEach(c=> {
                c.source.forEach(music=> {
                    if (agency_ids.indexOf(music.composer.agency.id) === -1) {
                        agency_ids.push(music.composer.agency.id)
                        agency.push(music.composer.agency)
                    }
                })
            })
            setAgency(agency)
        })
    },[])
    return <div style={{paddingLeft:10}}>
        <div style={{fontWeight:'bold', textAlign:"center"}}>멘트 분류</div>
        {mentUser.map(ment=> <div key={ment}>
            <DropDownHeader title={ment} data={data.filter(d=> d.content?.ment === ment).map(d=> ({record:d.record, source:1}))}/>
        </div>)}
        {data.filter(d=> d.content === undefined).length > 0 && <>
            <DropDownHeader title={'미분류'} data={data.filter(d=> d.content === undefined).map(d=> ({record:d.record, source:1}))}/>
        </>}

        <div style={{fontWeight:'bold', textAlign:"center"}}>BGM 분류</div>
        {agency.map(ag=> <div key={ag.id}>
            <DropDownHeader title={ag.name} data={filterByAgent(ag, data)}/>
        </div>)}
        {restByAgent(data).length > 0 && <>
            <DropDownHeader title={'미분류'} data={restByAgent(data)}/>
        </>}
    </div>
}

function getAgentKey(agent:MusicAgency) {
    return agent.id+':'+agent.name
}

function filterByAgent(agent:MusicAgency, data:{record:Record,content:ColoringContent<MusicItem<MusicComposer<MusicAgency>>>, source:number}[]) {
    const output:{record:Record,content:ColoringContent<MusicItem<MusicComposer<MusicAgency>>>, source:number}[] = []
    data.filter(d=> d.content !== undefined).forEach(d=> {
        for(let i=0;i<d.content.source.length;i++) {
            if (agent.id === d.content.source[i].composer.agency?.id) {
                d.source = d.content.source.length
                output.push(d)
                break
            }
        }
    })
    return output.map(d=> ({record:d.record, source:d.source}))
}
function restByAgent(data:{record:Record,content:ColoringContent<MusicItem<MusicComposer<MusicAgency>>>, source:number}[]) {
    return data.filter(d=> d.content === undefined || d.content.source.length === 0).map(d=> ({record:d.record, source:1}))
}

function DropDownHeader({title, data}:{title, data:{record:Record, source:number}[]}) {
    const [collapse, setCollapse] = useState(false)
    let sell = 0
    let real = 0
    data.forEach(d=> {
        sell += d.record["판매 금액"] / d.source
        real += d.record['실 정산 금액'] / d.source
    })
    return <>
        <div style={{display:'flex', flexDirection:'row'}}>
            <div style={{cursor:'pointer'}}>
                {!collapse ? 
                    <ArrowRight onClick={()=> setCollapse(true)} /> : 
                    <ArrowDropDown onClick={()=> setCollapse(false)} />}
            </div>
            <div style={{fontWeight:'bold', width:200}}>{title + ` (${data.map(d=> 1/d.source).reduce((a,b)=> a+b,0)})`}</div>
            <div style={{display:'flex', flexDirection:'row', marginRight:20}}>
                <div style={{fontWeight:'bold', fontSize:12}}>판매금 합계 : </div>
                <div style={{width:150, textAlign:'right'}}>{numberWithCommas(sell)}</div>
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
                <div style={{fontWeight:'bold', fontSize:12}}>정산금 합계 : </div>
                <div style={{width:150, textAlign:'right'}}>{numberWithCommas(real)}</div>
            </div>
        </div>
        {collapse && <DetailTable row={data} />}
    </>
}

async function readExcel(file) {
    return new Promise<Record[]>(resolve=> {
        let reader = new FileReader();
        reader.onload = function () {
            let data = reader.result;
            let workBook = XLSX.read(data, { type: 'binary' });
            let find = false
            workBook.SheetNames.forEach(function (sheetName) {
                if (sheetName === '데이터 상세') {
                    find = true
                    let rows = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
                    resolve(rows.filter(r=> r['곡명'] && r['곡명'] !== '곡명'))
                }
            })
            if (!find) {
                alert('"데이터 상세" 시트를 찾지 못했습니다.')
            }
        };
        reader.readAsBinaryString(file);
    })
}

function ImportExcel({row, onUpload}:{row: Record[], onUpload:(file:File)=> void}) {
    const [done, setDone] = useState(0) // 0: 전, 1 진행중, 2: 완료, -1: 실패
    const [date, setDate] = useState<moment.Moment>(undefined)
    return <div className={css(`
        margin-bottom:15px;
    `)}>
        <div>정산 엑셀 파일</div>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            <input type={'file'} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e)=>onUpload(e.target.files[0])}/>
            <div>
                <SelectMonth update={0} onSelect={(date)=>setDate(date)} />
                <Button disabled={done >0 || date === undefined || row.length == 0} onClick={async()=>{
                    setDone(1)
                    const merged:Record[] = []
                    row.forEach(item=> {
                        const one = merged.find(d=> d.곡코드 === item.곡코드 && d["콘텐츠 분류"] === item["콘텐츠 분류"])
                        if(one) {
                            one["구매 건수"] += item["구매 건수"]
                            one['실 정산 금액'] += item['실 정산 금액']
                            one['판매 금액'] += item['판매 금액']
                        } else {
                            merged.push({
                                'JuiceID':item['JuiceID'],
                                '전달번호':item['전달번호'],
                                '크리에이터 명':item["크리에이터 명"],
                                '곡명':item.곡명,
                                '곡코드':item.곡코드,
                                '구매 건수':item["구매 건수"],
                                '실 정산 금액':item["실 정산 금액"],
                                '콘텐츠 분류':item["콘텐츠 분류"],
                                '판매 금액':item["판매 금액"],
                                content: item.content
                            })
                        }
                    })
                    const contents = await Api.list<ColoringContent[]>(ColoringContent.API, {
                        'code__in[]': merged.map(d=> d.곡코드)
                    })
                    for(let i=0;i<merged.length;i++) {
                        const content = contents.find(d=> d.code ===merged[i].곡코드)
                        if (!content) {
                            console.log(merged[i], 'is not mathched so cancel')
                            setDone(-1)
                            return 
                        }
                    }

                    const timestamp = date.utc().format('YYYY-MM-DD[T]HH:mm:ss')
                    for(let i=0;i<merged.length;i++) {
                        const content = contents.find(d=> d.code ===merged[i].곡코드)!
                        await Api.create<ColoringSettlement>(ColoringSettlement.API, {
                            date: timestamp,
                            type:merged[i]["콘텐츠 분류"],
                            amount:merged[i]["판매 금액"],
                            real:merged[i]["실 정산 금액"],
                            count:merged[i]['구매 건수'],
                            content:content.id
                        })
                    }
                    setDone(2)
                }}>업로드 {['실패','하기','중...', '완료'][done+1]}</Button>  
            </div>
        </div>      
    </div>
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}