/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Table from "components/Table/Table.js";
import { Api } from 'core/Api';
import { Base } from 'core/Base';
import { connectWithoutDone } from 'core/connection';
import { History } from 'history';
import { BoardItem } from 'types/api.types';
import Button from 'components/CustomButtons/Button';
import * as custom from 'types/custom.types'
import { AuthState } from 'screens/auth/Auth.action';
interface Props {
    history: History
    auth:AuthState
}

interface States {
    boardItems:BoardItem[]
}

class Board extends Base<Props, States> {

    state:States = {
        boardItems:[]
    }

    async componentDidMount() {
        const boardItems = await Api.list<BoardItem[]>('/api-board/item/',{
            valid:true
        })
        this.setState({boardItems})
    }

    render() {
        const { auth } = this.props
        return <div>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                <CardHeader color="primary">
                    <h4 style={styles.cardTitleWhite}>공지사항</h4>
                    <p style={styles.cardCategoryWhite}>
                        필독 사항들 입니다.
                    </p>
                </CardHeader>
                <CardBody>
                    <Table
                    tableHeaderColor="primary"
                    // tableHead={["제목"]}
                    tableData={this.state.boardItems.map(item=> [item.title])}
                    onClick={(r,c)=> {
                        const item = this.state.boardItems[r]
                        const {history} = this.props
                        history.push('/admin/board/'+item.id)
                    }}
                    />
                </CardBody>
                <CardFooter>
                    <div />
                    {auth.profile && (auth.profile.user as custom.auth.User).is_staff && 
                    <Button color="primary" onClick={()=>{
                        const {history} = this.props
                        history.push('/admin/board/write/0')
                    }}>글쓰기</Button>}
                </CardFooter>
                </Card>
            </GridItem>
            </GridContainer>
        </div>
    }
}

export default connectWithoutDone(
    state=>({
        auth:state.auth
    }),
    dispatch=>({
    }),
    Board
)


const styles:any = {
    cardCategoryWhite: {
    //   "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    //   },
    //   "& a,& a:hover,& a:focus": {
    //     color: "#FFFFFF"
    //   }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };