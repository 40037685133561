export class Model {
  static API: string;
}
export interface ModelConstructor<T extends Model> {
  API: string;
  new (): T;
}

export class BoardItem<Profile=number,BoardItem=number> extends Model {
    static readonly API = '/api-board/item/'
    id!:number
    title!:string
    author!:Profile // Profile
    body?:string
    created!:string
    parent?:BoardItem // BoardItem
    valid!:boolean
}
export class ColoringContent<MusicItem=number> extends Model {
    static readonly API = '/api-coloring/content/'
    id!:number
    no!:number
    title!:string
    nickname!:string
    genre!:string
    mood!:string
    ment!:string
    source!:MusicItem[] // MusicItem[]
    code!:number
}
export class ColoringSettlement<ColoringContent=number> extends Model {
    static readonly API = '/api-coloring/settlement/'
    id!:number
    date?:string
    type!:string
    created!:string
    done!:boolean
    amount!:number
    real!:number
    count!:number
    content!:ColoringContent // ColoringContent
}
export class Media<Profile=number,Payment=number,BoardItem=number,MusicCover=number> extends Model {
    static readonly API = '/api-media/'
    id!:number
    file!:string
    extra!:string
    profile?:Profile // Profile
    payment?:Payment // Payment
    board_item?:BoardItem // BoardItem
    music_cover?:MusicCover // MusicCover
}
export class MusicCover extends Model {
    static readonly API = '/api-music/cover/'
    id!:number
    filename!:string
}
export class Payment<Profile=number,Profile2=number> extends Model {
    static readonly API = '/api-payment/'
    id!:number
    title!:string
    author!:Profile // Profile
    body?:string
    updated!:string
    status!:string
    price!:number
    approver?:Profile2 // Profile
    type?:string
}
export class Profile<authUser=number> extends Model {
    static readonly API = '/api-profile/'
    id!:number
    user!:authUser // custom.auth.User
    company!:string
    name_ko!:string
    name_en?:string
    tel!:string
    email!:string
    gmail?:string
    profile_img?:string
    intro?:string
    team?:string
    role?:string
    mbti?:string
    disc?:string
}
export class Schedule<Profile=number> extends Model {
    static readonly API = '/api-schedule/'
    id!:number
    start?:string
    end?:string
    reason?:string
    leave!:boolean
    request!:boolean
    rest!:number
    annual_leave!:boolean
    created!:string
    profile!:Profile // Profile
}
export class Score extends Model {
    static readonly API = '/api-score/'
    id!:number
    songId!:string
    sheetID!:string
    sheetType!:string
    jsonFilePath!:string
    pdfFilePath!:string
    instruments!:string
    totalPage!:number
    timeSig!:string
    keySig!:string
    transKeySig!:string
    scorePartNumber!:string
    tempo!:string
    genre!:string
    tags!:string
    langCode!:string
}
export class GaUserItem extends Model {
    static readonly API = '/api-ga/user/item/'
    id!:number
    user_id?:string
    promo_type?:string
}
export class HarmonyScoring extends Model {
    static readonly API = '/api-harmony/scoring/'
    id!:number
    code!:number
    name!:string
}
export class I18nItem extends Model {
    static readonly API = '/api-item/'
    id!:number
    service!:string
    repo!:string
    data?:string
}
export class MemberItem<authUser=number> extends Model {
    static readonly API = '/api-member/item/'
    id!:number
    user!:authUser // custom.auth.User
    ko_name?:string
    en_name?:string
    tel?:string
    position?:string
    intro?:string
    profile_img?:string
}
export class MusicAgency extends Model {
    static readonly API = '/api-music/agency/'
    id!:number
    name!:string
}
export class MusicComposer<MusicAgency=number> extends Model {
    static readonly API = '/api-music/composer/'
    id!:number
    name!:string
    alias!:string
    agency?:MusicAgency // MusicAgency
}
export class MusicGenre extends Model {
    static readonly API = '/api-music/genre/'
    id!:number
    name!:string
    category!:string
    division!:string
}
export class MusicGroup<authUser=number,MusicItem=number> extends Model {
    static readonly API = '/api-music/group/'
    id!:number
    name!:string
    published!:boolean
    owner!:authUser // custom.auth.User
    header!:string
    items!:MusicItem[] // MusicItem
}
export class MusicItem<MusicComposer=number,MusicTag=number,MusicGenre=number,MusicSinger=number,MusicCover=number> extends Model {
    static readonly API = '/api-music/item/'
    id!:number
    filename!:string
    title!:string
    player!:string
    lyricist!:string
    time!:string
    midi!:string
    tonality!:string
    tempo!:string
    wav!:boolean
    timeSig!:string
    playTime!:number
    isValid!:boolean
    ignoreColoring!:boolean
    license!:string
    composer?:MusicComposer // MusicComposer
    tag!:MusicTag[] // MusicTag
    genre?:MusicGenre // MusicGenre
    singer!:MusicSinger[] // MusicSinger
    cover?:MusicCover // MusicCover
}
export class MusicSinger<MusicAgency=number> extends Model {
    static readonly API = '/api-music/singer/'
    id!:number
    name!:string
    agency?:MusicAgency // MusicAgency
}
export class MusicTag extends Model {
    static readonly API = '/api-music/tag/'
    id!:number
    name!:string
}
export class OgqTask<MusicItem=number> extends Model {
    static readonly API = '/api-ogq/task/'
    id!:number
    created!:string
    is_valid!:boolean
    type!:string
    musicitem!:MusicItem[] // MusicItem
}
export class SettlementContent<SettlementProvider=number,MusicItem=number> extends Model {
    static readonly API = '/api-settlement/content/'
    id!:number
    created!:string
    ogq_id?:string
    name?:string
    cover?:string
    category?:string
    status!:string
    provider?:SettlementProvider // SettlementProvider
    music?:MusicItem // MusicItem
}
export class SettlementContract<SettlementProvider=number> extends Model {
    static readonly API = '/api-settlement/contract/'
    id!:number
    name!:string
    start!:string
    end!:string
    created!:string
    state!:string
    auto_extension!:boolean
    commission!:number
    b2b_commission!:number
    provider?:SettlementProvider // SettlementProvider
}
export class SettlementNotice extends Model {
    static readonly API = '/api-settlement/notice/'
    id!:number
    title!:string
    created!:string
    show!:boolean
    body?:string
    type!:string
}
export class SettlementOgqSell<SettlementContent=number,SettlementSales=number> extends Model {
    static readonly API = '/api-settlement/ogq/sell/'
    id!:number
    created!:string
    buyer!:string
    sell_amount!:number
    refund_amount!:number
    way?:string
    content?:SettlementContent // SettlementContent
    sales?:SettlementSales // SettlementSales
}
export class SettlementProvider<authUser=number> extends Model {
    static readonly API = '/api-settlement/provider/'
    id!:number
    name!:string
    user?:authUser // custom.auth.User
    account?:string
    account_name?:string
    nickname?:string
    desc?:string
    master?:string
    email?:string
    tel?:string
    registraion_number?:string
}
export class SettlementSales<SettlementProvider=number> extends Model {
    static readonly API = '/api-settlement/sales/'
    id!:number
    month!:string
    payed!:boolean
    provider?:SettlementProvider // SettlementProvider
}
