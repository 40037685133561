/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Base } from '../../core/Base';
import { binding, connectWithoutDone } from '../../core/connection';
import { History } from 'history';
import { AuthAction, AuthState } from './Auth.action';
import { AlertAction } from '../alert/Alert.action';
interface Props {
    history: History
    AuthAct: typeof AuthAction
    auth: AuthState
    AlertAct: typeof AlertAction
}

interface States {
    username:string
    password:string
}

class AuthSignIn extends Base<Props, States> {

    state = {
        username:'',
        password:''
    }

    async signIn() {
        const { AuthAct, AlertAct } = this.props
        const res = await AuthAct.signIn(this.state.username, this.state.password)
        if (res.message !== 'success') {
            AlertAct.show(res.message, 'danger')
            return
        }
        
    }

    signUp() {
        const {history} = this.props
        history.push('/auth/signup')
    }

    render() {
        return <div css={css`
            padding: 0px 10px;
            @media screen and (min-width: 768px){
                margin-left: 40%;
            }
        `}>
            <div className="col-md-6 col-sm-12">
                <div css={css`
                    @media screen and (max-width: 450px) {
                        margin-top: 10%;
                    }
                    @media screen and (min-width: 768px){
                        margin-top: 40%;
                    }
                `}>
                    <div className="form-group">
                        <label>User Name</label>
                        <input type="text" className="form-control" placeholder="Username"
                            value={this.state.username}
                            onChange={e=> this.setState({username:e.target.value})}/>
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" placeholder="Password"
                            value={this.state.password}
                            onChange={e=> this.setState({password:e.target.value})}
                            onKeyPress={(e:any)=> e.code === 'Enter' && this.signIn()}/>
                    </div>
                    <button type="submit" className="btn" css={css`
                        background-color: #000 !important;
                        color: #fff;
                        margin-right:3px;
                    `} onClick={()=> this.signIn()}>Login</button>
                    <button type="submit" className="btn btn-secondary"
                        onClick={()=> this.signUp()}>Register</button>
                </div>
            </div>
        </div>
    }
}

export default connectWithoutDone(
    state=>({
        auth:state.auth
    }),
    dispatch=>({
        AuthAct:binding(AuthAction, dispatch),
        AlertAct:binding(AlertAction, dispatch)
    }),
    AuthSignIn
)