import { G } from "core/G";
import { Controller } from "juice-frontend-core";
import { BehaviorSubject } from "rxjs";
import { downloadURI } from "./Coloring.view";

export class ColoringCtrl extends Controller {
    progress = new BehaviorSubject(0)
    error = new BehaviorSubject('')
    start(meta:File, contents:File[]) {
        let index = -1
        let url = process.env.NODE_ENV === 'development' ? 
        'ws://localhost:8000/ws/coloring/transform/' :
        'wss://api.intranet.juice.co.kr/ws/coloring/transform/'
        const socket = new WebSocket(url)
        const orderedContents = contents.sort((a,b)=> a.name > b.name ? 1: -1)
        let uploadCount = 0
        socket.onopen = async() => {
            socket.send(JSON.stringify({
                'action':'upload',
                'user':G.profile.value.id,
                'contents':orderedContents.map(d=> d.name)
            }))
        }
        socket.onmessage = async(e) => {
            if (typeof(e.data) === 'string') {
                const data = JSON.parse(e.data)
                if(data.result === 'success') {
                    if (index === -1) {
                        socket.send(await meta.arrayBuffer())
                        index += 1
                    } else if (orderedContents.length > index) {
                        socket.send(await orderedContents[index].arrayBuffer())
                        index += 1
                        uploadCount += 1
                        this.progress.next((uploadCount/orderedContents.length)*100 * 0.3)
                    } else {
                        socket.send(JSON.stringify({
                            'action':'transform'
                        }))
                    }
                } else if (data.result === 'progress') {
                    this.progress.next(data.value)
                } else {
                    this.error.next(data.message)
                    this.progress.next(0)
                    socket.close()
                }
            } else {
                this.progress.next(100)
                const url = window.URL.createObjectURL(e.data);
                downloadURI(url, 'coloring.zip')
                socket.close()
            }
        }
        socket.onclose = () => {
            console.log('onclose')
        }
    }
}