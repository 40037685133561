/** @jsxRuntime classic */
/** @jsx jsx */
import { css,jsx } from '@emotion/react'
import InputLabel from '@material-ui/core/InputLabel';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CardHeader from 'components/Card/CardHeader';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button.js";
import { Base } from 'core/Base';
import { binding, connectWithoutDone } from 'core/connection';
import { History } from 'history';
import CardAvatar from 'components/Card/CardAvatar';
import { CSSProperties } from 'react';
import { AuthAction, AuthState } from 'screens/auth/Auth.action';
import * as ApiType from 'types/api.types'
import { U } from 'core/U';
import { Api } from 'core/Api';
import Modal from '@material-ui/core/Modal';
import { auth } from 'types/custom.types';
interface Props {
    auth:AuthState
    AuthAct:typeof AuthAction
    history: History
}

interface States {
    gmail:string
    name_ko:string
    name_en:string
    tel:string
    intro:string
    team:string
    role:string
    profile?:ApiType.Profile<auth.User>
    canUpdate:boolean
    mbti:string
    disc:string
    showModal:boolean
    modal_pass:string
    modal_pass_1:string
    modal_pass_2:string
    modal_message:string
}

class Profile extends Base<Props, States> {

    state:States = {
        gmail:'',
        name_ko:'',
        name_en:'',
        tel:'',
        intro:'',
        team:'',
        role:'',
        canUpdate:false,
        mbti:'',
        disc:'',
        showModal:false,
        modal_pass:'',
        modal_pass_1:'',
        modal_pass_2:'',
        modal_message:''
    }
    profile_file_ref

    componentDidMount() {
        const {auth} = this.props
        if (auth.profile) {
          this.setState({
            gmail: auth.profile.gmail?auth.profile.gmail:'' ,
            name_ko: auth.profile.name_ko?auth.profile.name_ko:'',
            name_en: auth.profile.name_en?auth.profile.name_en:'',
            tel: auth.profile.tel?auth.profile.tel:'',
            intro: auth.profile.intro?auth.profile.intro:'',
            role: auth.profile.role?auth.profile.role:'',
            team: auth.profile.team?auth.profile.team:'',
            mbti: auth.profile.mbti?auth.profile.mbti:'',
            disc: auth.profile.disc?auth.profile.disc:''
          })
        }
    }

    updateProfile() {
      const {AuthAct, auth} = this.props
      if (auth.profile) {
        try {
          AuthAct.update(auth.profile, {
            gmail: this.state.gmail,
            name_ko: this.state.name_ko,
            name_en: this.state.name_en,
            tel: this.state.tel,
            intro: this.state.intro,
            role: this.state.role,
            team: this.state.team,
            mbti: this.state.mbti,
            disc: this.state.disc
          })
          this.setState({canUpdate:false})
        } catch {
          alert('빈칸을 모두 입력해주세요.')
        }
      }
    }

    changePassword() {
      this.setState({showModal:true, modal_message:'', modal_pass:'', modal_pass_1:'', modal_pass_2:''})
    }

    bind(props:Props, state:States) {
      const {auth} = props
      state.profile = auth.profile
    }

    render() {
        if (!this.state.profile) return <div/>
        return <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 style={styles.cardTitleWhite}>Edit Profile</h4>
                <p style={styles.cardCategoryWhite}>Complete your profile</p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomInput
                      labelText="Company"
                      id="company-disabled"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.profile.company
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="한글이름"
                      id="name_ko"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:this.state.name_ko,
                        onChange:(e)=>this.setState({name_ko:e.target.value, canUpdate:true})
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="영어이름"
                      id="name_en"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:this.state.name_en,
                        onChange:(e)=>this.setState({name_en:e.target.value, canUpdate:true})
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="email"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled:true,
                        value:this.state.profile.email,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="gmail"
                      id="gmail"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:this.state.gmail,
                        onChange:(e)=>this.setState({gmail:e.target.value, canUpdate:true})
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="연락처"
                      id="tel"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:this.state.tel,
                        onChange:(e)=>this.setState({tel:e.target.value, canUpdate:true})
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="소속"
                      id="team"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:this.state.team,
                        onChange:(e)=>this.setState({team:e.target.value, canUpdate:true})
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="역활"
                      id="role"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:this.state.role,
                        onChange:(e)=>this.setState({role:e.target.value, canUpdate:true})
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="MBTI 성격 유형"
                      id="mbti"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:this.state.mbti,
                        onChange:(e)=>this.setState({mbti:e.target.value, canUpdate:true})
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button style={{marginTop:30}} color="primary" 
                      onClick={()=>window.open('https://www.16personalities.com/ko/%EB%AC%B4%EB%A3%8C-%EC%84%B1%EA%B2%A9-%EC%9C%A0%ED%98%95-%EA%B2%80%EC%82%AC', '_blank')}>MBTI 검사하기</Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="DISC 성격 유형"
                      id="disc"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:this.state.disc,
                        onChange:(e)=>this.setState({disc:e.target.value, canUpdate:true})
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button style={{marginTop:30}} color="primary" 
                      onClick={()=>window.open('http://disc.aiselftest.com/', '_blank')}>DISC 검사하기</Button>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <InputLabel style={{ color: "#AAAAAA" }}>About me</InputLabel>
                    <CustomInput
                      labelText="간단한 소개글을 적어주세요."
                      id="about-me"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        value:this.state.intro,
                        onChange:(e)=>this.setState({intro:e.target.value, canUpdate:true})
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={()=>this.changePassword()} >Change password</Button>
                <Button color="primary" onClick={()=>this.updateProfile()} disabled={!this.state.canUpdate}>Update Profile</Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardAvatar profile>
                <div style={{cursor:'pointer'}} onClick={()=> {
                    this.profile_file_ref.click()
                }}>
                  <img src={U.img(this.state.profile.profile_img)} alt="..." />
                  <input ref={r=> this.profile_file_ref = r} type="file" onChange={async(e)=> {
                    const {auth, AuthAct} = this.props
                    const res = await Api.upload(e.target.files[0], auth.profile.id, 'profile_img')
                    AuthAct.update(auth.profile, {
                      profile_img:res.url
                    })}} hidden />
                </div>
              </CardAvatar>
              <CardBody profile>
                <h6 style={styles.cardCategory}>{this.state.profile.role} / {this.state.profile.team}</h6>
                <h4 style={styles.cardTitle}>{this.state.profile.name_ko} {this.state.profile.name_en}</h4>
                <p style={styles.description}>
                  {this.state.profile.intro}
                </p>
                {/* <Button color="primary" round>
                  Follow
                </Button> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Modal open={this.state.showModal} onClose={()=> this.setState({showModal:false})}>
          <div css={css`
            position:absolute;
            width:100%;
            max-width:500px;
            top: 50%; 
            left: 50%; 
            transform: translate(-50%, -50%);
          `}>
            <Card>
              <CardBody>
                <h4>비밀번호 변경하기</h4>
                <CustomInput
                    labelText="기존 비밀번호"
                    id="modal_pass"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type:'password',
                      value:this.state.modal_pass,
                      onChange:(e)=>this.setState({modal_pass:e.target.value})
                    }}
                  />
                <CustomInput
                  labelText="새 비밀번호"
                  id="modal_pass_1"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type:'password',
                    value:this.state.modal_pass_1,
                    onChange:(e)=>this.setState({modal_pass_1:e.target.value})
                  }}
                />
                <CustomInput
                  labelText="비밀번호 확인"
                  id="modal_pass_2"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type:'password',
                    value:this.state.modal_pass_2,
                    onChange:(e)=>this.setState({modal_pass_2:e.target.value})
                  }}
                />
                <div style={{color:'red'}}>{this.state.modal_message}</div>
                <Button color="primary" onClick={()=>this.setState({showModal:false})}>취소</Button>
                <Button color="primary" onClick={async()=>{
                  this.setState({modal_message:''})
                  if (this.state.modal_pass_1 && this.state.modal_pass_1 !== '' && this.state.modal_pass_1 !== this.state.modal_pass_2) {
                    this.setState({modal_message:'두 비밀번호가 일치하지 않습니다.'})
                    return
                  }
                  const res = await Api.update<{message:string}>('/api-user/',1,{
                    password:this.state.modal_pass,
                    new_password:this.state.modal_pass_1
                  })
                  if (res.message === 'password not match') {
                    this.setState({modal_message:'현재 비밀번호가 맞지 않습니다.'})
                    return
                  } else if (res.message === 'fail') {
                    this.setState({modal_message:'이메일 비밀번호 변경중 실패핬습니다.'})
                    return
                  }
                  this.setState({showModal:false})
                }}>변경</Button>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </div>
    }
}

export default connectWithoutDone(
    state=>({
        auth:state.auth
    }),
    dispatch=>({
      AuthAct:binding(AuthAction, dispatch),
    }),
    Profile
)

const styles:{[s:string]:CSSProperties} = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
    //   fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    }
};