/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Base } from 'core/Base';
import { connectWithoutDone } from 'core/connection';
import Table from "components/Table/Table.js";
import Button from 'components/CustomButtons/Button';
import { FormControl, FormControlLabel, FormLabel, InputLabel, Modal, Radio, RadioGroup, Select } from '@material-ui/core';
import CustomInput from 'components/CustomInput/CustomInput';
import { Api, domain } from 'core/Api';
import { Profile } from 'types/api.types';
import * as ApiType from 'types/api.types';
import { AuthState } from 'screens/auth/Auth.action';
import moment from 'moment';
import { U } from 'core/U'
import * as custom from 'types/custom.types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Pagination } from '@material-ui/lab';

interface Props {
    auth:AuthState
}

interface States {
    modalOpen:string
    modalType:number
    modal_title:string
    modal_price:number
    modal_body:string
    modal_approver:string
    modal_type:string
    profiles:Profile[]
    payment_items: ApiType.Payment<Profile,Profile>[]
    payment_items_current_page:number
    payment_items_total_page:number
    payment_items_approver_1: ApiType.Payment<Profile, Profile>[]
    payment_items_approver_1_current_page:number
    payment_items_approver_1_total_page:number
    payment_items_approver_2: ApiType.Payment<Profile, Profile>[]
    payment_items_approver_2_current_page:number
    payment_items_approver_2_total_page:number
}

class Payment extends Base<Props, States> {

    state:States = {
        modalOpen:'',
        modalType:0,
        modal_title:'',
        modal_price:0,
        modal_body:'',
        modal_approver:'',
        modal_type:'',
        profiles:[],
        payment_items:[],
        payment_items_current_page:1,
        payment_items_total_page:1,
        payment_items_approver_1: [],
        payment_items_approver_1_current_page:1,
        payment_items_approver_1_total_page:1,
        payment_items_approver_2: [],
        payment_items_approver_2_current_page:1,
        payment_items_approver_2_total_page:1
    }

    async componentDidMount() {
        const {auth} = this.props
        if (auth.profile) {
            const profiles = await Api.list<Profile[]>('/api-profile/',{})
            // if ((auth.profile.user as custom.auth.User).is_staff) {
            //     const res1 = await Api.list<ApiType.Payment[]>('/api-payment/',{
            //         status:'비용처리중'
            //     })
            //     const res2 = await Api.list<ApiType.Payment[]>('/api-payment/',{
            //         status:'완료'
            //     })
            //     const res3 = await Api.list<ApiType.Payment[]>('/api-payment/',{
            //         author:auth.profile.id,
            //         approver:auth.profile.id,
            //         logic:'author__OR__approver'
            //     })
            //     payment_items = res3.concat(res1.concat(res2))
            // } else {
            const res = await Api.list<{total_page:number, items:ApiType.Payment<Profile, Profile>[]}>('/api-payment/',{
                page:1,
                count_per_page:10,
                author:auth.profile.id,
            })
            let res2 = await Api.list<{total_page:number, items:ApiType.Payment<Profile, Profile>[]}>('/api-payment/',{
                page:1,
                count_per_page:10,
                approver:auth.profile.id,
            })
            let payment_items_approver_2:ApiType.Payment<Profile, Profile>[] = []
            let payment_items_approver_2_total_page = 1
            if (auth.profile.user.is_staff) {
                const res3 = await Api.list<{total_page:number, items:ApiType.Payment<Profile, Profile>[]}>('/api-payment/',{
                    page:1,
                    count_per_page:10
                })
                payment_items_approver_2 = res3.items
                payment_items_approver_2_total_page = res3.total_page
            }
            await Api.expand(res.items.concat(res2.items).concat(payment_items_approver_2), 'author', '/api-profile/')
            await Api.expand(res.items.concat(res2.items).concat(payment_items_approver_2), 'approver', '/api-profile/')
            // }
            this.setState({profiles, 
                payment_items:res.items,
                payment_items_current_page:1,
                payment_items_total_page:res.total_page,
                payment_items_approver_1: res2.items,
                payment_items_approver_1_current_page:1,
                payment_items_approver_1_total_page:res2.total_page,
                payment_items_approver_2,
                payment_items_approver_2_current_page:1,
                payment_items_approver_2_total_page
            })
        }
    }

    async updatePayment(key, status:string, id:number) {
        await Api.update<ApiType.Payment>('/api-payment/',id ,{
            status,
            price:this.state.modal_price,
            body:this.state.modal_body,
            type:this.state.modal_type
        })
        this.changePage(key, 1)
        this.setState({modalOpen:'', modalType:0})
    }

    renderModalButton() {
        const  {auth} = this.props
        const id = Number(this.state.modalOpen.replace('item-',''))
        if (this.state.modalType === 0) {
            const legacy = this.state.payment_items.find(p=>p.id === id)
            if (legacy && legacy.status === '정산완료') {
                return <div style={{float:'right'}}>
                    <Button color="success" onClick={()=>this.setState({modalOpen:''})}>닫기</Button>
                </div>
            } else if (legacy && (legacy.status === '승인됨' || legacy.status === '처리거절')) {
                return <div style={{float:'right'}}>
                    <Button color="danger" onClick={()=>this.updatePayment('payment_items','거절됨', id)}>거절</Button>
                    <Button color="success" onClick={()=>this.updatePayment('payment_items', '비용처리중', id)}>영주승제출</Button>
                </div>
            } else {
                return <div style={{float:'right'}}>
                    <Button color="danger" onClick={()=>this.updatePayment('payment_items', '거절됨', id)}>거절</Button>
                    <Button color="success" onClick={()=>this.setState({modalOpen:'', modalType:0})}>취소</Button>
                </div>
            }
        } else if (this.state.modalType === 1) {
            const legacy = this.state.payment_items_approver_1.find(p=>p.id === id)
            if (legacy.status === '요청중') {
                return <div style={{float:'right'}}>
                    <Button color="danger" onClick={()=>this.updatePayment('payment_items_approver_1','거절됨', id)}>거절</Button>
                    <Button color="success" onClick={()=>this.updatePayment('payment_items_approver_1','2차 승인요청중', id)}>승인</Button>
                </div>
            } else if (legacy.status === '정산완료') {
                return <div style={{float:'right'}}>
                    <Button color="success" onClick={()=>this.setState({modalOpen:'', modalType:0})}>닫기</Button>
                </div>
            } else {
                return <div style={{float:'right'}}>
                    <Button color="danger" onClick={()=>this.updatePayment('payment_items_approver_1','거절됨', id)}>거절</Button>
                    <Button color="success" onClick={()=>this.setState({modalOpen:'', modalType:0})}>취소</Button>
                </div>
            }
        } else if (this.state.modalType === 2) {
            const legacy = this.state.payment_items_approver_2.find(p=>p.id === id)
            if (legacy.status === '비용처리중') {
                return <div style={{float:'right'}}>
                    <Button color="danger" onClick={()=>this.updatePayment('payment_items_approver_2','처리거절', id)}>거절</Button>
                    <Button color="success" onClick={()=>this.updatePayment('payment_items_approver_2','정산완료', id)}>완료</Button>
                </div>
            } else if(legacy.status === '정산완료') {
                return <div style={{float:'right'}}>
                    <Button color="success" onClick={()=>this.setState({modalOpen:'', modalType:0})}>닫기</Button>
                </div>
            } else {
                return <div style={{float:'right'}}>
                    <Button color="danger" onClick={()=>this.updatePayment('payment_items_approver_2','거절됨', id)}>거절</Button>
                    <Button color="success" onClick={()=>this.updatePayment('payment_items_approver_2','승인됨', id)}>승인</Button>
                </div>
            }
        }
    }

    renderPaymentList(type, title, desc, payment_items, current_page, total_page, onchange, create=false) {
        const heads = ["품목", "금액", "진행상황", "신청자","승인자", "수정일"]
        if (type === 2) {
            heads.push('구분')
        }
        return <Card>
        <CardHeader color="warning">
            <h4 style={styles.cardTitleWhite}>{title}</h4>
            <p style={styles.cardCategoryWhite}>
                {desc} 
            </p>
        </CardHeader>
        <CardBody>
            <Table
            tableHeaderColor="warning"
            tableHead={heads}
            tableData={payment_items.filter(item=>item).map(item=> {
                const output = [
                    item.title, 
                    new Intl.NumberFormat('ko-kr', { style: 'currency', currency: 'KRW' }).format(item.price),
                    item.status, 
                    (item.author as Profile).name_en,
                    (item.approver as Profile).name_en,
                    moment(item.updated).format('YYYY-MM-DD HH:mm:ss')
                ]
                if (type === 2) {
                    output.push(item.type)
                }
                return output
            })}
            onClick={(r,c)=> {
                this.setState({
                    modalOpen:'item-'+payment_items[r].id,
                    modal_title:payment_items[r].title,
                    modal_price:payment_items[r].price,
                    modal_body:payment_items[r].body,
                    modal_approver:String(U.getId(payment_items[r].approver)),
                    modalType:type,
                    modal_type:payment_items[r].type
                })
            }}
            />
            {create && <Button style={{float:'right'}} color="success" onClick={()=>this.setState({
                modalOpen:'create',
                modalType:0,
                modal_title:'',
                modal_price:0,
                modal_body:'',
                modal_approver:'',
            })}>요청 생성</Button>}
            <div style={{marginTop:30}}>
                <Pagination count={total_page} page={current_page} 
                    onChange={(e:any)=> {
                        let label = e.target.getAttribute('aria-label')
                        if (label === null) {
                            label = e.target.parentNode.getAttribute('aria-label')
                            if (label === null) {
                                label = e.target.parentNode.parentNode.getAttribute('aria-label')
                            }
                        }
                        if (label === 'Go to next page') onchange(current_page+1)
                        else if (label === 'Go to previous page') onchange(current_page-1)
                        else if (label !== null && label.indexOf('Go to page ') === 0) {
                            const page = Number(label.replace('Go to page ',''))
                            onchange(page)
                        }
                    }}
                    color="primary"/>
            </div>
        </CardBody>
        </Card>
    }

    async changePage(key, page) {
        const {auth} = this.props
        if (key === 'payment_items') {
            const res = await Api.list<{total_page:number, items:ApiType.Payment<Profile, Profile>[]}>('/api-payment/',{
                page, count_per_page:10,
                author:auth.profile.id,
            })
            await Api.expand(res.items, 'author', '/api-profile/')
            await Api.expand(res.items, 'approver', '/api-profile/')
            this.setState({
                payment_items:res.items, 
                payment_items_current_page:page, 
                payment_items_total_page:res.total_page
            })
        } else if (key === 'payment_items_approver_1') {
            const res = await Api.list<{total_page:number, items:ApiType.Payment<Profile, Profile>[]}>('/api-payment/',{
                page, count_per_page:10,
                approver:auth.profile.id,
            })
            await Api.expand(res.items, 'author', '/api-profile/')
            await Api.expand(res.items, 'approver', '/api-profile/')
            this.setState({
                payment_items_approver_1:res.items, 
                payment_items_approver_1_current_page:page, 
                payment_items_approver_1_total_page:res.total_page
            })
        } else if (key === 'payment_items_approver_2') {
            const res = await Api.list<{total_page:number, items:ApiType.Payment<Profile,Profile>[]}>('/api-payment/',{
                page, count_per_page:10
            })
            await Api.expand(res.items, 'author', '/api-profile/')
            await Api.expand(res.items, 'approver', '/api-profile/')
            this.setState({
                payment_items_approver_2:res.items, 
                payment_items_approver_2_current_page:page, 
                payment_items_approver_2_total_page:res.total_page
            })
        }
    }

    render() {
        const {auth} = this.props
        return <div>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {this.renderPaymentList(0, '비용처리', '요청 > 승인 > 비용사용 > 결과 보고 > 비용 지급 순서로 진행됩니다.', 
                    this.state.payment_items, this.state.payment_items_current_page, this.state.payment_items_total_page, (page)=> {

                    },true)}
                {this.state.payment_items_approver_1.length > 0 && this.renderPaymentList(1, '1차 승인필요', '승인자로 지정된 요청사항들 입니다.',
                    this.state.payment_items_approver_1, this.state.payment_items_approver_1_current_page, this.state.payment_items_approver_1_total_page,(page)=> {

                    },)}
                {this.state.payment_items_approver_2.length > 0 && this.renderPaymentList(2, '2차 승인필요', '2차 승인 및 지급처리가 필요한 요청사항들 입니다.',
                    this.state.payment_items_approver_2, this.state.payment_items_approver_2_current_page, this.state.payment_items_approver_2_total_page,(page)=> {

                    },)}
            </GridItem>
            </GridContainer>
            <Modal open={this.state.modalOpen !== ''}
                onClose={()=>this.setState({modalOpen:'', modalType:0})}>
            <div>
            <Card style={{maxWidth:700, position:'absolute', top:30, left:0, right:0, margin:'auto', maxHeight:'100%', overflowY:'auto'}}>
                <CardBody>
                    <h4>비용사용 요청</h4>
                    <div>
                        <CustomInput formControlProps={{}}
                            labelText="품목"
                            inputProps={{
                                // placeholder: "품목",
                                disabled:this.state.modalOpen !== 'create',
                                value:this.state.modal_title,
                                onChange:(e)=>this.setState({modal_title:e.target.value})
                            }}
                        />
                    </div>
                    <div>
                        <CustomInput formControlProps={{}}
                            labelText="금액"
                            inputProps={{
                                value:this.state.modal_price,
                                type:'number',
                                onChange:(e)=>this.setState({modal_price:e.target.value})
                            }}
                        />
                    </div>
                    <FormControl>
                        <InputLabel id="approver" htmlFor="approver-native-simple">승인자 선택</InputLabel>
                        <Select
                            style={{minWidth:100}}
                            native
                            inputProps={{
                                disabled:this.state.modalOpen !== 'create',
                                name: 'approver',
                                id: 'approver-native-simple',
                            }}
                            value={this.state.modal_approver}
                            onChange={(e)=> this.setState({modal_approver:(e.target.value as string)})}
                            >
                                <option aria-label="None" value="" />
                                {this.state.profiles.map((item,i)=><option value={item.id} key={i}>{item.name_en}</option>)}
                        </Select>
                    </FormControl>
                    
                    {this.state.modalType === 2 && <div><FormControl component="fieldset">
                    <FormLabel component="legend">Gender</FormLabel>
                    <RadioGroup row aria-label="gender" name="gender1" value={this.state.modal_type} onChange={e=>this.setState({modal_type:e.target.value})}>
                        <FormControlLabel value="법인" control={<Radio />} label="법인" />
                        <FormControlLabel value="공금" control={<Radio />} label="공금" />
                    </RadioGroup>
                    </FormControl></div>}
                    {this.state.modalOpen === 'create' ? <CustomInput
                      labelText="비용 사용 목적에 대해 기재해주세요."
                      id="payment-body"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        value:this.state.modal_body,
                        onChange:(e)=>this.setState({modal_body:e.target.value})
                      }}
                    />: <CKEditor
                        editor={ ClassicEditor }
                        config={{
                            ckfinder:{uploadUrl:domain+"/upload/"+this.state.modalOpen.replace('item-','')}
                        }}
                        data={this.state.modal_body}
                        onReady={ (editor:any) => {
                            editor.setData(this.state.modal_body)
                            // You can store the "editor" and use when it is needed.
                            // console.log( 'Editor is ready to use!', editor );
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            this.setState({modal_body:data})
                            // console.log( { event, editor, data } );
                        } }
                        onBlur={ ( event, editor ) => {
                            console.log( 'Blur.', editor );
                        } }
                        onFocus={ ( event, editor ) => {
                            console.log( 'Focus.', editor );
                        } }
                    />}
                    
                    {this.state.modalOpen === 'create' ? <div style={{float:'right'}}>
                        <Button color="danger" onClick={()=>this.setState({modalOpen:''})}>취소</Button>
                        <Button color="success" onClick={async()=>{
                            const newOne = await Api.create<ApiType.Payment<Profile<custom.auth.User>, Profile>>('/api-payment/',{
                                title:this.state.modal_title,
                                author:auth.profile.id,
                                body:this.state.modal_body,
                                status:'요청중',
                                price:this.state.modal_price,
                                approver:this.state.modal_approver
                            })
                            newOne.author = auth.profile
                            newOne.approver = this.state.profiles.find(p=> String(p.id) === this.state.modal_approver)
                            this.state.payment_items.push(newOne as any)
                            this.setState({modalOpen:''})
                        }}>완료</Button>
                    </div>: this.renderModalButton()}
                </CardBody>
            </Card>
            </div>
            </Modal>
        </div>
    }
}

export default connectWithoutDone(
    state=>({
        auth:state.auth
    }),
    dispatch=>({
    }),
    Payment
)

const styles:any = {
    cardCategoryWhite: {
    //   "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    //   },
    //   "& a,& a:hover,& a:focus": {
    //     color: "#FFFFFF"
    //   }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };