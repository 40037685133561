import React from 'react'
import { Base } from '../../core/Base';
import { binding, connectWithoutDone } from '../../core/connection';
import HomeFooter from './HomeFooter';
import HomeHeader from './HomeHeader';
import { History } from 'history'
import { MediaQueryAction, MediaQueryState } from './MediaQuery.action';
import HomeBody from './HomeBody';
import './styles/main.scss'

interface Props {
    MediaQuaryAct: typeof MediaQueryAction
    mediaquery: MediaQueryState
    history: History
}

interface States {
    
}
class Home extends Base<Props, States> {

    link
    state = {
        ready:false
    }

    componentDidMount() {
        const {MediaQuaryAct} = this.props;
        MediaQuaryAct.setHeight(window.innerHeight);
        MediaQuaryAct.setWidth(window.innerWidth);

        this.link = document.createElement('link')
        this.link.href = 'https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css'
        this.link.rel = 'stylesheet'
        this.link.onload = () => {
            this.setState({ready:true})
        }
        document.head.append(this.link)

        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener('resize', this.updateWindowDimensions, true);
    }

    componentWillUnmount() {
        this.link.remove()
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    handleScroll = () => {
        const {MediaQuaryAct, mediaquery} = this.props;
        if (window.scrollY !== mediaquery.lastScrollY) {
            MediaQuaryAct.setLastScrollY(window.scrollY);
        }
    };

    updateWindowDimensions = () => {
        const {MediaQuaryAct} = this.props;
        MediaQuaryAct.setHeight(window.innerHeight);
        MediaQuaryAct.setWidth(window.innerWidth);
      }

    render() {
        if (!this.state.ready) return <div />
        return <div id="home" className="d-flex flex-column">
            {/* <HomeHeader />
            <HomeBody />
            <HomeFooter history={this.props.history}/> */}
            <div style={{maxWidth:1000, margin:'auto'}}>
                <img src="/assets/parking.jpg" />
            </div>
        </div>
    }
}

export default connectWithoutDone(
    state=>({
        mediaquery:state.MediaQuery
    }),
    dispatch=>({
        MediaQuaryAct: binding(MediaQueryAction, dispatch),
    }),
    Home
) 