/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import Card from 'components/Card/Card';
import CardAvatar from 'components/Card/CardAvatar';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Table from "components/Table/Table.js";
import { Api } from 'core/Api';
import { Base } from 'core/Base';
import { connectWithoutDone } from 'core/connection';
import { U } from 'core/U';
import { History } from 'history';
import { Profile } from 'types/api.types';
interface Props {
    history: History
}

interface States {
    profiles:Profile[]
    selecedProfile?:Profile
}

class Humans extends Base<Props, States> {

    state:States = {
        profiles:[]
    }

    async componentDidMount() {
        const profiles = await Api.list<Profile[]>('/api-profile/', {
            company:'(주)주스'
        })
        this.setState({profiles})
    }

    render() {
        return <div>
            <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
                <Card>
                <CardHeader color="primary">
                    <h4 style={styles.cardTitleWhite}>임직원목록</h4>
                    <p style={styles.cardCategoryWhite}>
                        선택하면 상세정보를 볼 수 있습니다.
                    </p>
                </CardHeader>
                <CardBody>
                    <Table
                    tableHeaderColor="primary"
                    tableHead={["이름(영어)", "이름(한글)"]}
                    tableData={this.state.profiles.map(item=> [item.name_en, item.name_ko])}
                    onClick={(r,c)=> this.setState({selecedProfile:this.state.profiles[r]})}
                    />
                </CardBody>
                </Card>
            </GridItem>
            {this.state.selecedProfile && <GridItem xs={12} sm={12} md={4}>
                <Card profile>
                <CardAvatar profile>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img src={U.img(this.state.selecedProfile.profile_img)} alt="..." />
                    </a>
                </CardAvatar>
                <CardBody profile>
                    <h6 style={styles.cardCategory}>{this.state.selecedProfile.role} / {this.state.selecedProfile.team}</h6>
                    <h4 style={styles.cardTitle}>{this.state.selecedProfile.name_en} {this.state.selecedProfile.name_ko}</h4>
                    <p style={styles.description}>
                    {this.state.selecedProfile.mbti} / {this.state.selecedProfile.disc}<br/>
                    {this.state.selecedProfile.email}<br/>
                    {this.state.selecedProfile.gmail}<br/>
                    {this.state.selecedProfile.tel}<br/><br/>
                    {this.state.selecedProfile.intro}
                    </p>
                    {/* <Button color="primary" round>
                    Follow
                    </Button> */}
                </CardBody>
                </Card>
            </GridItem>}
            </GridContainer>
        </div>
    }
}

export default connectWithoutDone(
    state=>({
    }),
    dispatch=>({
    }),
    Humans
)


const styles:any = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };