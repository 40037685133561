/** @jsxRuntime classic */
/** @jsx jsx */
import { css,jsx } from '@emotion/react'
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import { Base } from 'core/Base';
import { connectWithoutDone } from 'core/connection';
import { History } from 'history';
import Table from "components/Table/Table.js";
import { CSSProperties } from 'react';
import CustomInput from 'components/CustomInput/CustomInput';
import { Search } from '@material-ui/icons';
import { Select, FormControl, InputLabel, Modal, Checkbox } from '@material-ui/core';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Pagination } from '@material-ui/lab';
import { ColoringContent, MusicAgency, MusicComposer, MusicGenre, MusicGroup, MusicItem, MusicSinger, MusicTag } from 'types/api.types';
import { Api } from 'core/Api';
import { U } from 'core/U';
import { AuthState } from 'screens/auth/Auth.action';
import { G } from 'core/G';
import { downloadURI } from '../coloring/Coloring.view';
import { s2ab, sheet_from_array_of_arrays } from '../coloring/settlement/ColoringSettlement';
declare var window:any;
declare var XLSX:any;
function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
      <Box position="relative" display="inline-flex" style={{verticalAlign:'middle'}}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
interface Props {
    auth:AuthState
    history: History
}

interface States {
    searchInput:string
    searchType:string
    head_artist:string
    head_artists:MusicSinger[]
    head_composer:string
    head_composers:MusicComposer<MusicAgency>[]
    head_agency:string
    head_agencies:MusicAgency[]
    head_genre:string
    head_genres:MusicGenre[]
    group:string
    groups:MusicGroup[]
    modalOpen:string
    modalGroup:string
    modal_group_name:string
    musicitems:MusicItem<MusicComposer<MusicAgency>, MusicTag, MusicGenre, MusicSinger>[]
    music_total_page:number
    music_total_count:number
    music_current_page:number
    music_checked:number[]
    csvDownloading:boolean
    progress:number
    loading:boolean
    isInsider:boolean
    coloring_working:number
    coloring:ColoringContent[]
    coloring_filter:boolean
    coloring_start_number:string
}

class Contents extends Base<Props, States> {

    state:States = {
        searchInput:"",
        searchType:'No',
        head_artist:'',
        head_artists:[],
        head_composer:'',
        head_composers:[],
        head_agency:'',
        head_agencies:[],
        head_genre:'',
        head_genres:[],
        group:'',
        groups:[],
        modalOpen:'',
        modalGroup:'',
        modal_group_name:'',
        musicitems:[],
        music_total_page:1,
        music_total_count:0,
        music_current_page:1,
        music_checked:[],
        csvDownloading:false,
        progress:0,
        loading:false,
        isInsider:false,
        coloring_working:-1,
        coloring:[],
        coloring_filter: false,
        coloring_start_number:''
    }

    _isMounted = false

    option = {

    }

    async componentDidMount() {
        this._isMounted = true
        const {auth} = this.props
        if (auth.profile) {
            const composers = await Api.list<MusicComposer<MusicAgency>[]>('/api-music/composer/',{})
            await Api.expand(composers, 'agency', '/api-music/agency/')
            const singers = await Api.list<MusicSinger[]>('/api-music/singer/',{})
            const genres = await Api.list<MusicGenre[]>('/api-music/genre/',{})
            const agencies = await Api.list<MusicGenre[]>('/api-music/agency/',{})
            const groups = await Api.list<MusicGroup[]>('/api-music/group/',{
                owner:U.getId(auth.profile.user),
                published:1,
                logic:'owner__OR__published'
            })
            this.setState({
                head_artists:singers,
                head_composers:composers,
                head_agencies:agencies.filter(a=> a.name !== 'MFU'),
                head_genres:genres,
                groups,
                isInsider:auth.profile.company === '(주)주스'
            })
            this.changePage(1)
        }
    }

    async changePage(page) {
        this.setState({loading:true})
        const res = await Api.list<{total_page:number, total_count:number, items:MusicItem<MusicComposer<MusicAgency>, MusicTag, MusicGenre, MusicSinger>[]}>('/api-music/item/',{
            isValid:true,
            page, count_per_page:20,
            // composer__agency__name__not:'MFU',
            ...(this.state.coloring_filter ? {coloringcontent__isnull:true, ignoreColoring:false}:{}),
            ...this.option
        })
        await Api.expand(res.items, 'tag', '/api-music/tag/', true)
        const musicitems = res.items.map(item=> {
            item.singer = this.state.head_artists.filter(artist=>(item.singer as any[]).indexOf(artist.id) !== -1)
            item.genre = this.state.head_genres.find(g=> g.id === U.getId(item.genre))
            item.composer = this.state.head_composers.find(c=> c.id === U.getId(item.composer))
            return item
        })
        const coloring = await Api.list<ColoringContent[]>(ColoringContent.API, {
                'source__in[]':musicitems.map(d=> d.id)
            })

        this.setState({musicitems, music_current_page:page, music_total_page:res.total_page,
            music_checked:[], music_total_count:res.total_count, loading:false, coloring})
    }

    setState(state:any) {
        if (this._isMounted){
            super.setState(state)
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    downloadExcel() {
        this.setState({csvDownloading:true, progress:0})
        let url = process.env.NODE_ENV === 'development' ? 
        'ws://localhost:8000/ws/music/excel/' :
        'wss://api.intranet.juice.co.kr/ws/music/excel/'
        const socket = new WebSocket(url);
        const start = new Date().getTime()
        socket.onopen =(e) => {
            socket.send(JSON.stringify({
                type: 'start',
                items:this.state.music_checked,
                group:this.state.group
            }))
        }
        socket.onmessage = (e) => {
            if (typeof(e.data) === 'string') {
                const data = JSON.parse(e.data);
                if (data.state === 'busy') {
                    this.setState({
                        progress: Math.floor((data.cur / data.total)*100)
                    });
                } else if (data.state === 'finish') {
                    socket.close();
                }
            } else {
                const a:any = document.createElement('a');
                document.body.appendChild(a);
                a.style = 'display:none';
                const url = window.URL.createObjectURL(e.data);
                a.href = url;
                a.download = 'Juice_Contents.xlsx'
                a.click()
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
        socket.onclose = (e) => {
            console.log('take time : ',new Date().getTime() - start)
            this.setState({csvDownloading:false});
        }
    }

    getAudioPlayTime(item_id:number) {
        return new Promise(resolve=> {
            const audio = document.createElement('audio')
            audio.src = 'https://api.intranet.juice.co.kr/content/'+item_id+'/'
            audio.addEventListener('loadedmetadata', ()=>{
                const duration = audio.duration
                audio.remove()
                resolve(duration)
            }, false);
        })
    }

    async makeColoring() {
        this.setState({coloring_working:0})
        const group = await Api.retrieve<MusicGroup<number, 
            MusicItem<MusicComposer, MusicTag, MusicGenre>>
        >(MusicGroup.API, this.state.group,{})
        await Api.expand([group], 'items', MusicItem.API, true)
        await Api.expand(group.items, 'composer', MusicComposer.API)
        await Api.expand(group.items, 'tag', MusicTag.API, true)
        await Api.expand(group.items, 'genre', MusicGenre.API)
        // @ts-ignore
        const agency_ids = [...new Set(group.items.map(d=> d.composer.agency))]
        const agency = await Api.list<MusicAgency[]>(MusicAgency.API, {
            'pk__in[]':agency_ids
        })
        const group2 = {
            ...group,
            items:group.items.map(d=> ({
                ...d,
                composer: {
                    ...d.composer,
                    agency:agency.find(a=> a.id === d.composer.agency)
                }
            }))
        }
        let start_number = this.state.coloring_start_number === '' ? 0 : Number(this.state.coloring_start_number) 
        for(let i=0;i<group.items.length;i++) {
            const item = group.items[i]
            const duration = await this.getAudioPlayTime(item.id)
            if (duration < 60) {
                alert(`${item.id} - ${item.title} 곡은 60초 미만이라서 컬러링 변환에 실패하였습니다.`)
                this.setState({coloring_working:-1})
                return
            }
           this.setState({coloring_working: (i+1)/group.items.length* 10})
        }

        if (start_number === 0) {
            const legacy = await Api.list<{items:ColoringContent[]}>(ColoringContent.API, {
                page:1,
                count_per_page:1,
            })
            start_number = legacy.items[0].no+1
        }

        const contents = []
        for(let i=0;i<group.items.length;i++) {
            const item = group.items[i]
            let audiourl = ''
            let filename = ''
            if (item.wav) {
                audiourl = 'https://api.intranet.juice.co.kr/content2/'+item.id+'/'
                filename = `${start_number+i}.wav`
            } else {
                audiourl = 'https://api.intranet.juice.co.kr/content/'+item.id+'/'
                filename = `${start_number+i}.mp3`
            }
            const res = await fetch(audiourl)
            const blob = await res.blob();
            const file = new File([blob], filename)
            contents.push(file)
            this.setState({coloring_working:10+ (i+1)/group.items.length* 50})
        }
        const wb = { Sheets: {}, SheetNames: [] };
        const sheet = sheet_from_array_of_arrays([
            ['No.', 'title', 'nickname', '장르', '분위기'],
            ...group2.items.map((d,i)=> [
                start_number + i,d.title, (d.composer.agency ? (d.composer.agency.name === 'Juice' ? 'JUICE' : d.composer.agency.name)+ "/" : '') + d.composer.name, d.genre.name, d.tag.map(t=>t.name).join(', ')
            ])            
        ])
        wb.Sheets['시트1'] = sheet
        wb.SheetNames.push('시트1')
        const wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'})
        const meta = new File([new Blob([s2ab(wbout)],{type:"application/octet-stream"})], 'meta.xlsx')
        let url = process.env.NODE_ENV === 'development' ? 
        'ws://localhost:8000/ws/coloring/transform/' :
        'wss://api.intranet.juice.co.kr/ws/coloring/transform/'
        const socket = new WebSocket(url)
        const orderedContents = contents
        // .sort((a,b)=> a.name > b.name ? 1: -1)
        let uploadCount = 0
        let index = -1
        socket.onopen = async() => {
            socket.send(JSON.stringify({
                'action':'upload',
                'user':G.profile.value.id,
                'contents':orderedContents.map(d=> d.name)
            }))
        }
        socket.onmessage = async(e) => {
            if (typeof(e.data) === 'string') {
                const data = JSON.parse(e.data)
                if(data.result === 'success') {
                    if (index === -1) {
                        socket.send(await meta.arrayBuffer())
                        index += 1
                    } else if (orderedContents.length > index) {
                        socket.send(await orderedContents[index].arrayBuffer())
                        index += 1
                        uploadCount += 1
                        this.setState({coloring_working:60 + (uploadCount/orderedContents.length)*10})
                    } else {
                        socket.send(JSON.stringify({
                            'action':'transform'
                        }))
                    }
                } else if (data.result === 'progress') {
                    this.setState({coloring_working:60+Number(data.value)*40})

                } else {
                    alert(data.message)
                    // this.error.next(data.message)
                    // this.progress.next(0)
                    socket.close()
                }
            } else {
                // this.progress.next(100)
                const url = window.URL.createObjectURL(e.data);
                downloadURI(url, 'coloring.zip')
                socket.close()
            }
        }
        socket.onclose = () => {
            this.setState({coloring_working:-1})
        }
    }

    render() {
        // const coloring_ids = this.state.coloring.map(d=> d.source)
        const item_id_used_to_coloring:number[] = []
        this.state.coloring.forEach(c=> item_id_used_to_coloring.push(...c.source))
        return <div>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                <CardHeader color="info">
                    <h4 style={styles.cardTitleWhite}>컨텐츠</h4>
                </CardHeader>
                <CardBody>
                    <div css={css`
                        display:flex;
                        justify-content:space-between;
                        align-items:center;
                    `}>
                        <div>
                            <FormControl 
                                style={{marginTop:27, marginRight:5}}
                            >
                                <InputLabel htmlFor="age-native-simple">타입</InputLabel>
                                <Select
                                native
                                value={this.state.searchType}
                                onChange={(e)=> this.setState({searchType:(e.target.value as string)})}
                                >
                                <option value={'No'}>No</option>
                                <option value={'제목'}>제목</option>
                                </Select>
                            </FormControl>
                            <CustomInput formControlProps={{}}
                                inputProps={{
                                    placeholder: "Search",
                                    inputProps: {
                                    "aria-label": "Search"
                                    },
                                    value:this.state.searchInput,
                                    onChange:(e)=>this.setState({searchInput:e.target.value})
                                }}
                                />
                            <Button color="white" aria-label="edit" justIcon round
                                onClick={()=> {
                                    if (this.state.searchInput === '') {
                                        delete this.option['pk']
                                        delete this.option['title__contains']
                                    } else if(this.state.searchType === 'No') {
                                        delete this.option['title__contains']
                                        this.option['pk'] = this.state.searchInput
                                    } else if(this.state.searchType === '제목') {
                                        delete this.option['pk']
                                        this.option['title__contains'] = this.state.searchInput
                                    }
                                    this.changePage(1)
                                }}>
                                <Search />
                            </Button>
                        </div>
                        <div>
                            <Button color={this.state.coloring_filter ? "success":"white"} onClick={()=>{
                                super.setState({
                                    coloring_filter:!this.state.coloring_filter
                                }, ()=> {
                                    this.changePage(1)
                                })
                            }}>컬러링 필터</Button>
                            {this.state.csvDownloading ? <CircularProgressWithLabel value={this.state.progress} /> :
                            <Button color="success" onClick={()=>this.downloadExcel()}>Excel 다운</Button>}
                            {/* <Button color="warning">Mp3 다운</Button> */}
                        </div>
                    </div>
                    <Select
                    native
                    value={this.state.group}
                    onChange={(e)=> {
                        this.setState({group:(e.target.value as string)})
                        if (e.target.value === '') {
                            delete this.option['musicgroup']
                        } else {
                            this.option['musicgroup'] = e.target.value
                        }
                        this.changePage(1)
                    }}
                    >
                        <option value={''}>전체보기</option>
                        {this.state.groups.map((item, i)=> <option value={item.id} key={i}>{item.published && '(공유)'}{item.name}</option>)}
                    </Select>
                     {this.state.music_total_count}개
                    <Table
                    tableHeaderColor="info"
                    tableHead={["","No(컬러링무시)", 
                    // "(컬러링)제목", 
                        <Select
                        native
                        style={{maxWidth:100}}
                        value={this.state.head_artist}
                        onChange={(e)=> {
                            this.setState({head_artist:(e.target.value as string)})
                            if (e.target.value === '') {
                                delete this.option['singer']
                            } else {
                                this.option['singer'] = e.target.value 
                            }
                            this.changePage(1)                            
                        }}
                        >
                        <option value={''}>아티스트</option>
                        {this.state.head_artists.sort((item1, item2) => (item1.name).localeCompare(item2.name)).map((item,i)=><option value={item.id} key={i}>{item.name}</option>)}
                        </Select>, 
                        <Select
                        native
                        style={{maxWidth:100}}
                        value={this.state.head_composer}
                        onChange={(e)=> {
                            this.setState({head_composer:(e.target.value as string)})
                            if (e.target.value === '') {
                                delete this.option['composer']
                            } else {
                                this.option['composer'] = e.target.value 
                            }
                            this.changePage(1)        
                        }}
                        >
                        <option value={''}>작곡가</option>
                        {this.state.head_composers.map((item,i)=><option value={item.id} key={i}>{item.name}</option>)}
                        </Select>, <Select
                        native
                        style={{maxWidth:100}}
                        value={this.state.head_agency}
                        onChange={(e)=> {
                            this.setState({head_agency:(e.target.value as string)})
                            if (e.target.value === '') {
                                delete this.option['composer__agency']
                            } else {
                                this.option['composer__agency'] = e.target.value 
                            }
                            this.changePage(1)        
                        }}
                        >
                        <option value={''}>소속</option>
                        {this.state.head_agencies.map((item,i)=><option value={item.id} key={i}>{item.name}</option>)}
                        </Select>, <Select
                        native
                        style={{maxWidth:100}}
                        value={this.state.head_genre}
                        onChange={(e)=> {
                            this.setState({head_genre:(e.target.value as string)})
                            this.setState({head_agency:(e.target.value as string)})
                            if (e.target.value === '') {
                                delete this.option['genre']
                            } else {
                                this.option['genre'] = e.target.value 
                            }
                            this.changePage(1)  
                        }}
                        >
                        <option value={''}>장르</option>
                        {this.state.head_genres.map((item,i)=><option value={item.id} key={i}>{item.name}</option>)}
                        </Select>, "해시", "재생"]}
                    tableData={
                        this.state.musicitems.map((item,i)=> [
                            <Checkbox 
                                checked={this.state.music_checked.indexOf(item.id) !== -1}
                                onChange={()=>{
                                    if(this.state.music_checked.indexOf(item.id)!==-1) {
                                        this.state.music_checked.splice(this.state.music_checked.indexOf(item.id),1)
                                    } else {
                                        this.state.music_checked.push(item.id)
                                    }
                                    this.setState({})
                                }}
                            />, <div><span>{item.id}</span><input type="checkbox" checked={item.ignoreColoring} 
                                onChange={e=>{
                                    Api.update<MusicItem>(MusicItem.API, item.id, {
                                        ignoreColoring: !item.ignoreColoring
                                    }).then(res=> {
                                        item.ignoreColoring = res.ignoreColoring
                                        this.setState({musicitems:[...this.state.musicitems]})
                                    })
                                }} /></div>, 
                                // (item_id_used_to_coloring.indexOf(item.id) === -1 ? '' : '* ') + item.title, 
                            item.singer.map(s=> s.name).join(','), 
                            item.composer ? item.composer.name : 'N', 
                            item.composer ? item.composer.agency?
                                item.composer.agency.name: 'N' : 'N', 
                            item.genre ? (item.genre as MusicGenre).name : 'N', 
                            (item.tag as MusicTag[]).map(t=> t.name).join(','), <div>
                                {!this.state.loading && <audio style={{width:'100%', minWidth:300}} controls controlsList={this.state.isInsider ? "":"nodownload"}>
                                    <source type="audio/mpeg" 
                                        src={'https://api.intranet.juice.co.kr/content/'+item.id+'/'} 
                                        // onError={(e)=> {
                                        //     fetch('/content/'+item.id, {headers:{
                                        //         Authrization: 'JWT '+localStorage.getItem('jwt_token')
                                        //     }}).then(r=> r.blob()).then(d=> {
                                        //         e.target['src'] = window.URL.createObjectURL(d)
                                        //     })
                                        // }} 
                                        />
                                    Your browser does not support the audio tag.
                                </audio>}
                            </div>
                        ])
                    }
                    // onClick={(r,c)=> this.setState({selecedProfile:this.state.profiles[r]})}
                    />
                    <Button color="primary" onClick={()=>this.setState({modalOpen:'add'})} disabled={this.state.music_checked.length === 0}>담기</Button>
                    <Button color="warning" onClick={()=>this.setState({modalOpen:'substract'})} 
                        disabled={this.state.music_checked.length === 0 || this.state.group === '' || this.state.groups
                        .filter(g=> String(g.id) === this.state.group && 
                            g.owner === U.getId(this.props.auth.profile.user)).length === 0}>빼기</Button>
                    <Button color="success" onClick={()=>this.setState({modalOpen:'create'})}>생성</Button>
                    <Button color="danger" onClick={()=>this.setState({modalOpen:'delete'})}
                        disabled={this.state.group === '' || this.state.groups
                            .filter(g=> String(g.id) === this.state.group && 
                                g.owner === U.getId(this.props.auth.profile.user)).length === 0}>삭제</Button>
                    <Button color="info" onClick={()=>this.setState({modalOpen:'share'})}
                        disabled={this.state.group === '' || this.state.groups
                        .filter(g=> String(g.id) === this.state.group && 
                            g.owner === U.getId(this.props.auth.profile.user)).length === 0}>공유</Button>
                    <input placeholder='컬러링 시작번호' value={this.state.coloring_start_number} onChange={e=> this.setState({coloring_start_number:e.target.value})} />
                    <Button disabled={this.state.group === '' || this.state.coloring_working !== -1} onClick={()=> 
                        this.makeColoring()
                    }>{this.state.coloring_working === -1 ? '컬러링' : `작업중... ${this.state.coloring_working.toFixed(2)}%`}</Button>
                    <Pagination count={this.state.music_total_page} page={this.state.music_current_page} 
                        onChange={(e:any)=> {
                            let label = e.target.getAttribute('aria-label')
                            if (label === null) {
                                label = e.target.parentNode.getAttribute('aria-label')
                                if (label === null) {
                                    label = e.target.parentNode.parentNode.getAttribute('aria-label')
                                }
                            }
                            if (label === 'Go to next page') this.changePage(this.state.music_current_page+1)
                            else if (label === 'Go to previous page') this.changePage(this.state.music_current_page-1)
                            else if (label !== null && label.indexOf('Go to page ') === 0) {
                                const page = Number(label.replace('Go to page ',''))
                                this.changePage(page)
                            }
                        }}
                        color="primary" style={{float:'right'}}/>
                </CardBody>
                </Card>
            </GridItem>
            </GridContainer>
            <Modal
                open={this.state.modalOpen !== ''}
                onClose={()=>this.setState({modalOpen:''})}
                >
                <div>
                    {this.state.modalOpen === 'create' && <Card style={{maxWidth:500, margin:'auto', marginTop:'10%'}}>
                        <CardHeader><h4>그룹 생성</h4></CardHeader>
                        <CardBody>
                            <div>
                                <CustomInput formControlProps={{}}
                                    inputProps={{
                                        placeholder: "새 그룹 이름",
                                        inputProps: {
                                        "aria-label": "새 그룹 이름"
                                        },
                                        value:this.state.modal_group_name,
                                        onChange:(e)=>this.setState({modal_group_name:e.target.value})
                                    }}
                                    />
                            </div>
                            <div css={css`text-align:right;`}>
                                <Button color="danger" onClick={()=>this.setState({modalOpen:''})}>취소</Button>
                                <Button color="success" onClick={async()=>{
                                    const {auth} = this.props
                                    const group = await Api.create<MusicGroup>('/api-music/group/',{
                                        name:this.state.modal_group_name,
                                        published:false,
                                        owner:U.getId(auth.profile.user)
                                    })
                                    this.state.groups.push(group)
                                    this.setState({modalOpen:'', modal_group_name:''})
                                }}>완료</Button>
                            </div>
                        </CardBody>
                    </Card>}
                    {this.state.modalOpen === 'delete' && <Card style={{maxWidth:500, margin:'auto', marginTop:'10%'}}>
                        <CardHeader><h4>그룹 삭제</h4></CardHeader>
                        <CardBody>
                            <div>
                                {this.state.groups.filter(g=> String(g.id) === this.state.group).map(g=>g.name).join('')}
                                그룹을 삭제하시겠습니까?
                            </div>
                            <div css={css`text-align:right;`}>
                                <Button color="danger" onClick={()=>this.setState({modalOpen:''})}>취소</Button>
                                <Button color="success" onClick={async()=>{
                                    await Api.delete('/api-music/group/', this.state.group)
                                    this.state.groups = this.state.groups.filter(g=>String(g.id) !== this.state.group)
                                    this.setState({modalOpen:'', group:''})
                                    delete this.option['musicgroup']
                                    this.changePage(1)
                                }}>완료</Button>
                            </div>
                        </CardBody>
                    </Card>}
                    {this.state.modalOpen === 'share' && <Card style={{maxWidth:500, margin:'auto', marginTop:'10%'}}>
                        <CardHeader><h4>그룹 공유</h4></CardHeader>
                        <CardBody>
                            <div>
                                {this.state.groups.find(g=> String(g.id) === this.state.group).published ? 
                                    '해당 그룹의 공유를 해지 하시겠습니까?':
                                    '해당 그룹을 공유하시겠습니까?'}
                            </div>
                            <div css={css`text-align:right;`}>
                                <Button color="danger" onClick={()=>this.setState({modalOpen:''})}>취소</Button>
                                <Button color="success" onClick={async()=>{
                                    const g = this.state.groups.find(g=> String(g.id) === this.state.group)
                                    const group = await Api.update<MusicGroup>('/api-music/group/', this.state.group, {
                                        published:!g.published
                                    })
                                    this.state.groups = this.state.groups.map(g=> String(g.id) === this.state.group ? group : g)
                                    this.setState({modalOpen:''})
                                }}>완료</Button>
                            </div>
                        </CardBody>
                    </Card>}
                    {this.state.modalOpen === 'add' && <Card style={{maxWidth:500, margin:'auto', marginTop:'10%'}}>
                        <CardHeader>
                            <h4>담을 그룹 선택</h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Select
                                native
                                value={this.state.modalGroup}
                                onChange={(e)=> this.setState({modalGroup:(e.target.value as string)})}
                                >
                                    <option value=''>그룹 선택</option>
                                    {this.state.groups.filter(g=>g.owner === U.getId(this.props.auth.profile.user))
                                        .map((item, i)=> <option value={item.id} key={i}>{item.published && '(공유)'}{item.name}</option>)}
                                </Select>
                            </div>
                            <div css={css`text-align:right;`}>
                                <Button color="danger" onClick={()=>this.setState({modalOpen:''})}>취소</Button>
                                <Button color="success" onClick={async()=>{
                                    const group = await Api.retrieve<MusicGroup>('/api-music/group/', this.state.modalGroup,{})
                                    await Api.update('/api-music/group/',this.state.modalGroup, {
                                        items:Array.from(new Set((group.items as number[]).concat(this.state.music_checked)))
                                    })
                                    this.setState({modalOpen:''})
                                }}>완료</Button>
                            </div>
                        </CardBody>
                    </Card>}
                    {this.state.modalOpen === 'substract' && <Card style={{maxWidth:500, margin:'auto', marginTop:'10%'}}>
                        <CardHeader>
                            <h4>선택된 컨텐츠 그룹에서 빼기</h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                선택한 컨텐츠가 그룹에서 빠집니다. 계속하시겠습니까?
                            </div>
                            <div css={css`text-align:right;`}>
                                <Button color="danger" onClick={()=>this.setState({modalOpen:''})}>취소</Button>
                                <Button color="success" onClick={async()=>{
                                    const group = this.state.groups.find(g=> this.state.group === String(g.id))
                                    const items = []
                                    group.items.forEach(id=> {
                                        if (this.state.music_checked.indexOf(id) === -1) {
                                            items.push(id)
                                        }
                                    })
                                    await Api.update('/api-music/group/',this.state.group, {items})
                                    this.changePage(1)
                                    this.setState({modalOpen:''})
                                }}>완료</Button>
                            </div>
                        </CardBody>
                    </Card>}
                </div>
            </Modal>
        </div>
    }
}

export default connectWithoutDone(
    state=>({
        auth:state.auth
    }),
    dispatch=>({
    }),
    Contents
)

const styles:{[s:string]:CSSProperties} = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
    //   fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    }
};