/** @jsxRuntime classic */
/** @jsx jsx */
import { css,jsx } from '@emotion/react'
import Icon from '@material-ui/core/Icon';
import { Accessibility, DateRange, LocalOffer, Store, Update, Alarm } from '@material-ui/icons';
import Card from 'components/Card/Card';
import CardFooter from 'components/Card/CardFooter';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Base } from 'core/Base';
import { connectWithoutDone } from 'core/connection';
import { History } from 'history';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import moment from 'moment';
import CardBody from 'components/Card/CardBody';
import { Button, Checkbox, FormControlLabel, Modal, TextField } from '@material-ui/core';
import CustomInput from 'components/CustomInput/CustomInput';
import { Api } from 'core/Api';
import { Profile, Schedule } from 'types/api.types';
import { AuthState } from 'screens/auth/Auth.action';
import { AttendanceUtils } from './AttendanceUtils';
import { auth } from 'types/custom.types';
interface Props {
    auth:AuthState
    history: History
}

interface States {
    year:number
    month:number
    calender:Array<CalendarCell[]>
    modalOpen:string
    reason:string
    rest:number
    leaveCount:number
    todaySchedule?:Schedule
    workingTimeForWeek:number
    checkboxValue:boolean
    reqStart:string
    reqEnd:string
    reqTimeDiable:boolean
    isRequesting:boolean
    halfCheckboxValue1:boolean
    halfCheckboxValue2:boolean
    etcCheckboxValue:boolean
    target_schedules:Schedule[]
}

type CalendarCell = {
    currentMonth:boolean
    date:number
    items:string[]
}

const COMPANY_IP:Array<string> = [
    '112.169.119.198',
]
class Attendance extends Base<Props, States> {

    state:States = {
        year:0,
        month:0,
        calender:[],
        modalOpen:'none',
        reason:'',
        rest:0,
        leaveCount:0,
        workingTimeForWeek:0,
        checkboxValue:false,
        reqStart:'09:00',
        reqEnd:'18:00',
        reqTimeDiable:false,
        isRequesting:false,
        halfCheckboxValue1:false,
        halfCheckboxValue2:false,
        etcCheckboxValue:false,
        target_schedules:[]
    }

    _isMounted = false
    today

    async componentDidMount() {
        this._isMounted = true
        const {auth} = this.props
        let leaveCount = 0
        let todaySchedule = undefined
        let workingTimeForWeek = 0
        if (auth.profile) {
            const schedules = await Api.list<Schedule[]>('/api-schedule/',{
                profile:auth.profile.id,
                annual_leave:true,
                request:false,
                start__gte:moment().startOf('year').utc().format(),
                start__lte:moment().utc().format()
            })
            leaveCount = schedules.map(item=> 
                AttendanceUtils.getLeaveType(item) === '연차' ? 1 : 0.5
            ).reduce((a:number,b:number)=> a+b,0)
            const today = await Api.list<Schedule[]>('/api-schedule/',{
                profile:auth.profile.id,
                leave:false,
                request:false,
                start__gte:moment().startOf('date').utc().format(),
                start__lte:moment().endOf('date').utc().format()
            })
            if (today.length > 0) {
                todaySchedule = today[0]
                this.today = today[0]
            }
            let ss
            let ee
            if (moment().day() === 0) {
                ss = moment().startOf('week').subtract(6, 'day')
                ee = moment().endOf('week').subtract(6, 'day')
            } else {
                ss = moment().startOf('week').add(1, 'day')
                ee = moment().endOf('week').add(1, 'day')
            }
            const week = await Api.list<Schedule[]>('/api-schedule/',{
                profile:auth.profile.id,
                leave:false,
                request:false,
                start__gte:ss.utc().format(),
                start__lte:ee.utc().format()
            })
            week.forEach(s=>{
                const start = moment(s.start)
                const end = s.end ? moment(s.end) : moment()
                const minutes = end.diff(start, 'minutes') - s.rest
                if (minutes > 0) {
                    workingTimeForWeek += minutes
                } 
            })
        }

        const year = moment().year()
        const month = moment().month()
        const calender = await this.makeCalendar(year, month)
        this.setState({
            year, month, calender, leaveCount, todaySchedule, workingTimeForWeek
        })
    }

    setState(state:any) {
        if (this._isMounted){
            super.setState(state)
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    isCompany(ip) {
        for(let i=0;i<COMPANY_IP.length;i++) {
            const company_ip = COMPANY_IP[i]
            if (company_ip === ip) {
                return true
            } else if (company_ip.indexOf('*') !== -1) {
                const block = company_ip.split('.')
                const ip_block = ip.split('.')
                let skip = false
                for(let j=0;j<block.length;j++) {
                    if (block[j] !== ip_block[j] && block[j] !== '*') {
                        skip = true
                        break
                    }
                }
                if (skip) {
                    continue
                }
                return true
            }
        }
        return false
    }

    isAttending = false
    async attend(reason:string|undefined=undefined) {
        if (this.isAttending) return
        this.isAttending = true
        const {auth} = this.props
        const time = await Api.list('/api/home/time/', {})
        if (reason) {
            const schedule = await Api.create<Schedule>('/api-schedule/',{
                profile:auth.profile.id,
                start:time,
                rest:0,
                reason:JSON.stringify({
                    '외근출근':reason
                })
            })
            this.setState({todaySchedule:schedule, modalOpen:'none', reason:''})
        } else {
            const ip:string = await Api.list('/get-ip',{})
            console.log(ip)
            if (this.isCompany(ip) && auth.profile) { // 사내에서 출석
                const schedule = await Api.create<Schedule>('/api-schedule/',{
                    profile:auth.profile.id,
                    start:time,
                    rest:0
                })
                this.setState({todaySchedule:schedule})
            } else {
                this.setState({modalOpen:'attend'})
            }
        }
        this.isAttending = false
    }

    async offwork(reason:string|undefined=undefined) {
        const time = await Api.list('/api/home/time/', {})
        if(reason) {
            const param = this.state.todaySchedule.reason ? JSON.parse(this.state.todaySchedule.reason) : {}
            param['외근퇴근'] = reason
            const schedule = await Api.update<Schedule>('/api-schedule/',this.state.todaySchedule.id, {
                end:time,
                rest:Number(this.state.rest) + AttendanceUtils.getRestByWorkingTime(this.state.todaySchedule),
                reason:JSON.stringify(param)
            })
            this.setState({todaySchedule:schedule, modalOpen:'none', reason:''})
        } else {
            const ip:string = await Api.list('/get-ip',{})
            if (this.isCompany(ip)) {
                const schedule = await Api.update<Schedule>('/api-schedule/',this.state.todaySchedule.id, {
                    end:time,
                    rest:Number(this.state.rest) + AttendanceUtils.getRestByWorkingTime(this.state.todaySchedule)
                })
                this.setState({todaySchedule:schedule, modalOpen:'none'})
            } else {
                this.setState({modalOpen:'outside_off'})
            }
        }
    }

    isRequesting = false
    async requestSchedule() {
        if(this.isRequesting) return
        const {auth} = this.props
        this.isRequesting = true
        this.setState({isRequesting:true});
        const date = moment()
            .year(this.state.year)
            .month(this.state.month)
            .date(Number(this.state.modalOpen.replace('cell','')))
            .second(0).millisecond(0)
        const reason = {'요청':this.state.reason}
        if (this.state.checkboxValue) reason['휴가'] = '연차'
        else if (this.state.halfCheckboxValue1) reason['휴가'] = '오전반차'
        else if (this.state.halfCheckboxValue2) reason['휴가'] = '오후반차'
        else if (this.state.etcCheckboxValue) reason['휴가'] = '기타휴가'
        const s = date.clone().hour(Number(this.state.reqStart.split(':')[0])).minute(Number(this.state.reqStart.split(':')[1]))
        const e = date.clone().hour(Number(this.state.reqEnd.split(':')[0])).minute(Number(this.state.reqEnd.split(':')[1]))
        await Api.create('/api-schedule/', {
            profile:auth.profile.id,
            start:s.utc().format(),
            end:e.utc().format(),
            rest:Number(this.state.rest) + AttendanceUtils.getRestByTime(s, e),
            leave:this.state.checkboxValue || this.state.halfCheckboxValue1 || this.state.halfCheckboxValue2 || this.state.etcCheckboxValue,
            annual_leave:this.state.checkboxValue || this.state.halfCheckboxValue1 || this.state.halfCheckboxValue2,
            request:true,
            reason:JSON.stringify(reason)
        })
        await Api.create('/mail/',{
            title:'근태 요청',
            message:auth.profile.name_en + '님이 근태 요청을 하셨습니다. https://juice.co.kr/admin/m_attendance 에서 확인해주세요.',
            to:'company@juice.co.kr'
        })
        this.setState({modalOpen:'none', reason:'', checkboxValue:false, rest:0, 
            isRequesting:false,halfCheckboxValue1:false, halfCheckboxValue2:false, reqTimeDiable:false,
            etcCheckboxValue:false
        })
        this.changeCalender(0)
        this.isRequesting = false
    }

    async changeCalender(delta:number) {
        const next = moment().year(this.state.year).month(this.state.month)
        next.add(delta, 'month')
        const calender = await this.makeCalendar(next.year(), next.month())
        this.setState({
            year:next.year(), 
            month:next.month(), 
            calender
        })
    }
    
    async makeCalendar(year, month):Promise<Array<CalendarCell[]>> {
        const {auth} = this.props
        const startMonth = moment().year(year).month(month).startOf('month')
        const start = startMonth.clone().startOf('week')
        const endMonth = moment().year(year).month(month).endOf('month').endOf('day')
        const end = endMonth.clone().endOf('week').endOf('day')

        const holiday = await Api.list<{name:string, date:string}[]>('/api/holiday', {year, month:month+1})
        let schedules:Schedule[] = []
        if(auth.profile) {
            schedules = await Api.list<Schedule[]>('/api-schedule/', {
                profile:auth.profile.id,
                start__gte:startMonth.clone().utc().format(),
                start__lte:endMonth.clone().utc().format()
            })
        }
        const iter = start.clone()
        const calendar:Array<CalendarCell[]> = []
        let stop = false
        let currentMonth = false
        while(!stop) {
            const row:CalendarCell[] = []
            for(let i=0;i<7;i++) {
                if (iter.format() === startMonth.format()) {
                    currentMonth = true
                }
                const items = []
                schedules.filter(s=> moment(s.start).date() === iter.date())
                    .forEach(s=> {
                    if(s.request && s.leave) {
                        items.push(AttendanceUtils.getLeaveType(s)+' 요청중')
                    } else if (s.request) {
                        items.push('수정 요청중')
                    } else if (s.leave) {
                        items.push(AttendanceUtils.getLeaveType(s))
                    } else {
                        items.push('출근 '+moment(s.start).format('HH:mm'))
                        if (s.end) {
                            items.push('퇴근 '+moment(s.end).format('HH:mm'))
                        }
                    }
                })
                holiday.filter(s=> moment(s.date).date() == iter.date())
                .forEach(s=> {
                    items.push(s.name)
                })

                row.push({
                    currentMonth,
                    date:iter.date(),
                    items
                })
                if (iter.format() === endMonth.clone().startOf('day').format()) {
                    currentMonth = false
                }
                if (iter.format() === end.clone().startOf('day').format()) {
                    stop=true
                    break
                }
                iter.add(1,'days')
            }
            calendar.push(row)
        }
        return Promise.resolve(calendar)
    }

    async selectCell(date) {
        if (this.today) {
            const dt = moment(this.today.start)
            if (this.state.year === dt.year() && this.state.month === dt.month() && date === dt.date() && !this.today.end) {
                this.setState({modalOpen:'alert'})
                return
            }
        }
        const {auth} = this.props
        const target_date = moment().year(this.state.year).month(this.state.month).date(date)
        const s = target_date.clone().startOf('date').utc().format()
        const e = target_date.clone().endOf('date').utc().format()
        const schedules = await Api.list<Schedule[]>('/api-schedule/',{
            profile:auth.profile.id,
            start__gte:s,
            start__lte:e
        })
        this.setState({modalOpen:'cell'+date, target_schedules:schedules})
    }

    renderWorkingTime() {
        let minutes = 0
        if (this.state.todaySchedule) {
            const start = moment(this.state.todaySchedule.start)
            let target = moment()
            let rest = 0
            if (this.state.todaySchedule.end) {
                target = moment(this.state.todaySchedule.end)
                rest = this.state.todaySchedule.rest
            } else {
                rest = AttendanceUtils.getRestByWorkingTime(this.state.todaySchedule)
            }
            minutes = target.diff(start, 'minutes')
            if (minutes < rest) {
                minutes = 0
            } else {
                minutes -= rest
            }
        }
        return <GridItem xs={12} sm={6} md={3}>
        <Card>
            <CardHeader color="success" stats icon>
            <CardIcon color="success">
                <Store />
            </CardIcon>
            <p style={styles.cardCategory}>당일 근무시간</p>
            <h3 style={styles.cardTitle as any}>
                <div>{Math.floor(minutes/60)}<small>시간</small> {minutes%60}<small>분</small></div>
            </h3>
            </CardHeader>
            <CardFooter stats>
            <div style={styles.stats}>
                <DateRange />
                휴게시간(근무시간) : 30분(4~8h)/60분(8h~)
            </div>
            </CardFooter>
        </Card>
        </GridItem>
    }

    render() {
        return <div>
            <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="rose" stats icon>
                    <CardIcon color="rose">
                        <Icon>info_outline</Icon>
                    </CardIcon>
                    <p style={styles.cardCategory}>근태기록</p>
                    <h3 style={styles.cardTitle as any}>
                        <Button variant="contained" color="primary" style={{marginRight:10}}
                            onClick={()=> this.attend()}
                            disabled={this.state.todaySchedule ? true : false}>출근</Button>
                        <Button variant="contained" color="primary"
                            onClick={()=> this.setState({modalOpen:'rest'})}
                            disabled={(this.state.todaySchedule && !this.state.todaySchedule.end) ? false : true}>퇴근</Button>
                    </h3>
                    </CardHeader>
                    <CardFooter stats>
                    <div style={styles.stats}>
                        <Update />
                        사용한 연차수 : 행정팀 문의
                        {/* {this.state.leaveCount} */}
                    </div>
                    </CardFooter>
                </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                        <Icon>content_copy</Icon>
                    </CardIcon>
                    <p style={styles.cardCategory}>
                        {(this.state.todaySchedule && this.state.todaySchedule.end) ? '퇴근시간' : '출근시간'}</p>
                    <h3 style={styles.cardTitle as any}>
                        {this.state.todaySchedule ? 
                            this.state.todaySchedule.end ? 
                        <div>{moment(this.state.todaySchedule.end).hours()}<small>시</small> {moment(this.state.todaySchedule.end).minutes()}<small>분</small></div>:
                        <div>{moment(this.state.todaySchedule.start).hours()}<small>시</small> {moment(this.state.todaySchedule.start).minutes()}<small>분</small></div>:
                        <div>--<small>시</small> --<small>분</small></div>}
                        
                    </h3>
                    </CardHeader>
                    <CardFooter stats>
                    <div style={styles.stats}>
                        <Alarm />
                        {this.state.todaySchedule ? 
                            this.state.todaySchedule.end ? '출근시간 : ' + moment(this.state.todaySchedule.start).format('HH:mm') :
                                '근무중' : '출근전'}
                    </div>
                    </CardFooter>
                </Card>
                </GridItem>
                {this.renderWorkingTime()}
                <GridItem xs={12} sm={6} md={3}>
                <Card>
                    <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                        <Accessibility />
                    </CardIcon>
                    <p style={styles.cardCategory}>주간 근무시간</p>
                    <h3 style={styles.cardTitle as any}>
                        {Math.floor(this.state.workingTimeForWeek/60)}<small>시간</small> {this.state.workingTimeForWeek%60}<small>분</small>
                    </h3>
                    </CardHeader>
                    <CardFooter stats>
                    <div style={styles.stats}>
                        <LocalOffer />
                        주간 근무시간 40 ~ 52시간
                    </div>
                    </CardFooter>
                </Card>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4 style={styles.cardTitleWhite as any}>근태달력</h4>

                    </CardHeader>
                    <CardBody>
                        <div css={css`
                            display:flex;
                            justify-content:center;
                            align-items:center;
                            margin:10px;
                        `}>
                            <div onClick={()=>this.changeCalender(-1)}><Icon>keyboard_arrow_left</Icon></div>
                            <h4 css={css`margin:0px;margin-bottom:5px;`}>{this.state.year}년 {this.state.month+1}월</h4>
                            <div onClick={()=>this.changeCalender(1)}><Icon>keyboard_arrow_right</Icon></div>
                        </div>
                        <div css={css`
                            flex-direction:row;
                            justify-content:space-between;
                            display:flex;
                            border:solid 1px #ccc;
                        `}>
                            {['일', '월', '화', '수', '목', '금', '토'].map((cell, j)=> <div key={j} css={css`
                                flex:1;
                                margin-top:5px;
                                margin-bottom:5px;
                                align-items:center;
                                text-align:center;
                                font-weight:bold;
                            `}>
                                <div css={css`color:${j===0?'red':j===6?'blue':'black'};`}>{cell}요일</div>
                            </div>)}
                        </div>
                        {this.state.calender.map((row,i)=> <div key={i} css={css`
                            flex-direction:row;
                            justify-content:space-between;
                            display:flex;
                            padding:0px;
                            border-right:solid 1px #ccc;
                        `}>
                            {row.map((cell, j)=> <div key={j} css={css`
                                flex:1;
                                align-items:center;
                                border-left:solid 1px #ccc;
                                border-bottom:solid 1px #ccc;
                                padding:5px;
                                height:80px;
                                &:hover {
                                    background-color:#CCC;
                                    cursor:pointer;
                                }
                            `} onClick={()=>{
                                if (cell.currentMonth) {
                                    this.selectCell(cell.date)
                                }
                            }}>
                                <div css={css`
                                    font-weight:bold;
                                    text-align:right;
                                    color:${!cell.currentMonth? '#ccc':j===0?'red':j===6?'blue':'black'};`}>{cell.date}</div>
                                {cell.currentMonth && cell.items.map((item, i)=> <div key={i}>{item}</div>)}
                            </div>)}
                        </div>)}
                    </CardBody>
                    <CardFooter stats>
                    </CardFooter>
                </Card>
                </GridItem>
            </GridContainer>
            <Modal
                open={this.state.modalOpen !== 'none'}
                onClose={()=> this.setState({modalOpen:'none', reason:'', rest:0})}>
                <div css={css`
                    margin:auto;
                    max-width:500px;
                `}>
                    {this.state.modalOpen === 'attend' && <Card>
                        <CardBody>
                            <h3 style={styles.cardTitle as any}>외근 정보 입력</h3>
                            <CustomInput
                                labelText="외근 장소, 목적을 간단히 입력해주세요."
                                id="attend"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    multiline: true,
                                    rows: 5,
                                    value:this.state.reason,
                                    onChange:(e)=>this.setState({reason:e.target.value})
                                }}
                            />
                            <div css={css`text-align:right;`}>
                                <Button style={{marginRight:5}} variant="contained" onClick={()=> this.setState({modalOpen:'none', reason:''})}>취소</Button>
                                <Button variant="contained" color="primary" onClick={()=> this.attend(this.state.reason)}>확인</Button>
                            </div>
                        </CardBody>
                    </Card>}
                    {this.state.modalOpen === 'rest' && <Card>
                        <CardBody>
                            <h3 style={styles.cardTitle as any}>휴게시간 입력</h3>
                            <CustomInput
                                labelText="추가 휴게시간(분)을 입력해주세요."
                                id={'rest'}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    value:this.state.rest,
                                    onChange:(e)=>this.setState({rest:e.target.value})
                                }}
                            />
                            <div css={css`text-align:right;`}>
                                <Button style={{marginRight:5}} variant="contained" onClick={()=> this.setState({modalOpen:'none',rest:0})}>취소</Button>
                                <Button variant="contained" color="primary" onClick={()=> this.offwork()}>확인</Button>
                            </div>
                        </CardBody>
                    </Card>}
                    {this.state.modalOpen === 'outside_off' && <Card>
                        <CardBody>
                            <h3 style={styles.cardTitle as any}>외근 정보 입력</h3>
                            <CustomInput
                                labelText="외근 장소, 목적을 간단히 입력해주세요."
                                id="outside_off"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    multiline: true,
                                    rows: 5,
                                    value:this.state.reason,
                                    onChange:(e)=>this.setState({reason:e.target.value})
                                }}
                            />
                            <div css={css`text-align:right;`}>
                                <Button style={{marginRight:5}} variant="contained" onClick={()=> this.setState({modalOpen:'none',reason:''})}>취소</Button>
                                <Button variant="contained" color="primary" onClick={()=> this.offwork(this.state.reason)}>확인</Button>
                            </div>
                        </CardBody>
                    </Card>}
                    {this.state.modalOpen.indexOf('cell') === 0 && <Card>
                        <CardBody>
                            <h3 style={styles.cardTitle as any}>{this.state.month+1}/{this.state.modalOpen.replace('cell','')} 근태 정보</h3>
                            {this.state.target_schedules.map((item, i)=> {
                            const start = moment(item.start)
                            const end = moment(item.end)
                            return <div key={i}>
                                {start.format('HH:mm')} - {end.format('HH:mm')} 휴게시간 : {item.rest}분 
                                {(item.request || (item.leave && moment() < start)) && <Button style={{float:'right'}} variant="contained" color="primary"
                                    onClick={async()=> {
                                        await Api.delete('/api-schedule/', item.id)
                                        this.selectCell(start.date())
                                        this.changeCalender(0)
                                    }}>취소</Button>}
                                <div>{item.reason ? JSON.parse(item.reason)['요청']: ''}</div>
                            </div>})}
                            <h3 style={styles.cardTitle as any}>{this.state.month+1}/{this.state.modalOpen.replace('cell','')} 근태 요청</h3>
                            <div>
                                <TextField
                                    id="start"
                                    label="출근시간"
                                    type="time"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{marginRight:20}}
                                    value={this.state.reqStart}
                                    onChange={e=> this.setState({reqStart:e.target.value})}
                                    disabled={this.state.reqTimeDiable}
                                />
                                <TextField
                                    id="end"
                                    label="퇴근시간"
                                    type="time"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.reqEnd}
                                    onChange={e=> this.setState({reqEnd:e.target.value})}
                                    disabled={this.state.reqTimeDiable}
                                />
                            </div>
                            <CustomInput
                                labelText="추가 휴게시간(분)을 입력해주세요."
                                id={'rest'}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    value:this.state.rest,
                                    onChange:(e)=>this.setState({rest:e.target.value})
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.checkboxValue}
                                        onChange={()=>{
                                            if(!this.state.checkboxValue) {
                                                this.setState({checkboxValue:true, halfCheckboxValue1:false, halfCheckboxValue2:false,
                                                    etcCheckboxValue:false,
                                                    reqStart:'09:00', reqEnd:'18:00', reqTimeDiable:true})
                                            } else {
                                                this.setState({checkboxValue:false, reqTimeDiable:false})
                                            }
                                        }}
                                        name="checkedF"
                                        color="primary"
                                    />
                                }
                                label="연차휴가"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.halfCheckboxValue1}
                                        onChange={()=>{
                                            if(!this.state.halfCheckboxValue1) {
                                                this.setState({checkboxValue:false, halfCheckboxValue1:true, halfCheckboxValue2:false,
                                                    etcCheckboxValue:false,
                                                    reqStart:'09:00', reqEnd:'13:30', reqTimeDiable:true})
                                            } else {
                                                this.setState({halfCheckboxValue1:false, reqTimeDiable:false})
                                            }
                                        }}
                                        name="checkedF"
                                        color="primary"
                                    />
                                }
                                label="오전반차"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.halfCheckboxValue2}
                                        onChange={()=>{
                                            if(!this.state.halfCheckboxValue2) {
                                                this.setState({checkboxValue:false, halfCheckboxValue1:false, halfCheckboxValue2:true,
                                                    etcCheckboxValue:false,
                                                    reqStart:'13:30', reqEnd:'18:00', reqTimeDiable:true})
                                            } else {
                                                this.setState({halfCheckboxValue2:false, reqTimeDiable:false})
                                            }
                                        }}
                                        name="checkedF"
                                        color="primary"
                                    />
                                }
                                label="오후반차"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.etcCheckboxValue}
                                        onChange={()=>{
                                            if(!this.state.etcCheckboxValue) {
                                                this.setState({checkboxValue:false, halfCheckboxValue1:false, halfCheckboxValue2:false,
                                                    etcCheckboxValue:true,
                                                    reqStart:'09:00', reqEnd:'18:00', reqTimeDiable:false})
                                            } else {
                                                this.setState({etcCheckboxValue:false, reqTimeDiable:false})
                                            }
                                        }}
                                        name="checkedF"
                                        color="primary"
                                    />
                                }
                                label="기타휴가"
                            />
                            <div style={{
                                fontSize:9,
                                lineHeight: 1.5
                            }}>
                            향후 퇴직시 본인이 선사용한 연차휴가일수가 근로기준법 제60조에 정한 기준에 따라 발생하는 연차유급휴가일수에 미달하는 경우, 그 미달하는 일수 1일당 통상 임금 1일분을 기준으로 산정한 금액을 본인에게 지급할 임금 및 퇴직금에서 공제함에 동의합니다.
                            </div>
                            <CustomInput
                                labelText="요청사항(필수사항)"
                                id="request"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    multiline: true,
                                    rows: 5,
                                    value:this.state.reason,
                                    onChange:(e)=>this.setState({reason:e.target.value})
                                }}
                            />
                            <div css={css`text-align:right;`}>
                                <Button style={{marginRight:5}} variant="contained" onClick={()=> this.setState({modalOpen:'none',reason:'', rest:0})}>취소</Button>
                                <Button variant="contained" color="primary" onClick={()=> this.requestSchedule()}
                                    disabled={this.state.reason===''|| this.state.isRequesting}>요청</Button>
                            </div>
                        </CardBody>
                    </Card>}
                    {this.state.modalOpen === 'alert' && <Card>
                        <CardBody>
                            <div>
                                근무중인 날에 대한 근태요청은 퇴근 처리 이후 가능합니다.
                            </div>
                            <div css={css`text-align:right;`}>
                                <Button style={{marginRight:5}} variant="contained" onClick={()=> this.setState({modalOpen:'none',reason:'', rest:0})}>닫기</Button>
                            </div>
                        </CardBody>
                    </Card>}
                </div>
            </Modal>
        </div>
    }
}

export default connectWithoutDone(
    state=>({
        auth:state.auth
    }),
    dispatch=>({
    }),
    Attendance
)
