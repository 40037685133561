import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import queryStirng from "query-string";
import axios from "axios";

export const FileLoader = ({
  onUpload,
  onReady,
  sheetId,
  genieToken,
}: {
  onUpload: (file: any) => void;
  onReady: (json: any) => void;
  sheetId?: any;
  genieToken?: any;
}) => {
  const stfp_url = "https://api.intranet.juice.co.kr/api/sftp/";
  useEffect(() => {
    let id = "";
    let zero = 4 - sheetId.split("").length;
    for (let i = 0; i < zero; i++) {
      id += "0";
    }
    id += sheetId;
    const prefix = `${id ?? "0002"}.json`;
    const file_path = stfp_url.concat(prefix);
    async function getJson() {
      const result = await axios.get(file_path);
      if (result && result.data) {
        onReady(result.data);
      } else {
        onReady(false);
      }
    }
    getJson();
    // const genie_api = `http://app.genie.co.kr/songsheet/preview.json?countryCode=KR&sheetId=${sheetId}&authToken=${genieToken}`;
    // async function genieApi() {
    //   // const result = await axios.get(genie_api);

    //   // axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
    //   // 서로 다른 도메인간 쿠키 전달 허용
    //   // axios.defaults.withCredentials = true;
    //   const r2 = await axios
    //     .post(
    //       "https://app.genie.co.kr/songsheet/preview.json",
    //       {
    //         countryCode: "KR",
    //         sheetId: String(sheetId),
    //         authToken: genieToken,
    //       },
    //       {
    //         headers: {
    //           withCredentials: true,
    //         },
    //       }
    //     )
    //     .then((r) => {
    //       console.log("r is : ", r);
    //     });
    //   console.log("r : ", r2);
    // }

    // genieApi();
  }, []);
  // const [file, setFile] = useState<any>();
  // function onChange(event) {
  //   var reader = new FileReader();
  //   reader.onload = onReaderLoad;
  //   reader.readAsText(event.target.files[0]);
  // }

  // function onReaderLoad(event) {
  //   console.log(event.target.result);
  //   const obj = JSON.parse(event.target.result);
  //   alert("file Uploaded");
  //   onUpload(obj);
  // }

  return (
    <>
      {/* <div style={{ position: "absolute", top: 100 }}>
        {"Token : " + genieToken}
      </div> */}
    </>
  );
};

export const getJsonFile = (num: number) => {
  //해당 번호에 있는 json file return
  return 0;
};
