import { Api } from 'core/Api'
import React, { useEffect, useState } from 'react'

export function DemoMusicCut(){
    const [showRequest, setShowRequest] = useState(true)
    const [showDownload, setShowDownload] = useState(false)
    const [cut_time, setCut_time] = useState('')
    const [fade_in, setFade_in] = useState('1')
    const [fade_out, setFade_out] = useState('1')
    const [filename, setFilename] = useState('')
    const [music_list, setMusic_list] = useState<{description:string, id:number}[]>([])
    const [selectId, setSelectId] = useState(0)
    const [output_list, setOutput_list] = useState([])
    const process_music_cut = () => {
        if (!cut_time || !fade_in || !fade_out) {
            alert('자르는 시간이나, fade_in, fade_out 시간을 입력해 주세요.');
          }
      
          let that = this;
          Api.create('/api/music_cut/music_cut/', {
            music_item : music_list[selectId].description,
            cut_time: cut_time,
            fade_in: fade_in,
            fade_out: fade_out,
            filename: filename,
          }).then(score_convert_result => {
            console.log(score_convert_result);
            if (score_convert_result['result']) {
                setOutput_list(score_convert_result['output_list'])
                setShowRequest(false)
                setShowDownload(true)
            }
          }).catch(error=> {
            console.log('error', error);
            this.go_to_init();
          })
    }
    const go_to_init = () => {
        setShowRequest(true)
        setShowDownload(false)
        Api.list('/api/music_cut/music_delete/', {}).then(()=> {
            setCut_time('0')
            setFade_in('1')
            setFade_out('1')
            setOutput_list([])
        })
    }
    const music_download = (music_file) => {
        Api.list('/api/music_cut/music_download/', {
            filename : music_file,
        }, {
            responseType: 'blob' 
        }).then((blob:Blob)=> {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.href = url
            a.download = music_file
            a.click()
            a.remove()
            window.URL.revokeObjectURL(url);
        })
    }
    useEffect(()=>{
        Api.list('/api/music_cut/music_list/', {}).then(res=>{
            setMusic_list(res['music_list'].map((d,i)=>({
                description:d,
                id:i
            })))
        })
    },[])
    return <div id="avocado-test1">
    <div id="avocado-test1-wrap">
      <div className="container">
        <div className="row">
          {showRequest && <div id="request" className="col-md-12">
            <h6>자를 노래를 선택하시오.</h6>
            <table >
                <tbody>
                {music_list.map((music_item, i)=> <tr key={i} style={{border: "1px solid black"}}>
                    <td><input type="radio" name="radiogroup" onChange={e=> setSelectId(i)} checked={i === selectId}  value={music_item.id} /></td>
                    <td>{ music_item.description }</td>
              </tr>)}
              </tbody>
            </table>
            <h6>자르는 시간을 선택하시오.</h6>
            <h6>자르는 시간을 쉼표(,)로 구분하여 다음과 같이 입력하십시오. (밀리세컨드까지 입력가능) 예) 1:11.123, 2:34.324, 1:11
            </h6>
            <input value={cut_time} onChange={e=> setCut_time(e.target.value)} style={{width:500}}/>
            <h6>fadein, fadeout 설정 (단위는 초, 소수점 셋째자리까지 입력가능) 예) 1.123, 25
            </h6>
            <span>fadein : </span> <input value={fade_in} onChange={e=> setFade_in(e.target.value)} style={{width:500}} /><br />
            <span>fadeout : </span> <input value={fade_out} onChange={e=> setFade_out(e.target.value)}  style={{width:500}} />
  
            <h6>파일 이름 입력 ('대금독주곡_0001.wav'을 원하면, '대금독주곡' 까지 입력 하시오.) - 아무것도 안적으면 선택된 파일 이름 뒤에 _0001만 붙음.
            </h6>
            <span>파일 이름 : </span> <input value={filename} onChange={e=> setFilename(e.target.value)} style={{width:500}} /><br />
  
            <button onClick={()=> process_music_cut()} style={{display: 'block'}}>
              {/* <!--[disabled]="is_valid_score_request()"--> */}
            처리
            </button>
            <h6>처리 시간이 좀 걸립니다. 기다려 주세요.
            </h6>
          </div>}
          <hr />
          {showDownload && <div id="download" className="col-md-12">
            <div id="go_to_init">
                <button onClick={()=> go_to_init()}>
                처음으로
                </button>
            </div>
            <span>다운로드 목록</span>
            <a style={{display:'none'}}></a>
            {output_list.map((res,i)=> <div className="download_row" key={i}>
              <div>
                  <button onClick={()=> music_download(res)} className="btn btn-danger btn-xs download-button" >
                    {res}
                  </button>
              </div>
            </div>)}
            <h6>다운로드 되는데 시간이 걸립니다. 기다려 주세요.
            </h6>
          </div>}
        </div>
      </div>
    </div>
  </div>
}