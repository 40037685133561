import React from "react";
export enum ContextType {
  play = "play_btn",
  pause = "pause_btn",
  restart = "restart_btn",
  payment = "payment_btn",
  download = "download_btn",
  back = "back_btn",
  more = "more_btn",
  credit = "credit_btn",
}

export function GetSvg({
  context,
  width,
  height,
  fill,
}: {
  context: ContextType;
  width?: number;
  height?: number;
  fill?: string;
}) {
  const illustMap: any = SvgMap(width, height, "");
  const illust = illustMap[context];

  return <div>{illust()}</div>;
}

const SvgMap = (width?: number, height?: number, fill?: string) => {
  return {
    payment_btn: () => (
      <svg
        width="150%"
        height="150%"
        viewBox="0 0 60 52"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fillRule="nonzero" fill="none">
          <path fill="none" d="M0 0h44v44H0z" />
          <path
            d="M22 9c7.18 0 13 5.82 13 13s-5.82 13-13 13S9 29.18 9 22 14.82 9 22 9zm0 1.4c-6.407 0-11.6 5.193-11.6 11.6S15.593 33.6 22 33.6 33.6 28.407 33.6 22 28.407 10.4 22 10.4zM19.2 18a.3.3 0 0 1 .3.3V20h1.75v-.7a.3.3 0 0 1 .3-.3h.9a.3.3 0 0 1 .3.3v.7h1.75v-1.7a.3.3 0 0 1 .3-.3h.9a.3.3 0 0 1 .3.3V20h.7a.3.3 0 0 1 .3.3v.9a.3.3 0 0 1-.3.3H26v2.2l-.01.024A2.37 2.37 0 0 1 23.625 26c-.63 0-1.2-.25-1.625-.651a2.361 2.361 0 0 1-3.99-1.625L18 23.7v-2.2h-.7a.3.3 0 0 1-.3-.3v-.9a.3.3 0 0 1 .3-.3h.7v-1.7a.3.3 0 0 1 .3-.3h.9zm5.3 3.5h-1.75v2.125c0 .482.393.875.875.875a.877.877 0 0 0 .875-.875V21.5zm-3.25 0H19.5v2.125c0 .482.393.875.875.875a.877.877 0 0 0 .875-.875V21.5z"
            fill="#000"
          />
        </g>
      </svg>
    ),
    restart_btn: () => (
      <svg
        width="150%"
        height="150%"
        viewBox="0 0 60 52"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fillRule="nonzero" fill="none">
          <path fill="none" d="M0 0h44v44H0z" />
          <path
            d="M15.2 31a1.2 1.2 0 0 1-1.2-1.2V14.2a1.2 1.2 0 1 1 2.4 0l-.002 6.568a1.6 1.6 0 0 1 .173-.125l11.381-7.113a1.6 1.6 0 0 1 2.448 1.357v14.226a1.6 1.6 0 0 1-2.448 1.357l-11.381-7.113a1.615 1.615 0 0 1-.173-.125L16.4 29.8a1.2 1.2 0 0 1-1.2 1.2z"
            fill="#000"
          />
        </g>
      </svg>
    ),
    play_btn: () => (
      <svg
        width="150%"
        height="150%"
        viewBox="0 0 60 52"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fillRule="nonzero" fill="none">
          <path fill="none" d="M0 0h44v44H0z" />
          <path
            d="M32.57 24.058 14.635 34.819A2.4 2.4 0 0 1 11 32.761V11.24a2.4 2.4 0 0 1 3.635-2.058L32.57 19.942a2.4 2.4 0 0 1 0 4.116z"
            fill="#000"
          />
        </g>
      </svg>
    ),
    pause_btn: () => (
      <svg
        width="150%"
        height="150%"
        viewBox="0 0 60 52"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fillRule="nonzero" fill="none">
          <path fill="none" d="M0 0h44v44H0z" />
          <path
            d="M16 8a2 2 0 0 1 2 2v24a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h2zm14 0a2 2 0 0 1 2 2v24a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h2z"
            fill="#000"
          />
        </g>
      </svg>
    ),
    download_btn: () => (
      <svg
        width="150%"
        height="150%"
        viewBox="0 0 60 52"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fillRule="nonzero" fill="none">
          <path fill="none" d="M0 0h44v44H0z" />
          <path
            d="M31.125 25.75c.443 0 .81.33.867.756l.008.119v3.25a2.125 2.125 0 0 1-1.942 2.117l-.183.008h-15.75a2.125 2.125 0 0 1-2.117-1.942L12 29.875v-3.25a.875.875 0 0 1 1.742-.119l.008.119v3.25c0 .178.123.326.289.365l.086.01h15.75a.375.375 0 0 0 .365-.289l.01-.086v-3.25c0-.483.392-.875.875-.875zM22 12c.443 0 .81.33.867.756l.008.119v11.139l4.756-4.758a.875.875 0 0 1 1.33 1.13l-.092.108-6.25 6.25a.871.871 0 0 1-.485.246L22 27a.872.872 0 0 1-.619-.256l-6.25-6.25a.875.875 0 0 1 1.13-1.33l.108.092 4.756 4.758V12.875c0-.483.392-.875.875-.875z"
            fill="#000"
          />
        </g>
      </svg>
    ),
    back_btn: () => (
      <svg
        width="150%"
        height="150%"
        viewBox="0 0 60 52"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fillRule="nonzero" fill="none">
          <path fill="none" d="M0 0h40v40H0z" />
          <path
            d="m12.613 11.21.094.083L20 18.585l7.293-7.292a1 1 0 0 1 1.497 1.32l-.083.094L21.415 20l7.292 7.293a1 1 0 0 1-1.32 1.497l-.094-.083L20 21.415l-7.293 7.292a1 1 0 0 1-1.497-1.32l.083-.094L18.585 20l-7.292-7.293a1 1 0 0 1 1.32-1.497z"
            fill="#000"
          />
        </g>
      </svg>
    ),
    more_btn: () => (
      <svg
        width="150%"
        height="150%"
        viewBox="0 0 60 52"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 26a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm0-8a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm0-8a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
          fill="#8A8A8D"
          fillRule="evenodd"
        />
      </svg>
    ),
    credit_btn: () => (
      <svg
        width="150%"
        height="150%"
        viewBox="0 0 60 52"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <path fill="none" d="M0 0h40v40H0z" />
          <path
            d="M20.5 9C26.851 9 32 14.149 32 20.5S26.851 32 20.5 32 9 26.851 9 20.5 14.149 9 20.5 9zm0 1.568c-5.485 0-9.932 4.447-9.932 9.932s4.447 9.932 9.932 9.932 9.932-4.447 9.932-9.932-4.447-9.932-9.932-9.932zm.523 8.887c.288 0 .522.257.522.575v5.175c0 .317-.234.575-.522.575h-1.046c-.288 0-.522-.258-.522-.575V20.03c0-.318.234-.575.522-.575h1.046zm-.523-4.13a1.15 1.15 0 1 1-.187.015l.187-.015z"
            fill="#8A8A8D"
          />
        </g>
      </svg>
    ),
  };
};
