/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Route, Switch } from 'react-router-dom';
import { Base } from '../../core/Base';
import { connectWithoutDone } from '../../core/connection';
import { AuthState } from './Auth.action';
import AuthSignIn from './AuthSignIn';
import AuthSignUp from './AuthSignUp';
import { History } from 'history'
interface Props {
    auth:AuthState
    history:History
}

interface States {
    
}

class Auth extends Base<Props, States> {

    link
    state = {
        ready:false
    }

    componentDidMount() {
        this.link = document.createElement('link')
        this.link.href = 'https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css'
        this.link.rel = 'stylesheet'
        this.link.onload = () => {
            this.setState({ready:true})
        }
        document.getElementsByTagName('title')[0].after(this.link)
    }

    componentWillUnmount() {
        this.link.remove()
    }

    bind(props:Props, state:States) {
        const {auth, history} = props
        if (auth.profile) {
            history.push('/admin/dashboard')
        }
    }

    render() {
        if(!this.state.ready) return <div />
        return <div>
            <div css={css`
                height: 100%;
                background-color: #000;
                overflow-x: hidden;
                padding-top: 20px;
                @media screen and (max-height: 450px) {
                    padding-top: 15px;
                }
                @media screen and (min-width: 768px) {
                    width: 40%;
                    position: fixed;
                    z-index: 1;
                    top: 0;
                    left: 0;
                }
            `}>
                <div css={css`
                    margin-top: 20%;
                    padding: 60px;
                    color: #fff;
                `}>
                    <h2 css={css`
                        font-weight: 300;
                    `}>JUICE</h2>
                    <p>Login or register from here to access.</p>
                </div>
            </div>
            <Switch>
                <Route exact path='/auth' component={AuthSignIn} />
                <Route exact path='/auth/signup' component={AuthSignUp} />
            </Switch>
        </div>
    }
}

export default connectWithoutDone(
    state=>({
        auth:state.auth
    }),
    dispatch=>({}),
    Auth
)