import moment from "moment";
import { Profile, Schedule } from "types/api.types";

export const AttendanceUtils = {
    getLeaveType:(s:Schedule<Profile> | Schedule)=> {
        const reason = JSON.parse(s.reason)
        let type = ''
        if (reason['휴가'] === undefined) {
            type = '연차'
        } else {
            type = reason['휴가']
        }
        return type
    },
    getRestByWorkingTime:(s:Schedule)=> {
        return AttendanceUtils.getRestByTime(moment(s.start), moment())
    },
    getRestByTime:(start:moment.Moment, end:moment.Moment)=> {
        const diff = end.diff(start, 'minutes')
        let rest = 0
        if (diff >= 480) {
            rest = 60
        } else if (diff >= 240) {
            rest = 30
        }
        return rest
    }
}