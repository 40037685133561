import React, { useEffect, useState } from "react";

export enum ScoreActionType {
  play = "play",
  pause = "pause",
  stop = "stop",
  more = "more",
  close = "close",
  download = "download",
  purchase = "purchase",
  credit = "credit",
}
export function SendActionToGenie(
  action: ScoreActionType | string,
  onResponse: (response) => void
) {
  const _window: any = window;
  if (isMobile.valid()) {
    if (isMobile.Android()) {
      if (_window.juiceToGenie) {
        const response = _window.juiceToGenie.webToApp(action);
        if (response) onResponse(response);
      }
    } else if (isMobile.iOS()) {
      // var messageData = "Hello from webview!";
      if (_window.webkit)
        _window.webkit.messageHandlers.juiceToGenie.postMessage(action);
      onResponse(action);
    }
  }
}

const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i) == null ? false : true;
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) == null
      ? false
      : true;
  },
  valid: function () {
    return isMobile.Android() || isMobile.iOS();
  },
};

export const AvocadoBridge = (props: {
  onStop: () => void;
  onPlay: () => void;
  onPause: () => void;
  ios_show: () => void;
  ios_hide: () => void;
  // action: string;
  //play, stop, pause, measurepress,
  //download, info, back
}) => {
  const [messageFromApp, setMessageFromApp] = useState("");

  useEffect(() => {
    const _window: any = window;
    //app -> web
    _window.genieToJuice = {
      appToWeb: (message: string) => {
        // setMessageFromApp("app To Web  : " + message);
        if (message === "play") {
          props.onPlay();
        } else if (message === "stop") {
          props.onStop();
        } else if (message === "pause") {
          props.onPause();
        } else if (message === "ios_hide") {
          props.ios_hide();
        } else if (message === "ios_show") {
          props.ios_show();
        } else {
          // setMessageFromApp("wrong order");
        }
        setMessageFromApp("app to web: " + message);
      },
    };
  }, []);

  // useEffect(() => {
  //   const _window: any = window;
  //   if (isMobile.valid()) {
  //     if (isMobile.Android()) {
  //       if (_window.juiceToGenie) {
  //         const response = _window.juiceToGenie.webToApp(props.action);
  //         if (response) setMessageFromApp(response);
  //       }
  //     } else if (isMobile.iOS()) {
  //       // var messageData = "Hello from webview!";
  //       if (_window.webkit)
  //         _window.webkit.messageHandlers.juiceToGenie.postMessage(props.action);
  //       setMessageFromApp(props.action);
  //     }
  //   }
  // }, [props]);

  return (
    <div
      style={{
        position: "absolute",
        pointerEvents: "none",
        top: 250,
        zIndex: 5,
        // backgroundColor: "red",
      }}
    >
      {/* <div>{messageFromApp}</div> */}
      {/* <button
        onClick={() => {
          const _window: any = window;
          //web->app
          if (isMobile.valid()) {
            if (isMobile.Android()) {
              if (_window.juiceToGenie) {
                const response = _window.juiceToGenie.webToApp(
                  "play or more or measure or ...etc"
                );
                if (response) setMessageFromApp(response);
              }
            } else if (isMobile.iOS()) {
              var messageData = "Hello from webview!";
              _window.webkit.messageHandlers.juiceToGenie.postMessage(
                messageData
              );
            }
          }
        }}
        style={{}}
      >
        Send msg to app
      </button> */}
    </div>
  );
};
