import { store } from "../../core/Store";

export type MediaQueryState = {
    width: number
    height: number
    deviceWidth: number
    deviceHeight: number
    lastScrollY: number,
    isMobile: boolean
}

const initState:MediaQueryState = {
    width: 0,
    height: 0,
    deviceWidth: 0,
    deviceHeight: 0,
    lastScrollY: 0,
    isMobile: false,
}

export const MediaQueryAction = {
    name:'MediaQuery',
    setWidth: (width:number)=> {
        return Promise.resolve({width: width});
    },
    setHeight: (height:number) => {
        return Promise.resolve({height: height});
    },
    setDeviceWidth: (deviceWidth: number) => {
        return Promise.resolve({deviceWidth: deviceWidth});
    },
    setDeviceHeight: (deviceHeight: number) => {
        return Promise.resolve({deviceHeight: deviceHeight});
    },
    setIsMobile: (isMobile: boolean) => {
        return Promise.resolve({isMobile: isMobile});
    },
    setLastScrollY: (lastScrollY: number) => {
        return Promise.resolve({lastScrollY: lastScrollY})
    }
}

store.injectReducer(MediaQueryAction, initState)